import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize, WindowSize } from '../hooks';
import { Paragraph } from './Paragraph';

interface SliderBarProps {
  className?: string;
  children?: any;
}

export const SliderBar = (props: SliderBarProps) => {
  const { className, children } = props;
  const { t } = useTranslation();
  const [transform, setTransform] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [visibleCount, setVisibleCount] = useState<number>(0);
  const size: WindowSize = useWindowSize();
  const items = useRef<any>();
  let finalClass: string = 'gov-slider-bar';

  finalClass = className?
    `${finalClass} ${className}`:
    finalClass;

  useEffect(() => {
    setCount(items.current.childElementCount);
    setVisibleCount(Math.round(items.current.offsetWidth / items.current.firstChild.offsetWidth));
  }, [])

  useEffect(() => {
    setVisibleCount(Math.round(items.current.offsetWidth / items.current.firstChild.offsetWidth));
    setTransform(0);
  }, [size.width]); //control width change

  return (<div className={finalClass}>
    <div className="gov-slider-bar__content">
      {transform < 0 && <span
        onClick={() => setTransform(transform + 1)}
        className={ `gov-slider-bar__arrow gov-slider-bar__arrow--prev` }
        aria-label={t("sliderBar.buttonLabelOne")}
        tabIndex={-1}
        role="button">
      </span>}
      <div
        className="gov-slider-bar__items"
        ref={items}
        style={{ transition: 'all 1s', transform: `translateX(${transform * 100}%)` }}
      >
        {children.props ?
          <SliderBarItem {...children.props} /> :
          children.map((item: { props: SliderBarItemProps }, index: number) => {
            return (<SliderBarItem key={index} {...item.props} />)
          })}
      </div>
      {transform <= 0 && (-1 * Math.ceil(count / visibleCount) < transform - 1) && <span
        onClick={() => setTransform(transform - 1)}
        className="gov-slider-bar__arrow gov-slider-bar__arrow--next"
        aria-label={t("sliderBar.buttonLabelTwo")}
        tabIndex={0}
        role="button">
      </span>}
    </div>
  </div>);
}

interface SliderBarItemProps {
  className?: string;
  href?: string;
  text?: string;
  icon?: any;
}

export const SliderBarItem = (props: SliderBarItemProps) => {
  const { className, href, text, icon } = props;
  let finalClass: string = 'gov-slider-bar__item';

  finalClass = className ?
    `${finalClass} ${className}` :
    finalClass;

  return (<a className={ finalClass } href={ href }>
    {icon && <div className="gov-slider-bar__icon">{ icon }</div>}
    {text && <Paragraph>{ text }</Paragraph>}
  </a>)
}
