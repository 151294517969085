import * as React from 'react';

export interface ButtonProps {
  size?: 'small' | 'large' | 'normal';
  color?: 'primary' | 'secondary' | 'primary-outlined' | 'tertiary';
  disabled?: boolean;
  inversed?: boolean;
  children?: any;
  href?: string;
  onClick?: any;
  className?: string;
  target?: string;
  tabIndex?: number;
}

export const Button = (props: ButtonProps) => {
  const { children, size, disabled, inversed, color, href, target, onClick, className, tabIndex } = props;
  let finalClassName: string = 'gov-button';

  finalClassName = color ?
    `${finalClassName} gov-button--${color}` :
    finalClassName;
  finalClassName = className ?
    `${finalClassName} ${className}` :
    finalClassName;
  finalClassName = disabled ?
    `${finalClassName} gov-button--disabled` :
    finalClassName;
  finalClassName = size ?
    `${finalClassName} gov-button--${size}` :
    finalClassName;
  finalClassName = inversed ?
    `${finalClassName} gov-button--inversed` :
    finalClassName;

  if (href) {
    return (<a href={href} target={target} className={finalClassName} onClick={onClick}>
      {children}
    </a>);
  } else {
    return (<a type="button" onClick={onClick} target={target} className={finalClassName} tabIndex={tabIndex || 0}>
      {children}
    </a>);
  }
}
