/**
 * Perex Component
 */

import * as React from 'react';
import { ReactNode } from 'react';
import clsx from 'clsx';
import { Paragraph } from './Paragraph';

export interface PerexProps {
  /** If true, text is bold */
  bold?: boolean;
  /** Custom class applied to root element */
  className?: string;
  /** Represents text or another component */
  children: ReactNode;
  /** Passing all other props to component */
  props?: any;
}

export const Perex = ({ bold = false, children, className, ...props }: PerexProps) => (
  <Paragraph className={clsx('gov-perex', className, { 'u-font-weight--bold': bold })} {...props}>
    {children}
  </Paragraph>
);
