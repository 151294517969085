import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Headline } from './Headline';
import { Paragraph } from './Paragraph';
import { scrollToElement } from './Utils';

export interface TabsProps {
  children?: any;
  className?: string;
  version?: 'new' | 'badge-long' | 'badge-short' | 'infotile';
  tabsRef?: any;
  activeTab?: number;
  onActiveTabChanged?: (newActiveTab: number) => void;
}

export const Tabs = (props: TabsProps) => {
  const { children, className, version, activeTab, onActiveTabChanged } = props;
  const [isActive, setIsActive] = useState(0);
  const { t } = useTranslation();
  const history = useHistory();
  let finalClass: string = 'gov-tabs';

  finalClass = className ?
    `${finalClass} ${className}` :
    finalClass;
  finalClass = version ?
    `${finalClass} gov-tabs--${version}` :
    finalClass;

  useEffect(() => {
    if (!location?.pathname || !children) return;

    const matchingChild = children.find((i: any) => i.props && location.pathname.indexOf(i.props.routePath) > -1);

    if (matchingChild) {
      const childIndex = children.indexOf(matchingChild);
      setIsActive(childIndex);
    }
  }, [location?.pathname, children]);


  useEffect( ()=>{
    typeof activeTab !== "undefined" && setIsActive(activeTab);
  }, [activeTab]);

  useEffect( ()=>{
    onActiveTabChanged && onActiveTabChanged(isActive);
  }, [isActive]);

  const handleTabsButtonClick = (ev: React.MouseEvent<HTMLAnchorElement>, index: number, routePath: string | undefined) => {
    ev.preventDefault();
    setIsActive(index);
    routePath && history.push(routePath);
  }

  return (<div className={finalClass}>
    <div className="gov-tabs__links-holder" role="tablist" aria-label={t("tabs.buttonLabel")}>
      <div className="gov-tabs__links-holder--inner" ref={props.tabsRef}>
      {children.props ?
        <TabsButton
          version={version}
          isActive={true}
          {...children.props}
        /> :
        children.map((item: { props: TabsItemProps }, index: number) => {
          return (<TabsButton
            version={version}
            isActive={index === isActive}
            {...item.props}
            keyNumber={index}
            key={index}
            handleClick={(ev) => handleTabsButtonClick(ev, index, item.props.routePath)}
          />)
      })}
      </div>
    </div>

    <div className="gov-tabs__content-holder">
      {children.props ?
        <TabsItem
          isActive={true}
          title={children.props.title}
          keyNumber={0}
          routePath={children.props.routePath}
        >
          {children.props.children}
        </TabsItem> :
        children.map((item: { props: TabsItemProps }, index: number) => {
          return (<TabsItem
            isActive={isActive === index}
            title={item.props.title}
            keyNumber={index}
            key={index}
            routePath={item.props.routePath}
          >
            {item.props.children}
          </TabsItem>)
        })
      }
    </div>
  </div>)
}

export const TabsButton = (props: TabsItemProps) => {
  const { keyNumber, title, subtitle, text, icon, arrow, version, className, id, isActive, handleClick, routePath } = props;
  let finalClass: string = 'gov-tabs__link';

  finalClass = className ?
    `${finalClass} ${className}` :
    finalClass;
  finalClass = isActive ?
    `${finalClass} is-active` :
    finalClass;

  return (
    <a
      onClick={(ev) => handleClick && handleClick(ev)}
      id={`link-${keyNumber}-${id}`}
      className={finalClass}
      aria-selected={true}
      role="tab"
      aria-controls={`link-${keyNumber}-tab-${id}`}
      tabIndex={0}
      href={routePath}
    >
      {version === 'new' &&
        <>
          <span className={ 'gov-tabs__icon' }>{ icon }</span>
          <Headline type={3} className={ 'gov-tabs__title' }>{ title }</Headline>
          <span className={ 'gov-tabs__link--arrow' }>{ arrow }</span>
        </>
      }
      {(version === 'badge-long' || version === 'badge-short')  &&
        <>
          <span className={ 'gov-tabs__icon' }>{ icon }</span>
          <Paragraph className={'gov-tabs__subtitle'}>{ subtitle }</Paragraph>
        </>
      }
      {version === 'infotile' &&
        <>
          <span className={ 'gov-tabs__icon' }>{ icon }</span>
          <Headline type={3} className={ 'gov-tabs__title' }>{ title }</Headline>
          <Headline type={4} className={ 'gov-tabs__text' }>{ text }</Headline>
        </>
      }
    </a>)
}

export interface TabsItemProps {
  handleClick?: (ev: React.MouseEvent<HTMLAnchorElement>) => void;
  children?: any;
  id?: string;
  className?: string;
  keyNumber?: number;
  isActive?: boolean;
  title?: string;
  subtitle?: string;
  text?: string;
  icon?: any;
  arrow?: any;
  routePath?: string;
  version?: 'new' | 'badge-long' | 'badge-short' | 'infotile';
}

export const TabsItem = (props: TabsItemProps) => {
  const { children, className, keyNumber, id, isActive } = props;
  let finalClass: string = 'gov-tabs__content';

  finalClass = className ?
    `${finalClass} ${className}` :
    finalClass;
  finalClass = isActive ?
    `${finalClass} is-active` :
    finalClass;

  return (<div
    id={`link-${keyNumber}-tab-${id}`}
    className={finalClass}
    role="tabpanel"
    aria-labelledby={`link-${keyNumber}`}
  >
    {children}
  </div>)
}
