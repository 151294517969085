import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionItem,
  Alert,
  Link,
  Paragraph,
  StepDescription,
  Stepper,
} from '../../gov-components';

import externalLink from '../../resources/icons/external.svg';
import lionErb from '../../resources/icons/erb-logo.svg';
import lionErbYellow from '../../resources/icons/erb-yellow.svg';
import czpLogo from '../../resources/icons/czechpoint.svg';

import { withLocale } from '../../util/routing';
import * as Constants from '../../util/constants';

export const DataboxAccordionsNew = (props: {expandedId: number | undefined, onExpanded: (id: number | undefined ) => void}) => {
  const { t } = useTranslation();
  const {expandedId, onExpanded} = props;

  const stepperOneDB = {
    steps: [
      {
        order: 1,
        title: t('stepperOneDB.stepOneTitle'),
        images: ['/static/images/PO-1.png'],
      },
      {
        order: 2,
        title: t('stepperOneDB.stepTwoTitle'),
        images: ['/static/images/PO-2A.png', '/static/images/PO-2B.png',
                 '/static/images/PO-2C.png', '/static/images/PO-2D.png',
                 '/static/images/PO-2E.png', '/static/images/PO-2F.png']
      },
      {
        order: 3,
        title: t('stepperOneDB.stepThreeTitle'),
        images: ['/static/images/PO-3A.png', '/static/images/PO-3B.png',
                 '/static/images/PO-3C.png', '/static/images/PO-3D.png',
                 '/static/images/PO-3E.png', '/static/images/PO-3F.png']
      },
      {
        order: 4,
        title: t('stepperOneDB.stepFourTitle'),
        images: ['/static/images/PO-4A.png', '/static/images/PO-4B.png']
      }
    ],
  }

  const stepperTwoDB = {
    steps: [
      {
        order: 1,
        title: t('stepperTwoDB.stepOneTitle'),
        images: ['/static/images/NIA-1A.png', '/static/images/NIA-1B.png',
                 '/static/images/NIA-1C.png']
      },
      {
        order: 2,
        title: t('stepperTwoDB.stepTwoTitle'),
        images: ['/static/images/NIA-2A.png', '/static/images/NIA-2B.png',
                 '/static/images/NIA-2C.png']
      },
      {
        order: 3,
        title: t('stepperTwoDB.stepThreeTitle'),
        images: ['/static/images/NIA-3A.png', '/static/images/NIA-3B.png']
      }
    ],
  }

  const stepperThreeDB = {
    steps: [
      {
        order: 1,
        title: t('stepperThreeDB.stepOneTitle'),
      },
      {
        order: 2,
        title: t('stepperThreeDB.stepTwoTitle'),
      },
      {
        order: 3,
        title: t('stepperThreeDB.stepThreeTitle'),
      },
      {
        order: 4,
        title: t('stepperThreeDB.stepFourTitle'),
        images: ['/static/images/CZP-4A.png', '/static/images/CZP-4B.png'],
      }
    ],
  }

  return (
    <Accordion version={ 'new' }
      expandedItem={expandedId}
      onExpanded={onExpanded}
    >
      <AccordionItem title={t('mainPage.sectionDatabox.titleOne')}
                     keyNumber={0}
                     icon={<img src={ lionErb }
                                alt={t(`mainPage.sectionDatabox.altOne`)} />}
        >
        <Alert inContent={true}
               globalClosable={false}
               className={ 'u-mb--32' }>
          <Paragraph className={ 'gov-alert__content--bigger-text' }>
            {t('stepperOneDB.stepOneAlertPartOne')}&nbsp;
            <Link
              title={t('stepperOneDB.stepOneDescriptionLinkOneTitle')}
              href={ 'https://obcan.portal.gov.cz/prihlaseni' }
              target="_blank"
              className={ 'stepper-wrapper__text--link' }
            >{t('stepperOneDB.stepOneAlertLinkOne')}
              <img className={ 'plain-list__external-link' }
                   src={ externalLink }
                   alt={t("mainPage.imageLinkExternal")} />
            </Link>
          </Paragraph>
        </Alert>
        <div className={ 'accordion-text-wrapper' }>
          <Stepper steps={ stepperOneDB.steps } >
            <StepDescription key={1}>
              {t('stepperOneDB.stepOneDescriptionPartOne')}&nbsp;
              <Link
                title={t('stepperOneDB.stepOneDescriptionPartOneTitle')}
                href={ 'https://obcan.portal.gov.cz/prihlaseni' }
                target="_blank"
                className={ 'stepper-wrapper__text--link' }
              >{t('stepperOneDB.stepOneDescriptionLinkOne')}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>
            </StepDescription>
            <StepDescription key={2}>
              {t('stepperOneDB.stepTwoDescriptionPartOne')}&nbsp;
              <b>{t('stepperOneDB.stepTwoDescriptionPartTwo')}</b>&nbsp;
              {t('stepperOneDB.stepTwoDescriptionPartThree')}&nbsp;
              <b>{t('stepperOneDB.stepTwoDescriptionPartFour')}</b>
            </StepDescription>
            <StepDescription key={3}>
              {t('stepperOneDB.stepThreeDescriptionPartOne')} <b>{t('stepperOneDB.stepThreeDescriptionPartTwo')}</b>&nbsp;
              {t('stepperOneDB.stepThreeDescriptionPartThree')} <b>{t('stepperOneDB.stepThreeDescriptionPartFour')}</b>&nbsp;
              {t('stepperOneDB.stepThreeDescriptionPartFive')} <b>{t('stepperOneDB.stepThreeDescriptionPartSix')}</b>
            </StepDescription>
            <StepDescription key={4}>
              {t('stepperOneDB.stepFourDescriptionPartOne')} <b>{t('stepperOneDB.stepFourDescriptionPartTwo')}</b>&nbsp;
              {t('stepperOneDB.stepFourDescriptionPartThree')} <b>{t('stepperOneDB.stepFourDescriptionPartFour')}</b>&nbsp;
              {t('stepperOneDB.stepFourDescriptionPartFive')} <b>{t('stepperOneDB.stepFourDescriptionPartSix')}</b>
            </StepDescription>
          </Stepper>
        </div>
      </AccordionItem>
      <AccordionItem title={t('mainPage.sectionDatabox.titleTwo')}
                     keyNumber={1}
                     icon={<img src={ lionErbYellow }
                                  alt={t(`mainPage.sectionDatabox.altTwo`)} />}
      >
        <Alert inContent={true}
               globalClosable={false}
               className={ 'u-mb--32' }>
          <Paragraph className={ 'gov-alert__content--bigger-text' }>
            {t('stepperTwoDB.stepOneAlertPartOne')}&nbsp;
            <Link
              title={t('stepperTwoDB.stepOneAlertLinkOneTitle')}
              href={ withLocale(Constants.ROUTE_PATHS.identity) }
              className={ 'stepper-wrapper__text--link' }
            >{t('stepperTwoDB.stepOneAlertLinkOne')}
            </Link>&nbsp;
            {t('stepperTwoDB.stepOneAlertPartTwo')} <b>{t('stepperTwoDB.stepOneAlertPartThree')}</b>
          </Paragraph>
        </Alert>
        <div className={ 'accordion-text-wrapper' }>
          <Stepper steps={ stepperTwoDB.steps }>
            <StepDescription key={1}>
              <Link
                title={t("stepperTwoDB.stepOneDescriptionLinkOneTitle")}
                href={t("https://info.mojedatovaschranka.cz/")}
                target="_blank"
                className={ 'stepper-wrapper__text--link' }
              >{t('stepperTwoDB.stepOneDescriptionLinkOne')}
              <img className={ 'plain-list__external-link' }
                   src={ externalLink }
                   alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp;
              <b>{t('stepperTwoDB.stepOneDescriptionPartOne')}</b> {t('stepperTwoDB.stepOneDescriptionPartTwo')}&nbsp;
              <b>{t('stepperTwoDB.stepOneDescriptionPartThree')}</b> {t('stepperTwoDB.stepOneDescriptionPartFour')}
            </StepDescription>
            <StepDescription key={2}>
              {t('stepperTwoDB.stepTwoDescriptionPartOne')} <b>{t('stepperTwoDB.stepTwoDescriptionPartTwo')}</b>
            </StepDescription>
            <StepDescription key={3}>
              {t('stepperTwoDB.stepThreeDescriptionPartOne')} <b>{t('stepperTwoDB.stepThreeDescriptionPartTwo')}</b>&nbsp;
              {t('stepperTwoDB.stepThreeDescriptionPartThree')} <b>{t('stepperTwoDB.stepThreeDescriptionPartFour')}</b>&nbsp;
              {t('stepperTwoDB.stepThreeDescriptionPartFive')} <b>{t('stepperTwoDB.stepThreeDescriptionPartSix')}</b>&nbsp;
              {t('stepperTwoDB.stepThreeDescriptionPartSeven')}
            </StepDescription>
          </Stepper>
        </div>
      </AccordionItem>
      <AccordionItem title={t('mainPage.sectionDatabox.titleThree')}
                     keyNumber={2}
                     icon={<img src={ czpLogo }
                                alt={t(`mainPage.sectionDatabox.altThree`)} />}
      >
        <Alert inContent={true}
               globalClosable={false}
               className={ 'u-mb--32' }>
          <Paragraph className={ 'gov-alert__content--bigger-text' }>
            {t('stepperThreeDB.stepOneAlertPartOne')}
          </Paragraph>
        </Alert>
        <div className={ 'accordion-text-wrapper' }>
          <Stepper steps={ stepperThreeDB.steps }>
            <StepDescription key={1}>
              {t('stepperThreeDB.stepOneDescriptionPartOne')} <b>{t('stepperThreeDB.stepOneDescriptionPartTwo')}</b>&nbsp;
              <Link
                title={t("stepperThreeDB.stepOneDescriptionLinkOneTitle")}
                href={t("https://www.czechpoint.cz/wwwstats/f?p=100%3A16")}
                target="_blank"
                className={ 'stepper-wrapper__text--link' }
              >{t('stepperThreeDB.stepOneDescriptionLinkOne')}
              <img className={ 'plain-list__external-link' }
                   src={ externalLink }
                   alt={t("mainPage.imageLinkExternal")} />
              </Link>
            </StepDescription>
            <StepDescription key={2}>
              {t('stepperThreeDB.stepTwoDescriptionPartOne')} <b>{t('stepperThreeDB.stepTwoDescriptionPartTwo')}</b>&nbsp;
              {t('stepperThreeDB.stepTwoDescriptionPartThree')}
            </StepDescription>
            <StepDescription key={3}>
              {t('stepperThreeDB.stepThreeDescriptionPartOne')}&nbsp;
              <Link
                title={t("stepperThreeDB.stepThreeDescriptionLinkOneTitle")}
                href={t("https://info.mojedatovaschranka.cz/")}
                target="_blank"
                className={ 'stepper-wrapper__text--link' }
              >{t('stepperThreeDB.stepThreeDescriptionLinkOne')}
              <img className={ 'plain-list__external-link' }
                   src={ externalLink }
                   alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp;
              {t('stepperThreeDB.stepThreeDescriptionPartTwo')}&nbsp;
              <b>{t('stepperThreeDB.stepThreeDescriptionPartThree')}</b>
            </StepDescription>
            <StepDescription key={4}>
              {t('stepperThreeDB.stepFourDescriptionPartOne')}&nbsp;
              {t('stepperThreeDB.stepFourDescriptionPartTwo')} <b>{t('stepperThreeDB.stepFourDescriptionPartThree')}</b>&nbsp;
              {t('stepperThreeDB.stepFourDescriptionPartFour')} <b>{t('stepperThreeDB.stepFourDescriptionPartFive')}</b>&nbsp;
              {t('stepperThreeDB.stepFourDescriptionPartSix')}
            </StepDescription>
          </Stepper>
        </div>
      </AccordionItem>
    </Accordion>
  )
}
