import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container, Headline,
  Link,
  Paragraph,
  scrollToElement,
  ShowMoreBox,
} from '../../gov-components';

import externalLink from '../../resources/icons/external.svg';
import NIAStepOne from '../../static/images/NIA-3A.png';
import NIAStepTwo from '../../static/images/NIA-3B.png';

interface BadgeInfoBoxProps {
  switchDataboxTab: (tab: number) => void;
  switchFaqTab: (tab: number) => void;
}

export const BadgeInfoBox = (props: BadgeInfoBoxProps) => {
  const {switchDataboxTab, switchFaqTab} = props;
  const { t } = useTranslation();

  return (
    <>
      <section id="badge-prepinace-box">
        <Container className="gov-container--900px u-bg-color--white gov-badge-infobox">
          <div className="gov-badge-infobox__wrapper">

            <ShowMoreBox
              heading={t('databoxPage.badgeInfoBox.titleTwo')}
              showMoreLabel={t("databoxPage.badgeInfoBox.buttonTwo")}
              showMoreContent={
                <>
                  <Paragraph className="u-mb--16">
                    {t('databoxPage.badgeInfoBox.textEleven')}&nbsp;
                    <Link
                      title={t('databoxPage.badgeInfoBox.titleLinkFour')}
                      href={'https://info.mojedatovaschranka.cz/'}
                      target="_blank"
                      className="gov-badge-infobox__text-link"
                    >{t('databoxPage.badgeInfoBox.textTwelveLinkFour')}
                      <img className="plain-list__external-link"
                          src={ externalLink }
                          alt={t("mainPage.imageLinkExternal")} />
                    </Link>&nbsp;
                    {t('databoxPage.badgeInfoBox.textThirteen')} <b>{t('databoxPage.badgeInfoBox.textFourteen')}</b>&nbsp;
                    {t('databoxPage.badgeInfoBox.textFifteen')} <b>{t('databoxPage.badgeInfoBox.textSixteen')}</b>
                    </Paragraph>
                  <img src={ NIAStepOne }
                      alt={t("databoxPage.badgeInfoBox.iconAltOne")}
                      className="u-mb--16 gov-badge-infobox__image"/>
                  <img src={ NIAStepTwo }
                      alt={t("databoxPage.badgeInfoBox.iconAltTwo")}
                      className="gov-badge-infobox__image"/>
                   </>
              }>
              <Paragraph className="u-mb--unset">
                {t('databoxPage.badgeInfoBox.textTen')}
              </Paragraph>
            </ShowMoreBox>
          </div>
        </Container>
      </section>
    </>
  )
}

export const BadgeInfoBoxUaRuVersion = () => {
  const { t } = useTranslation();

  return (
    <>
      <section id="badge-prepinace-box-ua-ru">
        <Container className="gov-container--900px u-bg-color--grey gov-badge-infobox">
          <div className="gov-badge-infobox__wrapper margin-ua-version">
            <div className="show-more-box__inner">
              <Headline type={2} className="show-more-box__heading--ua-version">
                {t('databoxPageOld.badgeInfoBox.titleOne')}
              </Headline>
              <Paragraph className='u-mb--unset'>
                {t('databoxPageOld.badgeInfoBox.textOne')} <b>{t('databoxPageOld.badgeInfoBox.textTwo')}</b>&nbsp;
                {t('databoxPageOld.badgeInfoBox.textThree')} <b>{t('databoxPageOld.badgeInfoBox.textFour')}</b>
              </Paragraph>
            </div>
            <div className="show-more-box__inner u-mt--16">
              <Headline type={3} className="show-more-box__smaller-heading">
                {t('databoxPageOld.badgeInfoBox.titleTwo')}
              </Headline>
              <Paragraph className="u-mb--8">
                {t('databoxPageOld.badgeInfoBox.textFive')}
              </Paragraph>
              <Paragraph className='u-mb--unset'>
                {t('databoxPageOld.badgeInfoBox.textSix')}
              </Paragraph>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}
