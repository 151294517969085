import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Headline,
  Link,
  Paragraph,
  Tabs,
  TabsItem
} from '../../gov-components';
import {
  DataboxBusinessModalsNew,
  DataboxCitizenModalsNew,
  DataboxNonEntrepreneurialModalsNew
} from './DataboxModalsNew';

import externalLink from '../../resources/icons/external.svg';

export const BadgeDataboxTabs = (props: {activeTab: number, onActiveTabChanged: (tab: number) => void}) => {
  const { t } = useTranslation();


  return (
    <>
      <section id={ 'badge-prepinace' }>
        <Container className={ 'gov-container--900px u-bg-color--grey gov-badge-tabs pt-56 pb-56' }>
          <Headline id="s-cim-datovka-pomuze" type={2} className={ 'gov-badge-tabs__title heading2' }>{t('identityPage.badgeTabs.titleOne')}</Headline>
          <Tabs version={ 'badge-long' } activeTab={props.activeTab} onActiveTabChanged={props.onActiveTabChanged }>
            <TabsItem
              subtitle={t('identityPage.badgeTabs.subtitleOne')}
            >
              <DataboxCitizenModalsNew />
            </TabsItem>
            <TabsItem
              subtitle={t('identityPage.badgeTabs.subtitleTwo')}
            >
              <DataboxBusinessModalsNew />
            </TabsItem>
            <TabsItem
              subtitle={t('identityPage.badgeTabs.subtitleThree')}
            >
              <DataboxNonEntrepreneurialModalsNew />
            </TabsItem>
          </Tabs>
          <Paragraph className={ 'gov-badge-tabs__text-link' }>
            {t('identityPage.badgeTabs.textMiddleTwo')}&nbsp;
            <Link
              title={t('identityPage.badgeTabs.titleLinkOne')}
              href={ 'https://info.mojedatovaschranka.cz/info/cs/87.html' }
              target="_blank"
              className={ 'stepper-wrapper__text--link' }
            >{t('identityPage.badgeTabs.textMiddleLinkTwo')}
              <img className={ 'plain-list__external-link' }
                   src={ externalLink }
                   alt={t("mainPage.imageLinkExternal")} />
            </Link>
          </Paragraph>
        </Container>
      </section>
    </>
  )
}
