import * as React from 'react';

interface LayoutProps {
  className?: string;
  children?: any;
  href?: string;
  text?: string;
  icon?: any;
}

export const Layout = (props: LayoutProps) => {
  const { className, children, href, text, icon } = props;
  let finalClass: string = 'gov-layout';

  finalClass = className ?
    `${finalClass} ${className}` :
    finalClass;

  return (<div className={finalClass}>
    {children}
  </div>);
}

interface LayoutColProps {
  className?: string;
  children?: any;
}

export const LayoutCol = (props: LayoutColProps) => {
  const { className, children } = props;
  let finalClass: string = 'gov-layout__col';

  finalClass = className ?
    `${finalClass} ${className}` :
    finalClass;

  return (<div className={finalClass}>
    {children}
  </div>);
}
