import * as React from 'react';
import { useRef, useState } from 'react';
import { scrollToElement } from './Utils';
import { Headline } from './Headline';

interface GliderProps {
  children?: any;
  className?: string;
  gridSize: number | string;
}

export const Glider = (props: GliderProps) => {
  const { children, className } = props;
  const [isVisible, setIsVisible] = useState<number>(0);
  let wrapper = useRef<any>();
  let finalClass: string = 'gov-glider';

  finalClass = className ?
    `${finalClass} ${className}` :
    finalClass;

  const handleScroll = (id: number) => {
    scrollToElement({
      elem: wrapper.current.children[id],
      axis: 'x',
      parent: wrapper.current,
    });
  };

  return (<div className={finalClass}>
    <div
      className="gov-glider-inner"
      ref={wrapper}
      onScroll={(e) => {
        const baseWidth: number = wrapper.current.children[0].clientWidth; // width is set according to first element

        // TODO: optimize this solution
        setTimeout(() => {
          const selectedIndex: number = Math.round(wrapper.current.scrollLeft / baseWidth);
          isVisible !== selectedIndex&& setIsVisible(selectedIndex);
        }, 200);
      }}
    >
      {children.props ?
        <GliderItem {...children.props} /> :
        children.map((item: { props: GliderItemProps }, index: number) => {
          return (<GliderItem
            {...item.props}
            key={index}
          />)
        })
      }
    </div>
    {!children.props && <div className="gov-glider-menu">
      {children.map((item: { props: GliderItemProps }, index: number) => {
        return (<button
          className={index === isVisible ? 'is-active' : undefined}
          onClick={() => handleScroll(index)}
          key={index}
        >&nbsp;</button>) // nbsp has to stay here so the buttons will be clickable
      })}
    </div>}
  </div>);
}

interface GliderItemProps {
  children?: any;
  title?: string;
  href?: string;
  onClick?: any;
}

export const GliderItem = (props: GliderItemProps) => {
  const { children, title, href } = props;
  let finalClass: string = 'gov-glider-item';

  return (<>
    {href ?
      <a href={href} className={finalClass}>
        {title && <Headline type={3}>{title}</Headline>}
        {children}
      </a> :
      <div className={finalClass}>
        {title && <Headline type={3}>{title}</Headline>}
        {children}
      </div>}
  </>);
}
