import * as React from 'react';
import { Headline } from './Headline';
import { Paragraph } from './Paragraph';

export interface PreviewTileProps {
  children?: any;
  className?: string;
  icon?: any;
  headlineText?: string;
  headline?: string;
}

export const PreviewTile = (props: PreviewTileProps) => {
  const { className, children, icon, headline, headlineText } = props;
  let finalClass: string = 'gov-preview-tile';

  finalClass = className ?
    `${finalClass} ${className}` :
    finalClass;

  return (<div className={ finalClass }>
    <div>
      {icon && <div className="gov-preview-tile__icon">{ icon }</div>}
      {headline && <Headline type={3} className="gov-preview-tile__headline">{ headline }</Headline>}
    </div>
    <div>{headlineText && <Paragraph className="gov-preview-tile__headline gov-preview-tile__headline-text">{ headlineText }</Paragraph>}</div>
    {children}
  </div>)
}
