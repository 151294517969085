/**
 * Icon Component
 */
import * as React from 'react';
import clsx from 'clsx';

export const IconTypeNames = [
  'add',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-on',
  'attachment',
  'calendar',
  'check',
  'compass',
  'cross',
  'dashboard',
  'download',
  'facebook',
  'e-envelope',
  'exit',
  'external',
  'facebook',
  'info',
  'lightbulb',
  'mail',
  'message',
  'more',
  'notifications',
  'phone',
  'place',
  'plus',
  'plus-small',
  'point-right',
  'remove',
  'search',
  'settings',
  'sigma',
  'star',
  'time',
  'twitter',
  'user',
  'youtube',
  'warning',
  'youtube',
] as const;
export type IconTypeNamesType = typeof IconTypeNames[number];

export const IconTypeSizes = ['16x16', '20x20', '32x32', '48x48', '64x64'] as const;
export type IconTypeSizesType = typeof IconTypeSizes[number];

export const IconTypeComplexNames = [
  'car',
  'covid',
  'doc-health',
  'doc-search',
  'doc-stamp',
  'doc-taxes',
  'graduate',
  'house',
  'id-card',
  'illness',
  'info',
  'jobless',
  'map',
  'new-comments',
  'newborn',
  'packet',
  'planet',
  'pregnant',
  'review',
  'rings',
  'senior',
  'tombstone',
  'vacation',
] as const;
export type IconTypeComplexNamesType = typeof IconTypeComplexNames[number];

export const IconTypeComplexSizes = ['16x16', '24x24', '32x32', '56x56', '64x64'] as const;
export type IconTypeComplexSizesType = typeof IconTypeComplexSizes[number];

export interface IconProps {
  /** Custom class applied to root element */
  className?: string;
  /** Icon name from Basic icons set */
  type?: IconTypeNamesType;
  /** Icon size - predefined sizes */
  size?: IconTypeSizesType;
  /** Icon complex size - predefined sizes */
  sizeComplex?: IconTypeComplexSizesType;
  /** Icon name from Complex icons set */
  typeComplex?: IconTypeComplexNamesType;
  /** Passing all other props to component */
  props?: any;
}

export const Icon = ({ className, size, sizeComplex, type, typeComplex, ...props }: IconProps) => {
  const complexClass = typeComplex ? 'complex-' : '';
  const finalType = typeComplex || type;
  const finalClasses = clsx(
    `gov-${complexClass}icon`,
    `gov-${complexClass}icon--${finalType}`,
    { [`gov-icon--${size}`]: size },
    { [`gov-complex-icon--${sizeComplex}`]: sizeComplex },
    className
  );

  return <span className={finalClasses} {...props} />;
};
