import * as React from 'react';
import { useEffect, useState } from 'react';
import { useWindowSize, WindowSize } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import {
  Container,
  Link,
  Button,
} from '../../gov-components';

export interface HeaderProps {
  version?: 'new' | 'old';
}

import lionLogo from '../../resources/icons/erb.svg';
import * as Constants from '../../util/constants';
import { withLocale } from '../../util/routing';

export const HeaderChciIdentitu = ({ version }: HeaderProps) => {
  const [headerMenu, setHeaderMenu] = useState(false);
  const size: WindowSize = useWindowSize();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const langs = ['cs', 'ua', 'en', 'ru'];
  const { location: { pathname } }: { location: { pathname: string } } = history;
  const hasDifferentContent: boolean = pathname.includes('ua') || pathname.includes('ru') || version === 'new';

  const routePaths = location.pathname.split('/');
  const pathName = routePaths.filter((path: any) => path.length <= 2)[0];
  
  const [currentLang, setCurrentLang] = useState('cs');
  useEffect(() => {
    setTimeout(() => {
      const lang = localStorage.getItem('lang');
      lang && setCurrentLang(lang);
    }, 1);
  }, [location]);

  const [isMainPage, setIsMainPage] = useState(false);
  useEffect(() => {
    const pathname = location.pathname;
    const isMainPage = pathname.indexOf(Constants.ROUTE_PATHS.intro) > -1 || pathname.replace('/', '') === '';
    setIsMainPage(isMainPage);
  }, [location]);

  useEffect(() => {
    setHeaderMenu(false);
  }, [size])

  useEffect(() => {
    headerMenu ?
      document.getElementsByTagName("body")[0].style.overflow = 'hidden' :
      document.getElementsByTagName("body")[0].style.overflow = '';
  }, [headerMenu])

  const translatePage = (lang: string): void => {
    localStorage.setItem('lang', lang);
    const routePaths = location.pathname.split('/');
    const pathName = routePaths.filter((path: any) => path.length > 2)

    if (lang === 'cs') {
      history.replace(`/${pathName}`, true);
      i18n.changeLanguage('cs');
    } else {
      history.replace(`/${lang}/${pathName}`);
      i18n.changeLanguage(lang);
    }
  }

  const pathIntroWithLocale = withLocale(Constants.ROUTE_PATHS.intro);

  const isLang = (lang: string) => {
    return currentLang && currentLang === lang;
  }

  return (<Container tag={'header'} className={`gov-container--no-y-offset gov-header${headerMenu ? ' is-fixed' : ''}`}>
    <div className="gov-header__holder">
      <div className="gov-header__left">
        {(isLang('cs') || isLang('en')) &&
          <Link
            inversed={true}
            className={'gov-header__logo'}
            href={ pathIntroWithLocale }
          >
            <div className="gov-pictogram gov-pictogram--inversed">
              <ReactSVG src={ lionLogo } alt={t('header.altOne')} />
            </div>
            <div className="gov-header__site-name">gov.cz</div>
          </Link>
        }
        {(!isLang('cs') && !isLang('en')) &&
          <div className='gov-header__logo'>
            <div className="gov-pictogram gov-pictogram--inversed">
              <ReactSVG src={ lionLogo } alt={t('header.altOne')} />
            </div>
            <div className="gov-header__site-name">gov.cz</div>
          </div>
        }
      </div>
      <div className="gov-header__right">
        {!hasDifferentContent &&
          <div className="gov-header__item">
            <div className="gov-search ">
              <div className="gov-search__inner">
                <form action="https://portal.gov.cz/hledat" method="get">
                  <div className="gov-form-control gov-form-control--inversed">
                    <input
                      id="02d92ec0-af77-e9c5-7497-c3e22149e1d1"
                      className="gov-form-control__input gov-form-control__input--standalone"
                      type="text"
                      aria-required="false"
                      aria-disabled="false"
                      placeholder={t("header.searchPlaceholder")}
                      name="q"
                    />
                    <label
                      className="gov-form-control__label u-sr-only"
                      htmlFor="02d92ec0-af77-e9c5-7497-c3e22149e1d1"
                    >{t("header.searchLabel")}</label>
                  </div>
                  <button
                    className="gov-button gov-button--primary gov-button--icon-only gov-search__submit"
                    aria-labelledby="ho-submit"
                  >
                    <span
                      id="ho-submit"
                      className="u-sr-only gov-button__label"
                    >
                      {t("header.searchLabel")}
                    </span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        }
        {!hasDifferentContent &&
          <div className="gov-header__item gov-header__item--login">
            <Button
              href="https://obcan.portal.gov.cz"
              color={'primary-outlined'}
              inversed={true}
              target="_blank"
            >{t("header.loginPortal")}</Button>
          </div>
        }
        <div className="gov-header__fixed-items">
          {!hasDifferentContent &&
            <div className="gov-header__item gov-header__item--mobile">
              <button
                onClick={ () => setHeaderMenu(!headerMenu) }

                className={ `gov-header__hamburger${ headerMenu ? ' active' : '' }` }
              >
                <span/>
                <span/>
                <div>{t("header.menu")}</div>
              </button>
            </div>
          }
          <div className="gov-header__item gov-header__lang">
            <div className="gov-lang-switch">
              <select
                defaultValue={currentLang}
                id="83e30b94-77c5-7998-e9b9-a54aab60a061"
                className="gov-lang-switch__select"
                onChange={(e) => translatePage(e.target.value)}
              >
                <option value={currentLang}>{currentLang.toUpperCase()}</option>
                {isMainPage ? langs
                .filter(lang => lang === 'cs' || lang === 'en')
                .map(lang => { 
                  return lang !== currentLang && <option key={lang} value={lang}>{lang.toUpperCase()}</option> 
                  })
                :
                langs.map(lang => { 
                  return lang !== currentLang && <option key={lang} value={lang}>{lang.toUpperCase()}</option> 
                  })
                }
              </select>
              <label className="u-sr-only" htmlFor="83e30b94-77c5-7998-e9b9-a54aab60a061">
                {t("header.languageSwitcher")}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr className="gov-header__separator" />
    {!hasDifferentContent &&
      <>
        <div className={ `gov-header__overlay${ headerMenu ? ' active' : '' }` }/>
        <nav className={ `gov-header__nav${ headerMenu ? ' active' : '' }` }>
          <div className={ `gov-header__nav-logo${ headerMenu ? ' active' : '' }` }>
            <div className="gov-pictogram gov-pictogram--inversed gov-pictogram--xs">
              <ReactSVG src={ lionLogo } alt={t('header.altOne')} />
            </div>
          </div>
          <div className="gov-header__nav-holder">
            <div className="gov-header__link">
              <Link
                href={t("header.textLinkOneHref")}
                standalone={true}
                size={'normal'}
                inversed={true}
              >{t("header.textLinkOne")}
              </Link>
            </div>
            <div className="gov-header__link">
              <Link
                href={t("header.textLinkTwoHref")}
                standalone={true}
                size={'normal'}
                inversed={true}
              >{t("header.textLinkTwo")}
              </Link>
            </div>
            <div className="gov-header__link">
              <Link
                href={t("header.textLinkThreeHref")}
                standalone={true}
                size={'normal'}
                inversed={true}
              >{t("header.textLinkThree")}
              </Link>
            </div>
            <div className="gov-header__link">
              <Link
                href={t("header.textLinkFourHref")}
                standalone={true}
                size={'normal'}
                inversed={true}
              >{t("header.textLinkFour")}
              </Link>
            </div>
            <div className="gov-header__link">
              <Link
                href={t("header.textLinkFiveHref")}
                standalone={true}
                size={'normal'}
                inversed={true}
              >{t("header.textLinkFive")}
              </Link>
            </div>
            <div className="gov-header__link">
              <Link
                href={t("header.textLinkSixHref")}
                standalone={true}
                size={'normal'}
                inversed={true}
              >{t("header.textLinkSix")}
              </Link>
            </div>
          </div>
          <div className="gov-header__item--mobile">
            <div className="gov-portal-header__extras ">
              <Button
                inversed={true}
                color={'primary-outlined'}
                href={t("header.buttonLoginHref")}
                target="_blank"
              >{t("header.buttonLogin")}</Button>
            </div>
          </div>
        </nav>
      </>
    }
  </Container>);
};
