import * as React from "react";
import { useEffect, useState } from 'react';
import { Headline } from './Headline';
import { scrollToElement } from './Utils';
import { Badge } from './Badge';
import { useWindowSize } from '../hooks';

export interface AccordionProps {
  children?: any;
  className?: string;
  version?: "new";
  expandedItem?: number;
  accordionRef?: any;
  onExpanded?: (idx?: number) => void;
}

export const Accordion = (props: AccordionProps) => {
  const { children, className, version, expandedItem, onExpanded } = props;
  const [expandedId, setExpandedId] = useState<number | undefined>();
  let finalClass: string = "gov-accordion";

  finalClass = className ? `${finalClass} ${className}` : finalClass;
  finalClass = version
    ? `${finalClass} ${version ? ` gov-accordion--${version}` : ""}`
    : finalClass;

  useEffect(() => {
    typeof expandedItem !== 'undefined' && setExpandedId(expandedItem);
  }, [expandedItem]);

  const handleExpand = (id: number | undefined) => {
    setExpandedId(id);
    onExpanded && onExpanded(id);
  };

  return (
    <div className={finalClass} ref={props.accordionRef}>
      {children.props ? (
        <AccordionItem {...children.props} />
      ) : (
        children.map((item: { props: AccordionItemProps }, index: number) => {
          const isLast: boolean = children.length === index + 1;
          return (
            <AccordionItem
              isLast={isLast}
              version={version}
              expandedId={expandedId}
              setExpandedId={version === "new" && handleExpand}
              {...item.props}
              key={index}
            />
          );
        })
      )}
    </div>
  );
};

export interface AccordionItemProps {
  children?: any;
  className?: string; // this does not make sense, it is not wrapping class
  title: string | undefined;
  keyNumber: number;
  setExpandedId?: any;
  expandedId?: number;
  version?: "new";
  icon?: any;
  isLast?: boolean;
  hasBadge?: boolean;
  badgeText?: string;
}

export const AccordionItem = (props: AccordionItemProps) => {
  const {
    children,
    title,
    keyNumber,
    version,
    setExpandedId,
    expandedId,
    icon,
    isLast,
    hasBadge,
    badgeText,
  } = props;
  const [isExpanded, setIsExpanded] = useState(expandedId === keyNumber);

  useEffect(() => {
    setIsExpanded(expandedId === keyNumber);

    // limited scrollTo on "small" screen resolution
    window.outerWidth < 600 &&
      scrollToElement({ elem: `gov-accordion-header-${expandedId}` });
  }, [props.expandedId]);

  const { width } = useWindowSize();
  const isMobile = width < 1100;

  // TODO: accordion-tabs/'new' functional for 3 items => css
  return (
    <>
      {version === "new" ? (
        <>
          {isMobile ?
            <button
              onClick={() => {
                setExpandedId(expandedId === keyNumber ? false : keyNumber);
                const element = document.getElementById(
                  `gov-accordion-header-${keyNumber}`
                );
                isExpanded &&
                scrollToElement({
                  elem: element,
                });
              }}
              id={`gov-accordion-header-${keyNumber}`}
              className={`gov-accordion__header${
                keyNumber === 0 ? " gov-accordion__header--noborder" : ""
              }${isExpanded ? " is-expanded" : ""}${isLast ? " is-last" : ""}`}
              aria-expanded="false"
              aria-controls={`gov-accordion-content-${keyNumber}`}
            >
              {icon && <span className="gov-accordion__icon">{icon}</span>}
              <Headline type={3} className="gov-accordion__title">
                {title}
              </Headline>
            </button>
            :
            <button
              onClick={() => {
                setExpandedId(expandedId === keyNumber ? expandedId : keyNumber);
                const element = document.getElementById(
                  `gov-accordion-header-${keyNumber}`
                );
                isExpanded &&
                scrollToElement({
                  elem: element,
                });
              }}
              id={`gov-accordion-header-${keyNumber}`}
              className={`gov-accordion__header${
                keyNumber === 0 ? " gov-accordion__header--noborder" : ""
              }${isExpanded ? " is-expanded" : ""}${isLast ? " is-last" : ""}`}
              aria-expanded="false"
              aria-controls={`gov-accordion-content-${keyNumber}`}
            >
              {icon && <span className="gov-accordion__icon">{icon}</span>}
              <Headline type={3} className="gov-accordion__title">
                {title}
              </Headline>
            </button>
          }
        </>
      ) : (
        <button
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
          id={`gov-accordion-header-${keyNumber}`}
          className={`gov-accordion__header${
            keyNumber === 0 ? " gov-accordion__header--noborder" : ""
          }${isExpanded ? " is-expanded" : ""}`}
          aria-expanded="false"
          aria-controls={`gov-accordion-content-${keyNumber}`}
        >
          <div className="accordion-with-badge">
            <Headline type={3} className='no-mb'>{title}</Headline>
            {hasBadge && <Badge variant={"warning"}>{badgeText}</Badge>}
          </div>
        </button>
      )}
      <div
        className={`gov-accordion__content${isExpanded ? " is-expanded" : ""}`}
        id={`gov-accordion-content-${keyNumber}`}
        aria-labelledby={`gov-accordion-header-${keyNumber}`}
      >
        <div className="gov-accordion__content-inner">{children}</div>
      </div>
    </>
  );
};
