import * as React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

export interface AlertProps{
  children?: any;
  className?: string;
  color?: 'warning' | 'error';
  closeHandler?: any;
  globalClosable?: boolean;
  inContent?: boolean;
}

export const Alert = (props: AlertProps) => {
  const { children, className, color, closeHandler, inContent, globalClosable } = props;
  let finalClass: string = 'gov-alert';

  finalClass = className ?
    `${finalClass} ${className}` :
    finalClass;
  finalClass = color ?
    `${finalClass} gov-alert--${color}` :
    finalClass;
  finalClass = inContent ?
    `${finalClass} gov-alert--content` :
    finalClass;

  return (<div className={finalClass}>
    <div className="gov-alert__content">
      {children}
    </div>
    {globalClosable && <button
      className="gov-button--icon-only gov-alert__close"
      onClick={closeHandler}
    >
      <span className="gov-icon gov-icon--cross" />
    </button>}
  </div>)
}

export interface AlertListProps{
  children?: any;
  className?: string;
  color?: 'warning' | 'error';
  closeHandler?: any;
  inContent?: boolean;
}

export const AlertList = (props: AlertListProps) => {
  const { children } = props;
  const { setAlertArray, alertArray, removeAlert } = useContext(AlertsContext);
  const localAlertsArray: any[] = children ? (children.props ? [children] : [...children]) : [];
  // TODO: this way we can simplify SliderBar, List, Grid, Accordion, etc...

  useEffect(() => {
    alertArray && localAlertsArray && setAlertArray(localAlertsArray);
  }, [])

  return (<>
    {alertArray && <div className={'gov-alert-list'}>
      {alertArray.map((item: { props: AlertProps }, index: number) => {
        return <Alert
          key={index}
          closeHandler={() => removeAlert(index)}
          {...item.props}
        />
      })}
    </div>}
  </>)
};

// CONTEXT
interface ContextAlerts {
  alertArray: any[];
  addAlert: any;
  removeAlert: any;
  setAlertArray: any;
}

export const AlertsContext = createContext<Partial<ContextAlerts>>({});

export const AlertsProvider = ({ children }: any) => {
  const [alertArray, setAlertArray] = useState<any[]>([]);

  const addAlert = (alert: any) => {
    setAlertArray([
      ...alertArray,
      alert
    ])
  }

  const removeAlert = (alertIndex: number) => {
    const newArray = alertArray.filter((alert: any, index: number) => {
      return index !== alertIndex;
    })
    setAlertArray(newArray);
  }

  return (<AlertsContext.Provider value={{
    alertArray: alertArray,
    addAlert: addAlert,
    removeAlert: removeAlert,
    setAlertArray: setAlertArray,
  }}>
    {children}
  </AlertsContext.Provider>);
};
