import * as React from 'react';

export interface ListProps{
  children?: any;
  className?: string;
  plain?: boolean;
  inline?: boolean;
}

export const List = (props: ListProps) => {
  const { children, className, plain, inline } = props;
  let finalClass: string = 'gov-list';

  // TODO: bad definition, if you have 'gov-list and gov-list--plain' the <li /> will add dots
  finalClass = plain ?
    `gov-list--plain` :
    finalClass;
  finalClass = className ?
    `${finalClass} ${className}` :
    finalClass;
  finalClass = inline ?
    `${finalClass} gov-list--inline` :
    finalClass;

  return (<ul className={finalClass}>
    {children.props ?
      <ListItem {...children.props} /> :
      children.map((item: { props: ListItemProps }, index: number) => {
        return (<ListItem key={index} {...item.props} />)
      })}
  </ul>)
}

export interface ListItemProps{
  children?: any;
}

export const ListItem = (props: ListItemProps) => {
  const { children } = props;
  // TODO: this is not BOM modifier, so it does not have to inherit className

  return (<li>
    {children}
  </li>)
}
