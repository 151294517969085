import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useState } from 'react';
import {
  Card,
  Container,
  Headline,
  HomePageHeader,
  scrollToElement
} from '../gov-components';
import {
  IconsWrapperNew,
  IdentityAccordionsNew,
  IdentityAccordionsQAndANew,
  InfotileTabs
} from './index';

import * as Constants from '../util/constants';
import { withLocale } from '../util/routing';
import { IdentityNewFAQ } from './components/JsonLd';

export const IdentityPageNew = () => {
  const location = useLocation();

  const { t } = useTranslation();
  const lang = localStorage.getItem('lang');
  const faq = IdentityNewFAQ(t);
  const [identityAccordionExpandedId, setIdentityAccordion] = useState<number | undefined>(undefined);


  const history = useHistory();
  const navigateTo = (route: string) => {
    history.push(route);
  }

  React.useEffect( () => {
    if (location.pathname.endsWith( Constants.ROUTE_PATHS.identity) && location.hash === `#${Constants.ROUTE_HASH_IDS.newIdentity}`) {
      scrollToElement({elem: Constants.ROUTE_HASH_IDS.newIdentity });
      setIdentityAccordion(0);
    }
  }, [location])

  const pathIdentitySetUpWithLocale = withLocale(Constants.ROUTE_PATHS.identitySetUp);
  const pathDataboxSetUpWithLocale = withLocale(Constants.ROUTE_PATHS.databoxSetUp);
  const pathDataboxWithLocale = withLocale(Constants.ROUTE_PATHS.databox);

  return (
    <>
      <div>
        <Helmet>
          <html lang={lang ? lang : 'cs'} />
          <title lang={lang ? lang : 'cs'}>{t('identityPage.seo.title')}</title>
          <link rel="canonical" hrefLang={`${lang}`} href={`https://chcidatovku.gov.cz/${lang}/${Constants.ROUTE_PATHS.identity}`} />
          <link rel="canonical" hrefLang={`${lang}`} href={`https://chcidatovku.gov.cz/${lang}/${Constants.ROUTE_PATHS.identitySetUp}`} />
          <script type="application/ld+json">{faq}</script>
          <meta
            lang={lang ? lang : 'cs'}
            name="description"
            content={t('identityPage.seo.description')} />
        </Helmet>
      </div>
      <HomePageHeader
        version={ 'identity' }
        title={t('identityPage.header.titleOne')}
        subtitle={t('identityPage.header.subtitleOne')}
        buttonLinkIdentity={ pathIdentitySetUpWithLocale }
        onButtonLinkIdentityClick={ () => navigateTo(pathIdentitySetUpWithLocale) }
        onButtonLinkDataBoxClick={ () => {}}
        buttonTextIdentity={t('homePage.header.buttonTwo')}
      />
      <InfotileTabs scrollToEnd />
      <IconsWrapperNew />
      <section id={ 'jak-zalozit-identitu' }>
        <Container className={ 'gov-container--1200px u-bg-color--white pb-56' }>
          <Headline type={1} className={ 'heading2 plain-list__main-title' }>
            <span id={ Constants.ROUTE_HASH_IDS.newIdentity }>{t("identityPage.accordionsHeadingOne")}</span>
          </Headline>
          <IdentityAccordionsNew expandedId={identityAccordionExpandedId} onExpanded={(id: number | undefined) => {setIdentityAccordion(id)}} />
        </Container>
      </section>
      <section id={ 'caste-dotazy-identita' }>
        <Container className={ 'gov-container--900px u-bg-color--grey container__fourth-section pt-56' }>
          <Headline type={2} className={ 'heading2 u-mb--50' }>{t('identityPage.accordionsQAHeadingOne')}</Headline>
          <IdentityAccordionsQAndANew />
        </Container>
      </section>
      <section id={ 'karta-nova-identita' } className={ 'page-card' }>
        <Container className={ 'gov-container--900px u-bg-color--grey' }>
          <Headline type={2} className={ 'heading2' }>{t('identityPage.cardIdentityHeading')}</Headline>
          <Card version={ 'databox' }
                  title={t('cardSectionPage.titleOne')}
                  titleBlue={t('cardSectionPage.titleBlueOne')}
                  onButtonLinkFirstClick={ () => navigateTo(pathDataboxSetUpWithLocale) }
                  buttonLinkFirst={pathDataboxSetUpWithLocale}
                  buttonTextFirst={t('cardSectionPage.buttonOne')}
                  onButtonLinkSecondClick={ () => navigateTo(pathDataboxWithLocale) }
                  buttonLinkSecond={pathDataboxWithLocale}
                  buttonTextSecond={t('cardSectionPage.buttonTwo')}
            >{t('cardSectionPage.textOnePartOne')}&nbsp;
            <b>{t('cardSectionPage.textOnePartTwo')}</b>&nbsp;
              {t('cardSectionPage.textOnePartThree')}&nbsp;
              <b>{t('cardSectionPage.textOnePartFour')}</b>&nbsp;
            </Card>
        </Container>
      </section>
  </>)
}
