import * as React from 'react';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import {
  Button,
  Container,
  Glider,
  GliderItem,
  Headline,
  Paragraph,
  scrollToElement
} from '../../gov-components';

import time from '../../resources/icons/time.svg';
import stamp from '../../resources/icons/stamp.svg';
import moneybag from '../../resources/icons/moneybag.svg';
import world from '../../resources/icons/world.svg';

export const ContainerChciDatovku = () => {
  const { location: { pathname } }: { location: { pathname: string } } = useHistory();
  const { t } = useTranslation();
  const hasDifferentContent: boolean = pathname.includes('ua') || pathname.includes('ru');

  return (<>
    <Container className={ `u-bg-color--blue gov-container__chcidatovku${pathname.includes('ua') || pathname.includes('ru') ? '--translated-page' : ''} gov-container--1200px u-align--center` }>
      <div className="gov-hero-banner gov-hero-banner--inversed gov-hero-banner--full">
        <div className="gov-hero-banner__content">
          {hasDifferentContent ?
            <Headline type={1} className={ 'gov-hero-banner__headline u-color--white' }>{t("main.change.heading")}</Headline> :
            <Headline type={1} className={ 'gov-hero-banner__headline u-color--white' }>{t("main.heading")}</Headline>
          }
          <Headline type={2} className={ 'u-color--white' }>{t("main.secondary.heading")}</Headline>
        </div>
      </div>
      <Glider gridSize={4} className="u-color--white chcidatovku-glider">
        <GliderItem>
          <ReactSVG src={ time } className={ 'gov-glider-item__icon' } />
          <div className="gov-glider-item__title">
            <Paragraph className="gov-glider-item__text u-color--white">{t('main.iconHeading.first')}</Paragraph>
            <Paragraph className="gov-glider-item__text u-color--white">{t('main.iconText.first')}</Paragraph>
          </div>
        </GliderItem>
        <GliderItem>
          <ReactSVG src={ stamp } className={ 'gov-glider-item__icon' } />
          <div className="gov-glider-item__title">
            <Paragraph className="gov-glider-item__text u-color--white">{t('main.iconHeading.second')}</Paragraph>
            <Paragraph className="gov-glider-item__text u-color--white">{t('main.iconText.second')}</Paragraph>
          </div>
        </GliderItem>
        <GliderItem>
          <ReactSVG src={ moneybag } className={ 'gov-glider-item__icon' } />
          <div className="gov-glider-item__title">
            <Paragraph className="gov-glider-item__text u-color--white">{t('main.iconHeading.third')}</Paragraph>
            <Paragraph className="gov-glider-item__text u-color--white">{t('main.iconText.third')}</Paragraph>
          </div>
        </GliderItem>
        <GliderItem>
          <ReactSVG src={ world } className={ 'gov-glider-item__icon' } />
          <div className="gov-glider-item__title">
            <Paragraph className="gov-glider-item__text u-color--white">{t('main.iconHeading.fourth')}</Paragraph>
            <Paragraph className="gov-glider-item__text u-color--white">{t('main.iconText.fourth')}</Paragraph>
          </div>
        </GliderItem>
      </Glider>
      {hasDifferentContent ?
        <></> :
        <div className="gov-grid__button-part">
          <Button inversed={ true }
                  size={ 'large' }
                  className={ 'gov-grid__button-part--yellow' }
                  onClick={() => scrollToElement({ elem: 'jak-zalozit-datovku-id' })}
          >{t('main.button')}</Button>
        </div>
      }
      {hasDifferentContent ?
        <div className={ 'u-bg-color--white gov-container__chcidatovku--text-box u-align--center' }>
          {t('main.change.splitCard')}
        </div> :
        <div className={ 'u-bg-color--white gov-container__chcidatovku--text-box u-align--center' }>
          {t('main.splitCard')}
        </div>
      }
    </Container>
  </>)
}
