import * as React from 'react';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import {
  Button,
  Container,
  Glider,
  GliderItem,
  Headline,
  scrollToElement,
} from '../../gov-components';

import time from '../../resources/icons/hour.svg';
import hourglass from '../../resources/icons/hour-glass.svg';
import coins from '../../resources/icons/coins-fee.svg';

export const ContainerChciIdentitu = () => {
  const { location: { pathname } }: { location: { pathname: string } } = useHistory();
  const { t } = useTranslation();
  const hasDifferentContent: boolean = pathname.includes('ua') || pathname.includes('ru');

  return (<>
    <Container className={ `u-bg-color--blue gov-container__chciidentitu${pathname.includes('ua') || pathname.includes('ru') ? '--translated-page' : ''} gov-container--1200px u-align--center` }>
      <div className="gov-hero-banner gov-hero-banner--inversed gov-hero-banner--full">
        <div className="gov-hero-banner__content">
          <Headline type={1} className={ 'gov-hero-banner__headline u-color--white' }>{t("mainPage.headingOne")}</Headline>
          <Headline type={2} className={ 'u-color--white' }>{t("mainPage.headingTwo")}</Headline>
        </div>
      </div>
      {hasDifferentContent ?
        <></> :
        <Glider gridSize={3} className="u-color--white chciidentitu-glider">
          <GliderItem>
            <ReactSVG src={ time } alt={t("mainPage.landingPictures.altHourText")}
                      className={ 'gov-glider-item__icon' }/>
            <Headline type={3} className={ 'gov-glider-item__text u-color--white u-pt--30' }>{t("mainPage.landingPictures.hourText")}</Headline>
          </GliderItem>
          <GliderItem>
            <ReactSVG src={ hourglass } alt={t("mainPage.landingPictures.altHourGlassText")}
                      className={ 'gov-glider-item__icon'}/>
            <Headline type={3} className={ 'gov-glider-item__text u-color--white u-pt--30' }>{t("mainPage.landingPictures.hourGlassText")}</Headline>
          </GliderItem>
          <GliderItem>
            <ReactSVG src={coins} alt={t("mainPage.landingPictures.altCoinsText")}
                      className={ 'gov-glider-item__icon' }/>
            <Headline type={3} className={ 'gov-glider-item__text u-color--white u-pt--30' }>{t("mainPage.landingPictures.coinsText")}</Headline>
          </GliderItem>
        </Glider>
      }
      {hasDifferentContent ?
        <></> :
        <div className="gov-grid__button-part">
          <Button
            color={ 'primary' }
            inversed={ true }
            size={ 'large' }
            className={ 'gov-grid__button-part--yellow' }
            onClick={() => scrollToElement({ elem: 'chci-identitu-id' })}
          >{t("mainPage.landingButton")}</Button>
        </div>
      }
      <div className={ 'u-bg-color--white gov-container__chciidentitu--text-box u-align--center' }>
        {t("mainPage.banner.text")}
      </div>
    </Container>
  </>)
}
