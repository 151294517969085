import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { Container, Headline } from '../gov-components';
import {
  ContainerChciIdentitu,
  CzechAndEnglishIdentityAccordions,
  CzechAndEnglishIdentityAccordionsQAndA,
  CzechAndEnglishIdentityModals,
  IconsWrapperOld,
  UkraineAndRussianIdentityAccordions,
  UkraineAndRussianIdentityAccordionsQAndA,
  UkraineAndRussianIdentityModals
} from './index';
import * as Constants from '../util/constants';
import { IdentityOldFAQ } from './components/JsonLd';

export const IdentityPageOld = () => {
  const [expandedNumber] = useState<number | undefined>();
  const { location: { pathname } }: { location: { pathname: string } } = useHistory();
  const hasDifferentContent: boolean = pathname.includes('ua') || pathname.includes('ru');
  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem('lang');
  const faq = IdentityOldFAQ(t);

  useEffect(() => {
    const langToTranslateTo: string = pathname.split('/')[1];

    if (langToTranslateTo !== '') {
      i18n.changeLanguage(langToTranslateTo);
    } else {
      i18n.changeLanguage('cs');
    }
  }, [pathname]);

  return (<>
    <Helmet>
      <html lang={lang ? lang : 'cs'} />
      <title lang={lang ? lang : 'cs'}>{t('identityPage.seo.title')}</title>
      <link rel="canonical" hrefLang={`${lang}`} href={`https://chcidatovku.gov.cz/${lang}/${Constants.ROUTE_PATHS.identity}`} />
      <script type="application/ld+json">{faq}</script>
      <meta
        lang={lang ? lang : 'cs'}
        name="description"
        content={t('identityPage.seo.description')} />
    </Helmet>
    <ContainerChciIdentitu />
    <Container className={ 'gov-container--1200px u-bg-color--white container__first-section' }>
      <div className={ 'container__first-section--text-box' }>
        {t("mainPage.banner.text")}
      </div>
    </Container>
    <Container className={ 'gov-container--900px u-bg-color--grey container__second-section' }>
      <Headline type={2} className={ 'heading2 container__second-section--identity-title' }>{t("mainPage.sectionOne.headingOne")}</Headline>
      {hasDifferentContent ?
        <UkraineAndRussianIdentityModals /> :
        <CzechAndEnglishIdentityModals />
      }
    </Container>
    {!hasDifferentContent &&
      <IconsWrapperOld />
    }
    <section id={ 'chci-identitu-id' }>
      <Container className={ 'gov-container--1200px u-bg-color--white' }>
        <Headline type={2} className={ 'heading2 plain-list__main-title' }>{t("mainPage.sectionThree.headingOne")}</Headline>
        {hasDifferentContent ?
          <UkraineAndRussianIdentityAccordions /> :
          <CzechAndEnglishIdentityAccordions />
        }
      </Container>
    </section>
    <section id={ 'caste-dotazy-id' }>
      <Container className={ 'gov-container--900px u-bg-color--grey container__fourth-section' }>
        <Headline type={2} className={ 'heading2' }>{t("mainPage.sectionFour.headingOne")}</Headline>
        {hasDifferentContent ?
          <UkraineAndRussianIdentityAccordionsQAndA expandedNumber={ expandedNumber } /> :
          <CzechAndEnglishIdentityAccordionsQAndA expandedNumber={ expandedNumber } />
        }
      </Container>
    </section>
  </>)
}
