/**
 * Paragraph Component
 */

import * as React from 'react';
import { ReactNode } from 'react';
import clsx from 'clsx';

export interface ParagraphProps {
  /** If true, text is bold */
  bold?: boolean;
  /** Custom class applied to root element */
  className?: string;
  /** Represents text or another component */
  children: ReactNode;
  /** Passing all other props to component */
  props?: any;
}

export const Paragraph = ({ bold = false, className, children, ...props }: ParagraphProps) => (
  <p className={clsx(className, { 'u-font-weight--bold': bold })} {...props}>
    {children}
  </p>
);
