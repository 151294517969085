import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface LinkProps {
  size?: 'small' | 'large' | 'normal';
  disabled?: boolean;
  inversed?: boolean;
  external?: boolean;
  hasArrow?: boolean;
  standalone?: boolean;
  children: any;
  href?: string;
  onClick?: any;
  className?: string;
  target?: string;
  title?: string;
}

export const Link = (props: LinkProps) => {
  const { children, target, title, size, disabled, inversed, external, hasArrow, standalone, href, onClick, className } = props;
  let finalClassName: string = 'gov-link';

  finalClassName = className ?
    `${finalClassName} ${className}` :
    finalClassName;
  finalClassName = disabled ?
    `${finalClassName} gov-link--disabled` :
    finalClassName;
  finalClassName = size ?
    `${finalClassName} gov-link--${size}` :
    finalClassName;
  finalClassName = inversed ?
    `${finalClassName} gov-link--inversed` :
    finalClassName;
  finalClassName = external ?
    `${finalClassName} gov-link--external` :
    finalClassName;
  finalClassName = hasArrow ?
    `${finalClassName} gov-link--has-arrow` :
    finalClassName;
  finalClassName = standalone ?
    `${finalClassName} gov-link--standalone` :
    finalClassName;

  if (href) {
    return href.startsWith('http') ?
      <a
        href={href}
        className={finalClassName}
        target={target}
        title={title}
      >{children}</a> :
      <RouterLink
        to={href}
        className={finalClassName}
        onClick={onClick}
        target={target}
        title={title}
      >{children}</RouterLink>
  } else {
    return (<button
      onClick={onClick}
      className={finalClassName}
      title={title}
    >
      {children}
    </button>);
  }
}
