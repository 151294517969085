import * as React from 'react'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { PageNotFound } from './pages/';
import { Footer, HeaderChciIdentitu } from './gov-components';
import { routes } from './resources/content/routes';
import * as Constants from './util/constants';

interface IRoutes {
  id: number,
  lang: string,
  route: string,
  component: React.FC
}

interface LangResponse {
  lang: string;
  callback: undefined | (() => void);
}

export const Layout = () => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { location: { pathname } }: { location: { pathname: string } } = history;

  useEffect(() => {
    const { lang, callback } = determineLang(pathname);
    setLang(lang);
    callback && callback();
  }, [location])

  useEffect(() => {
    const referrer = document.referrer;
    if (referrer.startsWith('https://chciidentitu')) {
      history.push(Constants.ROUTE_PATHS.identity);
    }
  }, [document?.referrer]);

  const determineLang = (pathname: string): LangResponse => {
    const langToTranslateTo: string = pathname.split('/')[1];

    if (pathname === "/") {
      return {
        lang: Constants.LOCALES.cs,
        callback: () => {
          history.push(Constants.ROUTE_PATHS.databox);
        }
      }
    }

    Constants.SUPPORTED_LOCALES
      .filter(i => i !== Constants.LOCALES.cs)
      .forEach(lang => {
      if (pathname === `/${lang}` || pathname === `/${lang}/`) {
        return {
          lang,
          callback: () => {
            history.push(`/${lang}${Constants.ROUTE_PATHS.databox}`);
          }
        }
      }
    })

    if (!Constants.SUPPORTED_LOCALES.some(i => i === langToTranslateTo)) {
      // not a supported locale, so use default
      return {
        lang: Constants.LOCALES.cs,
        callback: undefined
      }
    }
    // TEMP: Remove once full support is added for UA and RU
    if (pathname === Constants.ROUTE_PATHS.intro) {
      return {
        lang: Constants.LOCALES.cs,
        callback: undefined
      }
    }

    if (langToTranslateTo !== '') {
      return {
        lang: langToTranslateTo,
        callback: undefined
      }
    }
    else {
      return {
        lang: Constants.LOCALES.cs,
        callback: undefined
      }
    }
  }

  const setLang = (langToSet: string) => {    
    persistLang(langToSet);
    localizePage();
  }

  const persistLang = (langToSet: string) => {
    localStorage.setItem('lang', langToSet);
  }

  const localizePage = () => {
    const pathname = location.pathname;
    const langInPathIfOtherThanCs = Constants.SUPPORTED_LOCALES
      .filter(i => i !== Constants.LOCALES.cs)
      .find(i => pathname.startsWith(`/${i}/`));
    
    const persistedLang = localStorage.getItem('lang');
    // prioritise lang in pathName if other than 'cs'
    const langToSet = langInPathIfOtherThanCs != null ?
      langInPathIfOtherThanCs :
      persistedLang != null ?
        persistedLang :
        Constants.LOCALES.cs;
        
    setTimeout(() => {
      i18n.changeLanguage(langToSet);
      persistLang(langToSet);
    }, 1);
  };

  return (
    <div className="gov-page">
      <HeaderChciIdentitu version={'new'} />
      <Switch>
        {routes.map((route: IRoutes) => {
          if(route.lang === 'cs') return <Route path={`/${route.route}`} key={route.id} component={route.component} />
          if(route.route === '/') return <Route path={`/${route.lang}`} key={route.id} component={route.component} />
          if(route.route === 'en' || route.route === 'ua' || route.route === 'ru' ) {
            return <Route path={ `/${ route.route }` } key={ route.id } component={ route.component }/>
          }
          return <Route path={`/${route.lang}/${route.route}`} key={route.id} component={route.component} />
        })}
        <Route path='*' component={PageNotFound} />
      </Switch>
      <Footer />
    </div>
  )
}
