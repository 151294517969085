import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Headline } from './Headline';
import { Paragraph } from './Paragraph';
import { Button } from './Button';

import identityTile from '../resources/images/NIA-tile.png';
import databoxTile from '../resources/images/databox-tile.png';

export interface CardProps {
  children?: any;
  className?: string;
  title?: string;
  titleBlue?: string;
  buttonTextFirst?: string;
  buttonLinkFirst?: string;
  buttonTextSecond?: string;
  buttonLinkSecond?: string;
  onButtonLinkFirstClick: () => void;
  onButtonLinkSecondClick: () => void;
  version: 'databox' | 'identity';
}

export const Card = ({ className, children, version, ...props }: CardProps) => {
  return (
    <div className={ clsx('gov-card', className, { [`gov-card__${ version }`]: version }) }>
      <CardImage version={ version } className={ 'gov-card__image' }/>
      <div className='gov-card__text-wrapper'>
        <Headline type={3} className={ 'gov-card__text-wrapper--title' }>
          { props.title }
        </Headline>
        <Headline type={3} className={ 'gov-card__text-wrapper--blue-title' }>
          { props.titleBlue }
        </Headline>
        <Paragraph children={ children } className={ 'gov-card__text-wrapper--text' }/>
        <div className='gov-card__text-wrapper--buttons'>
          <Button color={ 'primary' }
                  size={ 'small' }
                  onClick={ (ev: any) => { 
                    props.onButtonLinkFirstClick();
                    ev.preventDefault();
                  }}
                  href={ props.buttonLinkFirst }
                  children={ props.buttonTextFirst }
                  className={ 'gov-card__text-wrapper--button-one' }/>
          <Button color={ 'primary-outlined' }
                  size={ 'small' }
                  onClick={ (ev: any) => {
                    props.onButtonLinkSecondClick();
                    ev.preventDefault();
                  }}
                  href={ props.buttonLinkSecond }
                  children={ props.buttonTextSecond }
                  className={ 'gov-card__text-wrapper--button-two' }/>
        </div>
      </div>
    </div>
  )
}

export interface CardImageProps {
  className?: string;
  version: 'databox' | 'identity';
}

export const CardImage = ({ version, className }: CardImageProps) => {
  const { t } = useTranslation();

  return (
    <>
      {version === 'databox' &&
        <>
          <img src={ databoxTile }
               alt={t(`databoxImageCard.altOne`)}
               className={ className } />
        </>
      }
      {version === 'identity' &&
        <>
          <img src={ identityTile }
               alt={t(`identityImageCard.altOne`)}
               className={ className } />
        </>
      }
    </>
  )
}

