import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Headline, Paragraph } from '../../gov-components';
import { ReactSVG } from 'react-svg';

import airbank from '../../resources/icons/airbank.png';
import ceskaSporitelna from '../../resources/icons/ceskasporitelna.png';
import csobId from '../../resources/icons/csob-id.png';
import komercniBanka from '../../resources/icons/komercnibanka.png';
import moneta from '../../resources/icons/moneta.png';
import fioBanka from '../../resources/icons/fio-banka.png';
import raiffeisenBank from '../../resources/icons/raiffeisenbank.png';
import unicreditBank from '../../resources/icons/unicredit.png';
import creditas from '../../resources/icons/creditas.png';
import mbank from '../../resources/icons/mbank.png';
import dataBox from '../../resources/icons/data-box.svg';
import erbGov from '../../resources/icons/erb-logo.svg';
import injection from '../../resources/icons/injection.svg';
import pillsPrescription from '../../resources/icons/pills-prescription.svg';
import portalForRegions from '../../resources/icons/portal-for-regions.svg';
import portalInsuranceCompanies from '../../resources/icons/portal-insurance-companies.svg';
import firstCertificateAuthority from '../../resources/icons/1certificate-authority.png';
import MEPLogo from '../../resources/icons/MEP-logo.png';
import mojeIDLogo from '../../resources/icons/mojeID-logo.png';
import NIALogo from '../../resources/icons/NIA-logo.png';
import unionLogo from '../../resources/icons/union-logo.png';
import loginRightArrow from '../../resources/icons/login-right.svg';
import portalMyTaxes from '../../resources/icons/portal-my-taxes.svg';

export const IconsWrapperOld = () => {
  const { t } = useTranslation();

  return (
    <section id={ 'sekce-ikony-ua-ru-verze' } className={ 'icon-cards-wrapper' }>
      <Container className={ 'gov-container--1200px u-bg-color--white' }>
        <Headline type={2} className={ 'heading2 container__main-title' }>{t("mainPage.sectionTwo.headingOne")}</Headline>
        <Paragraph className={ 'container__subtitle' }>{t("mainPage.sectionTwo.textParagraphOne")}</Paragraph>
        <div className={ 'container__third-section' }>
          <div className={ 'container__third-section--part-one u-bg-color--grey' }>
            <Paragraph className={ 'container__third-section--heading' }>{t("mainPage.sectionTwo.imagePartOneHeading")}</Paragraph>
            <div>
              <Paragraph className={ 'container__third-section--heading-smaller' }>{t("mainPage.sectionTwo.imagePartOneHeadingSmallOne")}</Paragraph>
              <div className={ 'container__third-section--logos' }>
                <div className={ 'container__third-section--logo-item' }>
                  <img className={ 'container__third-section--logo-icon' }
                       src={ MEPLogo }
                       alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallOneItemOneAltText")}/>
                  <Paragraph className={'container__third-section--logo-text'}>{t("mainPage.sectionTwo.imagePartOneHeadingSmallOneItemOneText")}</Paragraph>
                </div>
                <div className={ 'container__third-section--logo-item' }>
                  <img className={ 'container__third-section--logo-icon' }
                       src={ NIALogo }
                       alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallOneItemTwoAltText")}/>
                  <Paragraph className={'container__third-section--logo-text'}>{t("mainPage.sectionTwo.imagePartOneHeadingSmallOneItemTwoText")}</Paragraph>
                </div>
                <div className={ 'container__third-section--logo-item' }>
                  <img className={ 'container__third-section--logo-icon' }
                       src={ NIALogo }
                       alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallOneItemThreeAltText")}/>
                  <Paragraph className={ 'container__third-section--logo-text' }>{t("mainPage.sectionTwo.imagePartOneHeadingSmallOneItemThreeText")}</Paragraph>
                </div>
              </div>
            </div>
            <div>
              <Paragraph className={ 'container__third-section--heading-smaller' }>{t("mainPage.sectionTwo.imagePartOneHeadingSmallTwo")}</Paragraph>
              <div className={ 'container__third-section--logos' }>
                <div className={ 'container__third-section--logo-item' }>
                  <img className={ 'container__third-section--logo-icon' }
                       src={ airbank }
                       alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemFourAltText")}/>
                  <Paragraph className={'container__third-section--logo-text'}>{t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemFourText")}</Paragraph>
                </div>
                <div className={ 'container__third-section--logo-item' }>
                  <img className={ 'container__third-section--logo-icon' }
                       src={ ceskaSporitelna }
                       alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemOneAltText")}/>
                  <Paragraph className={ 'container__third-section--logo-text' }>{t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemOneText")}</Paragraph>
                </div>
                <div className={ 'container__third-section--logo-item' }>
                  <img className={ 'container__third-section--logo-icon' }
                       src={ csobId }
                       alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemTwoAltText")}/>
                  <Paragraph className={ 'container__third-section--logo-text' }>{t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemTwoText")}</Paragraph>
                </div>
                <div className={ 'container__third-section--logo-item' }>
                  <img className={ 'container__third-section--logo-icon' }
                       src={ fioBanka }
                       alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemSevenAltText")}/>
                  <Paragraph className={ 'container__third-section--logo-text' }>{t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemSevenText")}</Paragraph>
                </div>
                <div className={ 'container__third-section--logo-item' }>
                  <img className={ 'container__third-section--logo-icon' }
                       src={ komercniBanka }
                       alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemThreeAltText")}/>
                  <Paragraph className={ 'container__third-section--logo-text' }>{t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemThreeText")}</Paragraph>
                </div>
                <div className={ 'container__third-section--logo-item' }>
                  <img className={ 'container__third-section--logo-icon' }
                       src={ moneta }
                       alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemSixAltText")}/>
                  <Paragraph className={ 'container__third-section--logo-text' }>{t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemSixText")}</Paragraph>
                </div>
                <div className={ 'container__third-section--logo-item' }>
                  <img className={ 'container__third-section--logo-icon' }
                       src={ raiffeisenBank }
                       alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemFiveAltText")}/>
                  <Paragraph className={ 'container__third-section--logo-text' }>{t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemFiveText")}</Paragraph>
                </div>
                <div className={ 'container__third-section--logo-item' }>
                  <img className={ 'container__third-section--logo-icon' }
                       src={ unicreditBank }
                       alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemEightAltText")}/>
                  <Paragraph className={ 'container__third-section--logo-text' }>{t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemEightText")}</Paragraph>
                </div>
                <div className={ 'container__third-section--logo-item' }>
                  <img className={ 'container__third-section--logo-icon' }
                       src={ creditas }
                       alt={t("mainPage.sectionThree.itemOne.imageTextAltNine")}/>
                  <Paragraph className={ 'container__third-section--logo-text' }>{t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemEightText")}</Paragraph>
                </div>
                <div className={ 'container__third-section--logo-item' }>
                  <img className={ 'container__third-section--logo-icon' }
                       src={ mbank }
                       alt={t("mainPage.sectionThree.itemOne.imageTextAltTen")}/>
                  <Paragraph className={ 'container__third-section--logo-text' }>{t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemEightText")}</Paragraph>
                </div>
              </div>
            </div>
            <div>
              <Paragraph className={ 'container__third-section--heading-smaller' }>{t("mainPage.sectionTwo.imagePartOneHeadingSmallThree")}</Paragraph>
              <div className={ 'container__third-section--logos' }>
                <div className={ 'container__third-section--logo-item' }>
                  <img className={ 'container__third-section--logo-icon' }
                       src={ mojeIDLogo }
                       alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallThreeItemOneAltText")}/>
                  <Paragraph className={ 'container__third-section--logo-text' }>{t("mainPage.sectionTwo.imagePartOneHeadingSmallThreeItemOneText")}</Paragraph>
                </div>
                <div className={ 'container__third-section--logo-item' }>
                  <img className={ 'container__third-section--logo-icon' }
                       src={ unionLogo }
                       alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallThreeItemTwoAltText")}/>
                  <Paragraph className={ 'container__third-section--logo-text' }>{t("mainPage.sectionTwo.imagePartOneHeadingSmallThreeItemTwoText")}</Paragraph>
                </div>
                <div className={ 'container__third-section--logo-item' }>
                  <img className={ 'container__third-section--logo-icon' }
                       src={ firstCertificateAuthority }
                       alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallThreeItemThreeAltText")}/>
                  <Paragraph className={ 'container__third-section--logo-text' }>{t("mainPage.sectionTwo.imagePartOneHeadingSmallThreeItemThreeText")}</Paragraph>
                </div>
              </div>
            </div>
          </div>
          <div className={ 'container__third-section--part-two' }>
            <ReactSVG src={ loginRightArrow }
                      alt={t("mainPage.sectionTwo.imagePartMiddleImageAltText")}
                      className={ 'container__third-section--login-arrow' } />
            <Paragraph className={ 'container__third-section--heading-smaller' }>{t("mainPage.sectionTwo.imagePartMiddleImageText")}</Paragraph>
          </div>
          <div className={ 'container__third-section--part-three u-bg-color--grey' }>
            <Paragraph className={ 'container__third-section--heading u-mb--35' }>{t("mainPage.sectionTwo.imagePartTwoHeading")}</Paragraph>
            <div className={ 'container__third-section--item' }>
              <ReactSVG src={ erbGov }
                        alt={t("mainPage.sectionTwo.imagePartTwoHeadingItemOneAltText")}
                        className={ 'erb-gov-blue' } />
              <Paragraph className={ 'container__third-section--logo-name' }>{t("mainPage.sectionTwo.imagePartTwoHeadingItemOneText")}</Paragraph>
            </div>
            <div className={ 'container__third-section--item' }>
              <ReactSVG src={ injection }
                        alt={t("mainPage.sectionTwo.imagePartTwoHeadingItemTwoAltText")}
                        className={ 'injection-icon' } />
              <Paragraph className={ 'container__third-section--logo-name' }>{t("mainPage.sectionTwo.imagePartTwoHeadingItemTwoText")}</Paragraph>
            </div>
            <div className={ 'container__third-section--item' }>
              <ReactSVG src={ dataBox }
                        alt={t("mainPage.sectionTwo.imagePartTwoHeadingItemThreeAltText")}
                        className={ 'data-box-icon' } />
              <Paragraph className={ 'container__third-section--logo-name' }>{t("mainPage.sectionTwo.imagePartTwoHeadingItemThreeText")}</Paragraph>
            </div>
            <div className={ 'container__third-section--item' }>
              <span className="gov-complex-icon gov-complex-icon--map" />
              <Paragraph className={ 'container__third-section--logo-name' }>{t("mainPage.sectionTwo.imagePartTwoHeadingItemSixText")}</Paragraph>
            </div>
            <div className={ 'container__third-section--item' }>
              <ReactSVG src={ portalMyTaxes }
                        alt={t("mainPage.sectionTwo.imagePartTwoHeadingItemFourAltText")}
                        className={ 'portal-my-taxes' } />
              <Paragraph className={ 'container__third-section--logo-name' }>{t("mainPage.sectionTwo.imagePartTwoHeadingItemFourText")}</Paragraph>
            </div>
            <div className={ 'container__third-section--item' }>
              <ReactSVG src={ pillsPrescription }
                        alt={t("mainPage.sectionTwo.imagePartTwoHeadingItemFiveAltText")}
                        className={ 'pills-prescription' } />
              <Paragraph className={ 'container__third-section--logo-name' }>{t("mainPage.sectionTwo.imagePartTwoHeadingItemFiveText")}</Paragraph>
            </div>
            <div className={ 'container__third-section--item' }>
              <ReactSVG src={ portalInsuranceCompanies }
                        alt={t("mainPage.sectionTwo.imagePartTwoHeadingItemEightAltText")}
                        className={ 'portal-company-insurance' } />
              <Paragraph className={ 'container__third-section--logo-name' }>{t("mainPage.sectionTwo.imagePartTwoHeadingItemEightText")}</Paragraph>
            </div>
            <div className={ 'container__third-section--item' }>
              <ReactSVG src={ portalForRegions }
                        alt={t("mainPage.sectionTwo.imagePartTwoHeadingItemSevenAltText")}
                        className={ 'portal-for-regions' } />
              <Paragraph className={ 'container__third-section--logo-name' }>{t("mainPage.sectionTwo.imagePartTwoHeadingItemSevenText")}</Paragraph>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
