import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Container,
  Headline,
} from '../gov-components';

import NIADSHeader from './../resources/images/NIA-DS-header.png';
import NIAHeader from './../resources/images/NIA-header.png';
import databoxHeader from './../resources/images/databox-header.png';
import databoxHeaderMobile from './../resources/images/databox-header-mobile.png';

export interface HomePageProps {
  title?: string;
  subtitle?: string;
  buttonTextDatabox?: string;
  buttonLinkDatabox?: string;
  onButtonLinkDataBoxClick: () => void;
  buttonTextIdentity?: string;
  buttonLinkIdentity?: string;
  onButtonLinkIdentityClick: () => void;
  version: 'home' | 'databox' | 'identity';
}

export const HomePageHeader = ({ version, ...props }: HomePageProps) => {

  return (<>
    <section id="uvod-stranky" className="main-container-section">
      <Container className="gov-container">
        <div className="gov-main-container">
          <div className="text-wrapper">
            <div className="gov-main-container__text-wrapper">
              <Headline type={2} className="gov-main-container__text-wrapper--heading-one heading1">{props.title}</Headline>
              <div className="gov-main-container__mobile-image">
                <Headline type={2} className="gov-main-container__text-wrapper--heading-two u-color--white">{props.subtitle}</Headline>
                <HomePageImage version={version} className="mobile-image" />
              </div>
            </div>
            <div className="image-wrapper">
              <HomePageImage version={version} className="main-container-image" />
            </div>
          </div>
          <div className='gov-main-container__buttons-holder'>
            <div className='gov-main-container__buttons-holder--inner'>
              <HomePageButtons version={ version }
                               buttonTextDatabox={ props.buttonTextDatabox }
                               buttonLinkDatabox={ props.buttonLinkDatabox }
                               onButtonLinkDataBoxClick={ props.onButtonLinkDataBoxClick }
                               buttonTextIdentity={ props.buttonTextIdentity }
                               buttonLinkIdentity={ props.buttonLinkIdentity }
                               onButtonLinkIdentityClick={ props.onButtonLinkIdentityClick } />
            </div>
          </div>
        </div>
      </Container>
    </section>
  </>)
}

export interface HomePageItemProps {
  className?: string;
  buttonTextDatabox?: string;
  buttonLinkDatabox?: string;
  onButtonLinkDataBoxClick: () => void;
  buttonTextIdentity?: string;
  buttonLinkIdentity?: string;
  onButtonLinkIdentityClick: () => void;
  version: 'home' | 'databox' | 'identity';
}

export const HomePageImage = ({ className }: any) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <img src={databoxHeader}
              alt={t(`homePage.headerAltTwo`)}
              id="databox-image"
              className={className} />
        <img src={databoxHeaderMobile}
              alt={t(`homePage.headerAltTwo`)}
              id="databox-image-mobile"
              className={className} />
      </div>
    </>
  )
}

export const HomePageButtons = ({ version,
                                  buttonLinkDatabox,
                                  buttonTextDatabox,
                                  onButtonLinkDataBoxClick,
                                  buttonLinkIdentity,
                                  buttonTextIdentity,
                                  onButtonLinkIdentityClick
                                }: HomePageItemProps) => {
  return (
    <>
      {version === 'home' &&
      <>
        <Button
          color={'primary'}
          inversed={true}
          size={'normal'}
          className={'gov-main-container__buttons-holder--button-one'}
          onClick={ (ev: any) => {
            onButtonLinkDataBoxClick();
            ev.preventDefault();
          }}
          href={ buttonLinkDatabox }
        >{ buttonTextDatabox }</Button>
        <Button
          color={'primary'}
          inversed={true}
          size={'normal'}
          className={'gov-main-container__buttons-holder--button-two'}
          onClick={ (ev: any) => {
            onButtonLinkIdentityClick();
            ev.preventDefault();
          }}
          href={ buttonLinkIdentity }
        >{ buttonTextIdentity }</Button>
      </>
      }
      {version === 'databox' &&
      <>
        <Button
          color={ 'primary' }
          inversed={true}
          size={ 'normal' }
          className={ 'gov-main-container__buttons-holder--button-one' }
          onClick={ (ev: any) => {
            onButtonLinkDataBoxClick();
            ev.preventDefault();
          }}
          href={ buttonLinkDatabox }
        >{ buttonTextDatabox }</Button>
      </>
      }
      {version === 'identity' &&
      <>
        <Button
          color={ 'primary' }
          inversed={true}
          size={ 'normal' }
          className={ 'gov-main-container__buttons-holder--button-one' }
          onClick={ (ev: any) => {
            onButtonLinkIdentityClick();
            ev.preventDefault();
          }}
          href={ buttonLinkIdentity }
        >{ buttonTextIdentity }</Button>
      </>
      }
    </>
  )
}
