import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Accordion,
  AccordionItem,
  Alert,
  AlertList,
  Container,
  Headline,
  Link,
  List,
  ListItem,
  Paragraph,
} from '../../gov-components';

import externalLink from '../../resources/icons/external.svg';
import online from '../../resources/icons/online.svg';
import settings from '../../resources/icons/settings.svg';
import mapPin from '../../resources/icons/map-pin.svg';

export const DataboxAccordions = () => {
  const { location: { pathname } }: { location: { pathname: string } } = useHistory();
  const { t } = useTranslation();
  const hasDifferentContent: boolean = pathname.includes('ua') || pathname.includes('ru');

  return (
    <Container className={ 'gov-container--900px u-bg-color--white' }>
      <Headline type={2} className={'heading2 plain-list__main-title'}>{t('establishment.heading')}</Headline>
      <Accordion version={ 'new' }>
        <AccordionItem title={t('establishment.online.heading')}
                       keyNumber={0}
                       icon={<img src={ online }
                                  alt={t(`establishment.altOne`)} />}
        >
          <Headline type={3} className={ 'p-bigger u-mb--25' }>{t('establishment.online.secondary.heading')}</Headline>
          <List plain={true}>
            <ListItem>
              <Headline type={4} className={ 'plain-list__smaller-title' }>{t('establishment.online.first.heading')}</Headline>
              <Paragraph className={ 'u-ml--20' }>
                <Link
                  title={t('establishment.titleOne')}
                  href={ 'https://obcan.portal.gov.cz/' }
                  target="_blank"
                  className={ 'plain-list__link' }
                >{t('establishment.online.first.text.first')}
                  <img className={ 'plain-list__external-link' }
                       src={ externalLink }
                       alt={t("mainPage.imageLinkExternal")} />
                </Link>&nbsp; {t('establishment.online.first.text.second')}</Paragraph>
            </ListItem>
            {!hasDifferentContent &&
            <AlertList>
              <Alert inContent={true}
                     globalClosable={false}
                     className={ 'u-mb--16' }
              >
                <Paragraph className={ 'gov-alert__content--bigger-text' }>{t('establishment.online.alert')}&nbsp;
                  <Link
                    title={t('establishment.titleTwo')}
                    href={ 'https://chciidentitu.gov.cz/' }
                    target="_blank"
                    className={ 'plain-list__link' }
                  >chciidentitu.gov.cz&nbsp;
                    <img src={ externalLink }
                         alt={t("mainPage.imageLinkExternal")} />
                  </Link>
                </Paragraph>
              </Alert>
            </AlertList>
            }
            <ListItem>
              <Headline type={4} className={ 'plain-list__smaller-title' }>{t('establishment.online.second.heading')}</Headline>
              <Paragraph className={ 'u-ml--20' }>{t('establishment.online.second.text.first')}</Paragraph>
            </ListItem>
            <ListItem>
              <Headline type={4} className={ 'plain-list__smaller-title' }>{t('establishment.online.third.heading')}</Headline>
              <Paragraph className={ 'u-ml--20' }>{t('establishment.online.third.text.first')}</Paragraph>
            </ListItem>
            <ListItem>
              <Headline type={4} className={ 'plain-list__smaller-title' }>{t('establishment.online.fourth.heading')}</Headline>
              <Paragraph className={ 'u-ml--20' }>{t('establishment.online.fourth.text.first')}&nbsp;
                <img src={ settings }
                     alt={t(`establishment.altTwo`)} />{t('establishment.online.fourth.text.second')}</Paragraph>
            </ListItem>
          </List>
        </AccordionItem>
        <AccordionItem title={t('establishment.czechpoint.heading')}
                       icon={<img src={ mapPin }
                                  alt={t(`establishment.altThree`)} />}
                       keyNumber={1}
        >
          {!hasDifferentContent &&
          <Headline type={3} className={ 'p-bigger u-mb--25' }>{ t('establishment.czechpoint.secondary.heading') }</Headline>
          }
          <List plain={true} className={ 'plain-list__first-part' }>
            <ListItem>
              <Headline type={4} className={ 'plain-list__smaller-title' }>{t('establishment.czechpoint.first.heading')}</Headline>
              <Paragraph className={ 'u-ml--20' }>{t('establishment.czechpoint.first.text.first')}&nbsp;
                <Link
                  title={t(`establishment.titleThree`)}
                  href={ 'https://www.czechpoint.cz/wwwstats/f?p=100%3A16' }
                  target="_blank"
                  className={ 'plain-list__link' }
                >{t('establishment.czechpoint.first.text.second')}
                  <img className={ 'plain-list__external-link' }
                       src={ externalLink }
                       alt={t("mainPage.imageLinkExternal")} />
                </Link>.
              </Paragraph>
            </ListItem>
            {!hasDifferentContent &&
            <AlertList>
              <Alert inContent={true}
                     globalClosable={false}
                     className={ 'u-mb--16' }
              >
                <Paragraph className={ 'gov-alert__content--bigger-text' }>{t('establishment.czechpoint.alert')}</Paragraph>
              </Alert>
            </AlertList>
            }
            <ListItem>
              <Headline type={4} className={ 'plain-list__smaller-title' }>{t('establishment.czechpoint.second.heading')}</Headline>
              <Paragraph className={ 'u-ml--20' }>{t('establishment.czechpoint.second.text.first')}</Paragraph>
            </ListItem>
            <ListItem>
              <Headline type={4} className={ 'plain-list__smaller-title' }>{t('establishment.czechpoint.third.heading')}</Headline>
              <Paragraph className={ 'u-ml--20' }>{t('establishment.czechpoint.third.text.first')}&nbsp;
                <Link
                  title={t(`establishment.titleFour`)}
                  href={ 'https://www.mojedatovaschranka.cz' }
                  target="_blank"
                  className={ 'plain-list__link' }
                >www.mojedatovaschranka.cz
                  <img className={ 'plain-list__external-link' }
                       src={ externalLink }
                       alt={t("mainPage.imageLinkExternal")} />
                </Link>&nbsp; {t('establishment.czechpoint.third.text.second')}</Paragraph>
            </ListItem>
          </List>
        </AccordionItem>
      </Accordion>
    </Container>
  )
}
