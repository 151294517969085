const i18next = require('i18next');
const i18n = require('react-i18next');
const translationCS = require('../public/locales/cs/Translation.json');
const translationEN = require('../public/locales/en/Translation.json');
const translationUA = require('../public/locales/uk/Translation.json');
const translationRU = require('../public/locales/ru/Translation.json');
const { initReactI18next } = i18n;

const getDefaultLang = () => {
  let lang = localStorage.getItem('lang');

  if (lang == null) {
    const urlLng = window.location?.pathname?.split('/')[1];
    if (['ua', 'ru', 'en'].includes(urlLng)) {
      lang = urlLng;
    } else {
      lang = 'cs';
    }
  }

  return lang;
};

i18next.use(initReactI18next).init({
  fallbackLng: 'cs',
  lng: getDefaultLang(),
  supportedLngs: ['ua', 'ru', 'cs', 'en'],
  interpolation: {
    escapeValue: false,
  },

  resources: {
    cs: {
      translation: translationCS,
    },
    en: {
      translation: translationEN,
    },
    ru: {
      translation: translationRU,
    },
    ua: {
      translation: translationUA,
    },
  },
});
