import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface BreadcrumbsProps {
  className?: string;
}

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { className } = props;
  const { t } = useTranslation();
  let finalClass: string = 'gov-breadcrumbs';

  finalClass = className ?
    `${finalClass} ${className}` :
    finalClass;

  return (<div className={finalClass}>
    <span className="gov-breadcrumbs__item">
      <a className="gov-link gov-link--standalone" href="https://portal.gov.cz/">{t("breadcrumbs.itemOne")}</a>
    </span>
    <span className="gov-breadcrumbs__item">
      <a className="gov-link gov-link--standalone"
         href="/">{t("breadcrumbs.itemTwo")}</a>
    </span>
    <span className="gov-breadcrumbs__item">
      <strong className="gov-title gov-title--delta">{t("breadcrumbs.itemThree")}</strong>
    </span>
  </div>)
}
