export interface ScrollToElementProps {
  elem: any;
  axis?: 'x' | 'y';
  parent?: any;
}

export const scrollToElement = (props: ScrollToElementProps) => {
  const { elem, axis, parent } = props;

  if (typeof elem == 'number') {
    window.scroll({
      top: elem,
      left: 0,
      behavior: 'smooth'})
  } else {
    const element: any = (typeof elem === 'string') ? document.getElementById(elem) : elem;
    if (element) {
      setTimeout(() => {
        const positionY: number = parent? element.offsetTop: element.getBoundingClientRect().top + window.scrollY;
        const positionX: number = parent? element.offsetLeft: element.getBoundingClientRect().left + window.scrollX;
        const options: ScrollToOptions = (axis === 'x') ?
          {
            top: 0,
            left: positionX,
            behavior: 'smooth'
          } : {
            top: positionY,
            left: 0,
            behavior: 'smooth'
          };

        if (parent) {
          parent.scroll(options);
        } else {
          window.scroll(options);
        }
      }, 200);
    }
  }
}
