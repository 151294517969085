import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Accordion,
  AccordionItem,
  Container,
  CzechAndEnglishDataboxAccordions,
  Headline,
  Link,
  Paragraph,
  scrollToElement,
  UkraineAndRussianDataboxAccordions
} from '../gov-components';
import {
  ContactSection,
  ContainerChciDatovku,
  CzechAndEnglishDataboxModals,
  DataboxAccordions,
  InfoNumbersSection,
  UkraineAndRussianDataboxModals,
} from '../pages/index';
import * as Constants from '../util/constants';
import { DataboxOldFAQ } from './components/JsonLd';
import { BadgeInfoBoxUaRuVersion } from './components/BadgeInfoBox';

export const DataboxPageOld = () => {
  const [expandedNumber, setExpandedNumber] = useState<number | undefined>();
  const { location: { pathname } }: { location: { pathname: string } } = useHistory();
  const { t } = useTranslation();
  const hasDifferentContent: boolean = pathname.includes('ua') || pathname.includes('ru');
  const lang = localStorage.getItem('lang');
  const faq = DataboxOldFAQ(t);

  return (<>
    <Helmet>
      <html lang={lang ? lang : 'cs'} />
      <title lang={lang ? lang : 'cs'}>{t('databoxPage.seo.title')}</title>
      <link rel="canonical" hrefLang={`${lang}`} href={`https://chcidatovku.gov.cz/${lang}/${Constants.ROUTE_PATHS.databox}`} />
      <script type="application/ld+json">{faq}</script>
      <meta
        lang={lang ? lang : 'cs'}
        name="description"
        content={t('databoxPage.seo.description')} />
    </Helmet>
    <ContainerChciDatovku />
    <Container className={ 'gov-container--900px u-bg-color--white container__first-section' }>
      {hasDifferentContent ?
        <div className={ 'container__first-section--text-box' }>
          {t('main.change.splitCard')}
        </div> :
        <div className={ 'container__first-section--text-box' }>
          {t('main.splitCard')}
        </div>
      }
    </Container>
    <BadgeInfoBoxUaRuVersion />
    <Container className={ 'gov-container--900px u-bg-color--grey container__second-section' }>
      <Headline type={2} className={ 'heading2 container__second-section--databox-title' } id={ 'chci-datovku-id' }>{t(`databox.heading`)}</Headline>
      {hasDifferentContent ?
        <UkraineAndRussianDataboxModals /> :
        <CzechAndEnglishDataboxModals />
      }
    </Container>
    <section id={ 'jak-zalozit-datovku-id' }>
      <DataboxAccordions />
    </section>
    <section id={ 'caste-dotazy-id' }>
      <Container className={ 'gov-container--900px u-bg-color--grey container__fourth-section' }>
        <Headline type={2} className={ 'heading2' }>{t('curiosity.heading')}</Headline>
        <Paragraph className={ 'container__fourth-section--text' }>{t('curiosity.questions.heading')}</Paragraph>
        {hasDifferentContent ?
          <UkraineAndRussianDataboxAccordions expandedNumber={ expandedNumber } /> :
          <CzechAndEnglishDataboxAccordions expandedNumber={ expandedNumber } />
        }
      </Container>
    </section>
    {!hasDifferentContent &&
      <div className={ 'container-cock-background-big' }>
        <Container className={ 'gov-container--900px container__fifth-section' }>
          <Paragraph className={ 'container__fifth-section--text' }>{t('curiosity.myths.heading')}</Paragraph>
          <Accordion className={'gov-accordion--bordered'}>
            <AccordionItem title={t('curiosity.myths.accordion.first.heading')}
                           keyNumber={8}
                           className={ 'container__fourth-section--items' }>
              <Paragraph>{t('curiosity.myths.accordion.first.text.first')}</Paragraph>
            </AccordionItem>
            <AccordionItem title={t('curiosity.myths.accordion.second.heading')}
                           keyNumber={9}
                           className={ 'container__fourth-section--items' }>
              <Paragraph>{t('curiosity.myths.accordion.second.text.first')}</Paragraph>
              <Paragraph>{t('curiosity.myths.accordion.second.text.second')}</Paragraph>
              <Paragraph>{t('curiosity.myths.accordion.second.text.third')}&nbsp;
                <Link
                  onClick={() => { scrollToElement({ elem: 'gov-accordion-header-6' }); setExpandedNumber(6) }}
                  standalone={true}
                  size={ 'large' }
                  inversed={true}
                  className={ 'plain-list__link button-link' }
                >{t('curiosity.myths.accordion.second.text.fourth')}
                </Link>.
              </Paragraph>
            </AccordionItem>
            <AccordionItem title={t('curiosity.myths.accordion.third.heading')}
                           keyNumber={10}
                           className={ 'container__fourth-section--items' }>
              <Paragraph>{t('curiosity.myths.accordion.third.text.first')}</Paragraph>
              <Paragraph>{t('curiosity.myths.accordion.third.text.second')}</Paragraph>
            </AccordionItem>
          </Accordion>
        </Container>
        <div className={ 'container-cock-background-small' }>
          <InfoNumbersSection />
          <ContactSection />
        </div>
      </div>
    }
  </>)
}
