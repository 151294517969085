import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Headline, Link, Paragraph } from '../gov-components';

export const PageNotFound = () => {
  const { t } = useTranslation();

  return (<>
    <div className="gov-container gov-container--as-page gov-container--branded">

      <div className="gov-container__content">
        <Headline type={1} className="u-mb--25">{t('notFound.heading')}</Headline>
        <Paragraph>{t('notFound.text')}</Paragraph>

        <Link className="gov-button gov-button--primary-outlined" href="/">
          {t('notFound.button')}
        </Link>
      </div>
    </div>
  </>)
}
