import * as React from 'react';
import {
  Alert,
  Grid,
  GridTile,
  Headline,
  Link,
  Paragraph,
  PreviewTile
} from '../../gov-components';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

import retirement from '../../resources/icons/retirement.svg';
import cssz from '../../resources/icons/cssz.svg';
import personalDocument from '../../resources/icons/persondoc.svg';
import bag from '../../resources/icons/bag.svg';
import externalLink from '../../resources/icons/external.svg';

export const CzechAndEnglishDataboxModals = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid gridSize={3} className={ 'grid-czech-english' }>
        <GridTile modalTitle={t('databox.modal.title.first')} modalContent={
          <>
            <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
            <Paragraph className={ 'modal-text' }>{t('databox.modal.first.text.first')}</Paragraph>
            <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
            <Paragraph className={ 'modal-text' }>
              <Link
                title={t('databox.modal.titleOne')}
                href={ 'https://portaldopravy.cz/login' }
                target="_blank"
                className={ 'stepper-wrapper__text--link' }
              >{t('databoxNew.modal.textFourLink')}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp; {t('databoxNew.modal.TextFour')}&nbsp;
              <b>{t('databoxNew.modal.TextFive')}</b> {t('databoxNew.modal.TextSix')}&nbsp;
              <b>{t('databoxNew.modal.TextSeven')}</b> {t('databoxNew.modal.TextEight')}
            </Paragraph>
          </>
        }>
          <PreviewTile
            icon={<span className="gov-complex-icon gov-complex-icon--car" />}
            headline={t('databox.icon.first')}
            headlineText={t('more')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t('databox.modal.title.second')} modalContent={
          <>
            <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
            <Paragraph className={'modal-text'}>{t('databox.modal.second.text.first')}</Paragraph>
            <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
            <Paragraph className={ 'modal-text' }>{t('databox.modal.second.text.second')}&nbsp;
              <Link
                title={t('databox.modal.titleThree')}
                href={ 'https://adisspr.mfcr.cz/pmd/home' }
                target="_blank"
                className={ 'plain-list__link' }
              >{t('databox.modal.second.text.third')}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp; {t('databox.modal.second.text.fourth')}</Paragraph>
          </>
        }>
          <PreviewTile
            icon={<span className="gov-complex-icon gov-complex-icon--doc-taxes" />}
            headline={t('databox.icon.second')}
            headlineText={t('more')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t('databox.modal.title.third')} modalContent={
          <>
            <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
            <Paragraph className={'modal-text'}>{t('databox.modal.third.text.first')}</Paragraph>
            <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
            <Paragraph className={ 'modal-text' }>{t('databox.modal.third.text.second')}&nbsp;
              <Link
                title={t('databox.modal.titleOne')}
                href={ 'https://obcan.portal.gov.cz/prihlaseni' }
                target="_blank"
                className={ 'plain-list__link' }
              >{t('databox.modal.third.text.third')}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp; {t('databox.modal.third.text.fourth')}</Paragraph>
          </>
        }>
          <PreviewTile
            icon={<ReactSVG src={ personalDocument }
                            alt={t('databox.modal.altFive')} />}
            headline={t('databox.icon.third')}
            headlineText={t('more')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t('databox.modal.title.fourth')} modalContent={
          <>
            <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
            <Paragraph className={ 'modal-text' }>{t('databox.modal.fourth.text.first')}</Paragraph>
            <Headline type={3} className={'modal-heading'}>{t('databox.modal.second.heading')}</Headline>
            <Paragraph className={'modal-text u-mb--16'}>{t('databox.modal.fourth.text.second')}&nbsp;
              <Link
                title={t('databox.modal.titleFour')}
                href={ 'https://eportal.cssz.cz/web/portal/sluzby-pro-pojistence' }
                target="_blank"
                className={ 'plain-list__link' }
              >{t('databox.modal.fourth.text.third')}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>
            </Paragraph>
            <Alert
              inContent={true}
              globalClosable={false}
              className={ 'u-mb--16' }
            >
              <Paragraph className={ 'modal-text' }>{t('databox.modal.fourth.text.fourth')}&nbsp;
                <Link
                  title={t('databox.modal.titleFive')}
                  href={ 'https://eportal.cssz.cz/web/portal/-/sluzby/informativni-duchodova-aplikace' }
                  target="_blank"
                  className={ 'plain-list__link' }
                >{t('databox.modal.fourth.text.fifth')}
                  <img className={ 'plain-list__external-link' }
                       src={ externalLink }
                       alt={t("mainPage.imageLinkExternal")} />
                </Link>.
              </Paragraph>
            </Alert>
          </>
        }>
          <PreviewTile
            icon={<ReactSVG src={ retirement }
                            alt={t('databox.modal.altTwo')}
                            className={ 'icon-modal' } />}
            headline={t('databox.icon.fourth')}
            headlineText={t('more')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t('databox.modal.title.fifth')} modalContent={
          <>
            <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
            <Paragraph className={ 'modal-text' }>{t('databox.modal.fifth.text.first')}</Paragraph>
            <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
            <Paragraph className={ 'modal-text' }>{t('databox.modal.fifth.text.second')}</Paragraph>
          </>
        }>
          <PreviewTile
            icon={<span className="gov-complex-icon gov-complex-icon--id-card" />}
            headline={t('databox.icon.fifth')}
            headlineText={t('more')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t('databox.modal.title.sixth')} modalContent={
          <>
            <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
            <Paragraph className={ 'modal-text' }>{t('databox.modal.sixth.text.first')}</Paragraph>
            <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
            <Paragraph className={ 'modal-text' }>{t('databox.modal.sixth.text.second')}&nbsp;
              <Link
                title={t('databox.modal.titleSix')}
                href={ 'https://ozs.cuzk.cz/OzsWAEWEB/uvod/uvod.do' }
                target="_blank"
                className={ 'plain-list__link' }
              >{t('databox.modal.sixth.text.third')}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>.&nbsp;
              {t('databox.modal.sixth.text.fourth')}&nbsp;
              <Link
                title={t('databox.modal.titleSeven')}
                href={ 'https://chciidentitu.gov.cz/' }
                target="_blank"
                className={ 'plain-list__link' }
              >{t('databox.modal.sixth.text.fifth')}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>.
            </Paragraph>
          </>
        }>
          <PreviewTile
            icon={<span className="gov-complex-icon gov-complex-icon--map" />}
            headline={t('databox.icon.sixth')}
            headlineText={t('more')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t('databox.modal.title.seventh')} modalContent={
          <>
            <Headline type={3} className={'modal-heading'}>{t('databox.modal.first.heading')}</Headline>
            <Paragraph className={'modal-text'}>{t('databox.modal.sixth.text.first')}</Paragraph>
            <Headline type={3} className={'modal-heading'}>{t('databox.modal.second.heading')}</Headline>
            <Paragraph className={'modal-text'}>{t('databox.modal.sixth.text.second')}&nbsp;
              <Link
                title={t('databox.modal.titleEight')}
                href={ 'https://www.rzp.cz/registracni-formular/cs/' }
                target="_blank"
                className={ 'plain-list__link' }
              >{t('databox.modal.sixth.text.third')}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>.
            </Paragraph>
          </>
        }>
          <PreviewTile
            icon={<span className="gov-complex-icon gov-complex-icon--doc-stamp" />}
            headline={t('databox.icon.seventh')}
            headlineText={t('more')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t('databox.modal.title.eighth')} modalContent={
          <>
            <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
            <Paragraph className={ 'modal-text' }>{t('databox.modal.eighth.text.first')}</Paragraph>
            <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
            <Paragraph className={ 'modal-text' }>{t('databox.modal.eighth.text.second')}&nbsp;
              <Link
                title={t('databox.modal.titleNine')}
                href={ 'https://www.mpsv.cz/web/cz/-/zadost-o-rodicovsky-prispevek' }
                target="_blank"
                className={ 'plain-list__link' }
              >{t('databox.modal.eighth.text.third')}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>.
            </Paragraph>
          </>
        }>
          <PreviewTile
            icon={<span className="gov-complex-icon gov-complex-icon--newborn" />}
            headline={t('databox.icon.eighth')}
            headlineText={t('more')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t('databox.modal.title.ninth')} modalContent={
          <>
            <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
            <Paragraph className={ 'modal-text' }>{t('databox.modal.ninth.text.first')}</Paragraph>
            <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
            <Paragraph className={ 'modal-text u-mb--16' }>{t('databox.modal.ninth.text.second')}&nbsp;
              <Link
                title={t('databox.modal.titleOne')}
                href={ 'https://obcan.portal.gov.cz/prihlaseni' }
                target="_blank"
                className={ 'plain-list__link' }
              >{t('databox.modal.ninth.text.third')}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp; {t('databox.modal.ninth.text.fourth')}</Paragraph>
            <Alert inContent={true}
                   globalClosable={false}
                   className={ 'u-mb--16' }
            >
              <Paragraph>{t('databox.modal.ninth.text.fifth')}</Paragraph>
            </Alert>
          </>
        }>
          <PreviewTile
            icon={<span className="gov-complex-icon gov-complex-icon--info" />}
            headline={t('databox.icon.ninth')}
            headlineText={t('more')}
          >
          </PreviewTile>
        </GridTile>
      </Grid>
    </>
  )
}

export const UkraineAndRussianDataboxModals = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid gridSize={3} className={ 'grid-ukrainian-russian' }>
        <GridTile
          buttonLink={'https://www.mpsv.cz/cas/login'}
          modalButtonName={t('databox.modalButton')}
          modalTitle={t('databox.change.modal.title.first')}
          modalContent={
            <>
              <Paragraph className={'modal-text'}>{t('databox.change.modal.first.text.first')}</Paragraph>
              <Paragraph className={'modal-text'}>{t('databox.change.modal.first.text.second')}&nbsp;
                <Link
                  title={t('databox.modal.titleTen')}
                  href={ 'https://www.mpsv.cz/web/cz/formulare' }
                  target="_blank"
                  className={ 'plain-list__link' }
                >{t('databox.change.modal.first.text.third')}
                  <img className={ 'plain-list__external-link' }
                       src={ externalLink }
                       alt={t("mainPage.imageLinkExternal")} />
                </Link>.&nbsp; {t('databox.change.modal.first.text.fourth')}&nbsp;
                <Link
                  title={t('databox.modal.titleEleven')}
                  href={ 'https://www.uradprace.cz/web/cz/-/pomoc-pro-obcany-ukrajiny-a-jejich-zamestnavatele' }
                  target="_blank"
                  className={ 'plain-list__link' }
                >{t('databox.change.modal.first.text.fifth')}
                  <img className={ 'plain-list__external-link' }
                       src={ externalLink }
                       alt={t("mainPage.imageLinkExternal")} />
                </Link>.
              </Paragraph>
            </>
          }>
          <PreviewTile
            icon={<ReactSVG src={ bag }
                            alt={t('databox.modal.altThree')}
                            className={ 'icon-modal' }/>}
            headline={t('databox.change.modal.title.first')}
            headlineText={t('more')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile
          buttonLink={'https://www.rzp.cz/registracni-formular/cs/'}
          modalButtonName={t('databox.modalButton')}
          modalTitle={t('databox.change.modal.title.second')}
          modalContent={
            <>
              <Paragraph className={ 'modal-text' }>{t('databox.change.modal.second.text.first')}</Paragraph>
            </>
          }>
          <PreviewTile
            icon={<span className="gov-complex-icon gov-complex-icon--doc-stamp" />}
            headline={t('databox.change.modal.title.second')}
            headlineText={t('more')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile
          buttonLink={'https://eportal.cssz.cz/'}
          modalButtonName={t('databox.modalButton')}
          modalTitle={t('databox.change.modal.title.third')}
          modalContent={
            <>
              <Paragraph className={ 'modal-text' }>{t('databox.change.modal.third.text.first')}</Paragraph>
              <Paragraph className={ 'modal-text' }>
                <Link
                  title={t('databox.modal.titleTwelve')}
                  href={ 'https://www.cssz.cz/web/cz/-/dopomoga-gromadanam-ukraini-ta-ihnim-rodinam' }
                  target="_blank"
                  className={ 'plain-list__link' }
                >{t('databox.change.modal.third.text.second')}
                  <img className={ 'plain-list__external-link' }
                       src={ externalLink }
                       alt={t("mainPage.imageLinkExternal")} />
                </Link>&nbsp; {t('databox.change.modal.third.text.third')}
              </Paragraph>
            </>
          }>
          <PreviewTile
            icon={<ReactSVG src={ cssz }
                            alt={t('databox.modal.altFour')}
                            className={ 'icon-modal' } />}
            headline={t('databox.change.modal.title.third')}
            headlineText={t('more')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile
          buttonLink={'https://adisspr.mfcr.cz/pmd/home'}
          modalButtonName={t('databox.modalButton')}
          modalTitle={t('databox.change.modal.title.fourth')}
          modalContent={
            <>
              <Paragraph className={ 'modal-text' }>{t('databox.change.modal.fourth.text.first')}</Paragraph>
              <Paragraph className={ 'modal-text' }>{t('databox.change.modal.fourth.text.second')}</Paragraph>
              <Paragraph>{t('databox.change.modal.fourth.text.third')}&nbsp;
                <Link
                  title={t('databox.modal.titleThirteen')}
                  href={ 'https://www.financnisprava.cz/cs/dane/informace-valka-na-ukrajine/zamestnavani-obcanu-ukrajiny' }
                  target="_blank"
                  className={ 'plain-list__link' }
                >{t('databox.change.modal.fourth.text.fourth')}
                  <img className={ 'plain-list__external-link' }
                       src={ externalLink }
                       alt={t("mainPage.imageLinkExternal")} />
                </Link>.
              </Paragraph>
            </>
          }>
          <PreviewTile
            icon={<span className="gov-complex-icon gov-complex-icon--doc-taxes" />}
            headline={t('databox.change.modal.title.fourth')}
            headlineText={t('more')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile
          buttonLink={'https://obcan.portal.gov.cz/'}
          modalButtonName={t('databox.modalButton')}
          modalTitle={t('databox.change.modal.title.fifth')}
          modalContent={
            <>
              <Paragraph className={ 'modal-text' }>{t('databox.change.modal.fifth.text.first')}</Paragraph>
              <Paragraph className={ 'modal-text' }>
                {t('databox.change.modal.fifth.text.second')}&nbsp;
                {t('databox.change.modal.fifth.text.third')}&nbsp;
                {t('databox.change.modal.fifth.text.fourth')}.</Paragraph>
            </>
          }>
          <PreviewTile
            icon={<ReactSVG src={ personalDocument }
                            alt={t('databox.modal.altFive')} />}
            headline={t('databox.change.modal.title.fifth')}
            headlineText={t('more')}
          >
          </PreviewTile>
        </GridTile>
      </Grid>
    </>
  )
}
