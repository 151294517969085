import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Container } from '../../gov-components';

import * as Constants from '../../util/constants';
import { withLocale } from '../../util/routing';

export const CardsSection = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const navigateTo = (route: string) => {
    history.push(route);
  }

  const pathDataboxSetUpWithLocale = withLocale(Constants.ROUTE_PATHS.databoxSetUp);
  const pathDataboxWithLocale = withLocale(Constants.ROUTE_PATHS.databox);
  const pathIdentitySetUpWithLocale = withLocale(Constants.ROUTE_PATHS.identitySetUp);
  const pathIdentityWithLocale = withLocale(Constants.ROUTE_PATHS.identity);

  return (
    <section id={ 'informacni-karty' } className={ 'card-section' }>
      <Container className={ 'gov-container--900px u-bg-color--white gov-cards-section pt-56' }>
        <div className='gov-cards-section__cards-holder'>
          <div className='gov-cards-section__cards-holder--inner'>
            <Card version={ 'databox' }
                  title={t('cardSectionPage.titleOne')}
                  titleBlue={t('cardSectionPage.titleBlueOne')}
                  onButtonLinkFirstClick={ () => navigateTo(pathDataboxSetUpWithLocale) }
                  buttonLinkFirst={pathDataboxSetUpWithLocale}
                  buttonTextFirst={t('cardSectionPage.buttonOne')}
                  onButtonLinkSecondClick={ () => navigateTo(pathDataboxWithLocale) }
                  buttonLinkSecond={pathDataboxWithLocale}
                  buttonTextSecond={t('cardSectionPage.buttonTwo')}
            >{t('cardSectionPage.textOnePartOne')}&nbsp;
            <b>{t('cardSectionPage.textOnePartTwo')}</b>&nbsp;
              {t('cardSectionPage.textOnePartThree')}&nbsp;
              <b>{t('cardSectionPage.textOnePartFour')}</b>&nbsp;
            </Card>
            <Card version={ 'identity' }
                  title={t('cardSectionPage.titleTwo')}
                  titleBlue={t('cardSectionPage.titleBlueTwo')}
                  onButtonLinkFirstClick={ () => navigateTo(pathIdentitySetUpWithLocale) }
                  buttonLinkFirst={pathIdentitySetUpWithLocale}
                  buttonTextFirst={t('cardSectionPage.buttonOne')}
                  onButtonLinkSecondClick={ () => navigateTo(pathIdentityWithLocale) }
                  buttonLinkSecond={pathIdentityWithLocale}
                  buttonTextSecond={t('cardSectionPage.buttonTwo')}
            >{t('cardSectionPage.textTwoPartOne')}&nbsp;
            <b>{t('cardSectionPage.textTwoPartTwo')}</b>&nbsp;
              {t('cardSectionPage.textTwoPartThree')}</Card>
          </div>
        </div>
      </Container>
    </section>
  )
}
