/**
 * Headline Component
 */

import * as React from 'react';
import { ReactNode } from 'react';
import clsx from 'clsx';

export interface HeadlineProps {
  /** If true, text is bold */
  bold?: boolean;
  /** Custom class applied to root element */
  className?: string;
  /** Custom class applied to root element */
  id?: string;
  /** Color of text */
  color?: 'grey-light';
  /** Represents text or another component */
  children: ReactNode;
  /** Type of headline (h1,...h5) */
  type: 1 | 2 | 3 | 4 | 5;
  /** Passing all other props to component */
  props?: any;
}

export const Headline = ({
  bold = false,
  color,
  className,
  id,
  children,
  type = 1,
  ...props
}: HeadlineProps) => {
  const Comp: any = `h${!Number.isInteger(type) || type < 1 || type > 5 ? 1 : type}`;

  const finalClasses = clsx(
    { 'u-font-weight--bold': bold },
    { [`u-color--${color}`]: color },
    className
  );

  return (
    <Comp className={finalClasses} id={id} {...props}>
      {children}
    </Comp>
  );
};
