import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Alert,
  Card,
  Container,
  Headline,
  HomePageHeader,
  Link,
  Paragraph,
  scrollToElement,
  Tabs,
  TabsItem
} from '../gov-components';
import { BadgeDataboxTabs } from './components/BadgeTabs';
import { BadgeInfoBox } from './components/BadgeInfoBox';
import { DataboxAccordionsNew } from './components/DataboxAccordionsNew';
import {
  DataboxAccordionsQANewAutomaticSetup,
  DataboxAccordionsQANewCommunication,
  DataboxAccordionsQANewGeneral
} from './components/DataboxAccordionsQANew';
import { DataboxNewFAQ } from './components/JsonLd';
import { InfotileTabs } from './components/InfotileTabs';

import * as Constants from '../util/constants';
import { withLocale } from '../util/routing';
import { TabInfoText } from './components/TabInfoText';

import databoxImage from '../resources/images/databox-page-image.png';

export const DataboxPageNew = () => {
  const location = useLocation();
  const [expandedNumber] = useState<number | undefined>();
  const [activeDataboxTab, setActiveDataboxTab] = useState(0);
  const [faqActiveTab, setFaqActiveTab] = useState(0);
  const [databoxAccordionExpandedId, setDataboxAccordion] = useState<number | undefined>(undefined);
  const { location: { pathname } }: { location: { pathname: string } } = useHistory();
  const { t } = useTranslation();
  const lang = localStorage.getItem('lang');
  const faq = DataboxNewFAQ(t);
  const isEnglishContent: boolean = pathname.includes('en');

  const history = useHistory();
  const navigateTo = (route: string) => {
    history.push(route);
  }

  React.useEffect( () => {
    if (location.pathname.endsWith( Constants.ROUTE_PATHS.databox) && location.hash === `#${Constants.ROUTE_HASH_IDS.newDatabox}`) {
      scrollToElement({elem: Constants.ROUTE_HASH_IDS.newDatabox });
      setDataboxAccordion(1);
    }
  }, [location])

  const pathDataboxSetUpWithLocale = withLocale(Constants.ROUTE_PATHS.databoxSetUp);
  const pathIdentitySetUpWithLocale = withLocale(Constants.ROUTE_PATHS.identitySetUp);
  const pathIdentityWithLocale = withLocale(Constants.ROUTE_PATHS.identity);

  return (
    <>
    <Helmet>
      <html lang={lang ? lang : 'cs'} />
      <title lang={lang ? lang : 'cs'}>{t('databoxPage.seo.title')}</title>
      <link rel="canonical" hrefLang={`${lang}`} href={`https://chcidatovku.gov.cz/${lang}/${Constants.ROUTE_PATHS.databox}`} />
      <link rel="canonical" hrefLang={`${lang}`} href={`https://chcidatovku.gov.cz/${lang}/${Constants.ROUTE_PATHS.databoxSetUp}`} />
      <script type="application/ld+json">{faq}</script>
      <meta
        lang={lang ? lang : 'cs'}
        name="description"
        content={t('databoxPage.seo.description')} />
    </Helmet>
    <HomePageHeader
      version={ 'databox' }
      title={t('databoxPage.header.titleOne')}
      subtitle={t('databoxPage.header.subtitleOne')}
      buttonLinkDatabox={ pathDataboxSetUpWithLocale }
      onButtonLinkDataBoxClick={ () => navigateTo(pathDataboxSetUpWithLocale) }
      onButtonLinkIdentityClick={ () => {}}
      buttonTextDatabox={t('homePage.header.buttonOne')}
    />
    <InfotileTabs />
    <BadgeInfoBox switchDataboxTab={(tab) => setActiveDataboxTab(tab)} switchFaqTab={(tab) => setFaqActiveTab(tab)} />
    {isEnglishContent && <TabInfoText/>}
    <BadgeDataboxTabs activeTab={activeDataboxTab} onActiveTabChanged={(activeTab) => setActiveDataboxTab(activeTab)} />
    <section id={ 'jak-zalozit-datovku' }>
      <Container className={ 'gov-container--1200px create-databox-wrapper u-bg-color--white  pt-56 pb-56' }>
        <Headline type={1} className={ 'heading2 plain-list__main-title' }>
          <span id={ Constants.ROUTE_HASH_IDS.newDatabox } >{t("databoxPage.accordionsHeadingOne")}</span>
        </Headline>
        <DataboxAccordionsNew expandedId={databoxAccordionExpandedId} onExpanded={(id: number | undefined) => {setDataboxAccordion(id)}}/>
        <div className={ 'create-databox-wrapper__page-image' }>
          <img src={ databoxImage }
               alt={t("infotilePage.tabItem.imageAltEight")} />
        </div>
      </Container>
    </section>
    <section id={ 'caste-dotazy-datovka' }>
      <Container className={ 'gov-container--800px u-bg-color--grey container__fourth-section' }>
        <Headline id="casto-se-ptate" type={2} className={ 'heading2 faq-title' }>{t('databoxPage.accordionsQAHeadingOne')}</Headline>
        <Tabs version={ 'badge-long' } activeTab={faqActiveTab} onActiveTabChanged={(tab) => setFaqActiveTab(tab)}>
          <TabsItem
            subtitle={t('databoxPage.badgeTabs.subtitleFAQOne')}
          >
            <DataboxAccordionsQANewGeneral expandedNumber={ expandedNumber } />
          </TabsItem>
          <TabsItem
            subtitle={t('databoxPage.badgeTabs.subtitleFAQTwo')}
            id={ 'FAQ-automaticke-zalozeni' }
          >
            <DataboxAccordionsQANewAutomaticSetup expandedNumber={ expandedNumber } switchDataboxTab={(activeTab) => setActiveDataboxTab(activeTab)} />
          </TabsItem>
          <TabsItem
            subtitle={t('databoxPage.badgeTabs.subtitleFAQThree')}
          >
            <DataboxAccordionsQANewCommunication expandedNumber={ expandedNumber } />
          </TabsItem>
        </Tabs>
      </Container>
    </section>
    <section id={ 'karta-datovka' } className={ 'page-card' }>
      <Container className={ 'gov-container--900px u-bg-color--grey' }>
        <Headline type={2} className={ 'heading2' }>{t('identityPage.cardIdentityHeading')}</Headline>
        <Card version={ 'identity' }
                  title={t('cardSectionPage.titleTwo')}
                  titleBlue={t('cardSectionPage.titleBlueTwo')}
                  onButtonLinkFirstClick={ () => navigateTo(pathIdentitySetUpWithLocale) }
                  buttonLinkFirst={pathIdentitySetUpWithLocale}
                  buttonTextFirst={t('cardSectionPage.buttonOne')}
                  onButtonLinkSecondClick={ () => navigateTo(pathIdentityWithLocale) }
                  buttonLinkSecond={pathIdentityWithLocale}
                  buttonTextSecond={t('cardSectionPage.buttonTwo')}
            >{t('cardSectionPage.textTwoPartOne')}&nbsp;
            <b>{t('cardSectionPage.textTwoPartTwo')}</b>&nbsp;
              {t('cardSectionPage.textTwoPartThree')}</Card>
      </Container>
    </section>
  </>)
}
