import * as React from 'react';
import { useState } from 'react';
import { Button } from './Button';
import { Headline } from './Headline';
import arrowDown from '../resources/icons/arrow-down-small.svg';
import arrowUp from '../resources/icons/arrow-up.svg';
import { t } from 'i18next';

export interface ShowMoreBoxProps {
  id?: string;
  className?: string;
  heading: string;
  children: React.ReactNode;
  showMoreLabel: string;
  showMoreContent: React.ReactNode;
}

export const ShowMoreBox = ({
  className,
  heading,
  children,
  showMoreLabel,
  showMoreContent,
  ...props
}: ShowMoreBoxProps) => {
  const [showMore, switchShowMore] = useState(false);

  return (
    <div className={`show-more-box__inner ${className}`} {...props}>
      <Headline type={2} className="show-more-box__heading">
        {heading}
      </Headline>
      {children}
      <Button
        color="tertiary"
        size="small"
        className="show-more-box__show-button"
        onClick={() => {
          switchShowMore(!showMore);
        }}
      >
        <Headline type={4}>{showMoreLabel}</Headline>
        <img
          src={showMore ? arrowUp : arrowDown}
          alt={showMore ? t("mainPage.iconAltOne") : t("mainPage.iconAltTwo")}
          className="u-ml--10"
        />
      </Button>

      {showMore && (
        <div className="show-more-box__showable-content">{showMoreContent}</div>
      )}
    </div>
  );
};
