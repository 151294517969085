import * as React from 'react';
import {
  Alert,
  Grid,
  GridTile,
  Headline,
  Link,
  Paragraph,
  PreviewTile
} from '../../gov-components';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

import coins from '../../resources/icons/coins.svg';
import cssz from '../../resources/icons/cssz.svg';
import externalLink from '../../resources/icons/external.svg';
import chatBubble from '../../resources/icons/chat-bubble.svg';
import personalDocument from '../../resources/icons/persondoc.svg';
import pointSystem from '../../resources/icons/point-system.svg';
import portalInsuranceCompanies from '../../resources/icons/portal-insurance-companies.svg';
import retirement from '../../resources/icons/retirement.svg';
import scale from '../../resources/icons/scale.svg';

export const DataboxCitizenModalsNew = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid gridSize={3} className={ 'grid-databox' }>
        <GridTile modalTitle={t('databoxNew.modal.titleOne')}
                  modalButtonName={t('databoxNew.modal.buttonOne')}
                  buttonLink={ 'https://obcan.portal.gov.cz/prihlaseni' }
                  modalContent={
                    <>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
                      <Paragraph className={'modal-text'}>{t('databox.modal.third.text.first')}</Paragraph>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>
                        <Link
                          title={t('databox.modal.titleOne')}
                          href={ 'https://obcan.portal.gov.cz/prihlaseni' }
                          target="_blank"
                          className={ 'stepper-wrapper__text--link' }
                        >{t('databoxNew.modal.textLinkOne')}
                          <img className={ 'plain-list__external-link' }
                               src={ externalLink }
                               alt={t("mainPage.imageLinkExternal")} />
                        </Link>&nbsp; {t('databoxNew.modal.textOne')}&nbsp;
                        <b>{t('databoxNew.modal.textTwo')}</b> {t('databoxNew.modal.textThree')}
                      </Paragraph>
                    </>
                  }>
          <PreviewTile
            icon={<ReactSVG src={ personalDocument }
                            alt={t('databox.modal.altFive')} />}
            headline={t('databoxNew.modal.titleOne')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t('databoxNew.modal.titleTwo')}
                  modalButtonName={t('databoxNew.modal.buttonTwo')}
                  buttonLink={ 'https://adisspr.mfcr.cz/pmd/home' }
                  modalContent={
                    <>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
                      <Paragraph className={'modal-text'}>{t('databox.modal.second.text.first')}</Paragraph>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>{t('databox.modal.second.text.second')}&nbsp;
                        <Link
                          title={t('databox.modal.titleThree')}
                          href={ 'https://adisspr.mfcr.cz/pmd/home' }
                          target="_blank"
                          className={ 'stepper-wrapper__text--link' }
                        >{t('databox.modal.second.text.third')}
                          <img className={ 'plain-list__external-link' }
                               src={ externalLink }
                               alt={t("mainPage.imageLinkExternal")} />
                        </Link>&nbsp; {t('databox.modal.second.text.fourth')}</Paragraph>
                    </>
                  }>
          <PreviewTile
            icon={<span className="gov-complex-icon gov-complex-icon--doc-taxes" />}
            headline={t('databoxNew.modal.titleTwo')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t('databox.icon.fifth')}
                  noButton={true}
                  modalContent={
                    <>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>{t('databox.modal.fifth.text.first')}</Paragraph>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>{t('databoxNew.modal.fifth.textSecond')}</Paragraph>
                    </>
                  }>
          <PreviewTile
            icon={<span className="gov-complex-icon gov-complex-icon--id-card" />}
            headline={t('databox.icon.fifth')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t('databox.icon.first')}
                  modalButtonName={t('databoxNew.modal.buttonThree')}
                  buttonLink={ 'https://portaldopravy.cz/login' }
                  modalContent={
                    <>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>{t('databox.modal.first.text.first')}</Paragraph>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>
                        <Link
                          title={t('databox.modal.titleOne')}
                          href={ 'https://portaldopravy.cz/login' }
                          target="_blank"
                          className={ 'stepper-wrapper__text--link' }
                        >{t('databoxNew.modal.textFourLink')}
                          <img className={ 'plain-list__external-link' }
                               src={ externalLink }
                               alt={t("mainPage.imageLinkExternal")} />
                        </Link>&nbsp; {t('databoxNew.modal.TextFour')}&nbsp;
                        <b>{t('databoxNew.modal.TextFive')}</b> {t('databoxNew.modal.TextSix')}&nbsp;
                        <b>{t('databoxNew.modal.TextSeven')}</b> {t('databoxNew.modal.TextEight')}
                      </Paragraph>
                    </>
                  }>
          <PreviewTile
            icon={<span className="gov-complex-icon gov-complex-icon--car" />}
            headline={t('databox.icon.first')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t('databox.icon.ninth')}
                  modalButtonName={t('databoxNew.modal.buttonFour')}
                  buttonLink={ 'https://portal.gov.cz/formulare/vypis-z-bodoveho-konta-ridice/online' }
                  modalContent={
                    <>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>{t('databoxNew.modal.textSixth')}</Paragraph>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
                      <Paragraph className={ 'modal-text u-mb--16' }>
                        <Link
                          title={t('databox.modal.titleOne')}
                          href={ 'https://obcan.portal.gov.cz/prihlaseni' }
                          target="_blank"
                          className={ 'stepper-wrapper__text--link' }
                        >{t('databoxNew.modal.textSixthLink')}
                          <img className={ 'plain-list__external-link' }
                               src={ externalLink }
                               alt={t("mainPage.imageLinkExternal")} />
                        </Link>&nbsp; {t('databoxNew.modal.textSixthPartOne')}&nbsp;
                        <b>{t('databoxNew.modal.textSixthPartTwo')}</b> {t('databoxNew.modal.textSixthPartThree')}
                      </Paragraph>
                    </>
                  }>
          <PreviewTile
            icon={<ReactSVG src={ pointSystem }
                            alt={t('databoxNew.modal.altNinth')}
                            className={ 'icon-modal' } />}
            headline={t('databox.icon.ninth')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t('databox.modal.title.fourth')}
                  modalButtonName={t('databoxNew.modal.buttonFive')}
                  buttonLink={ 'https://eportal.cssz.cz/web/portal/sluzby-pro-pojistence' }
                  modalContent={
                    <>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>
                        {t('databoxNew.modal.textFourthFirst')}&nbsp;
                        <Link
                          title={t('databoxNew.modal.titleFive')}
                          href={ 'https://eportal.cssz.cz/web/portal/-/sluzby/informativni-duchodova-aplikace' }
                          target="_blank"
                          className={ 'stepper-wrapper__text--link' }
                        >{t('databoxNew.modal.textFourthSecondLink')}
                          <img className={ 'plain-list__external-link' }
                               src={ externalLink }
                               alt={t("mainPage.imageLinkExternal")} />
                        </Link>
                      </Paragraph>
                      <Headline type={3} className={'modal-heading'}>{t('databox.modal.second.heading')}</Headline>
                      <Paragraph className={'modal-text u-mb--16'}>{t('databox.modal.fourth.text.second')}&nbsp;
                        <Link
                          title={t('databox.modal.titleFour')}
                          href={ 'https://eportal.cssz.cz/web/portal/sluzby-pro-pojistence' }
                          target="_blank"
                          className={ 'stepper-wrapper__text--link' }
                        >{t('databox.modal.fourth.text.third')}
                          <img className={ 'plain-list__external-link' }
                               src={ externalLink }
                               alt={t("mainPage.imageLinkExternal")} />
                        </Link>
                      </Paragraph>
                    </>
                  }>
          <PreviewTile
            icon={<ReactSVG src={ retirement }
                            alt={t('databox.modal.altTwo')}
                            className={ 'icon-modal' } />}
            headline={t('databox.icon.fourth')}
          >
          </PreviewTile>
        </GridTile>
      </Grid>
    </>
  )
}

export const DataboxNonEntrepreneurialModalsNew = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid gridSize={3} className={ 'grid-databox' }>
        <GridTile modalTitle={t('databoxNewModal.titleOne')}
                  noButton={true}
                  modalContent={
                    <>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>{t('databoxNewModal.textOnePartOne')}</Paragraph>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>{t('databoxNewModal.textOnePartTwo')}</Paragraph>
                    </>
                  }>
          <PreviewTile
            icon={<ReactSVG src={ chatBubble }
                            alt={t('databoxNew.modal.altSix')} />}
            headline={t('databoxNewModal.titleOne')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t('databoxNewModal.titleTwo')}
                  modalButtonName={t('databoxNewModal.buttonTwo')}
                  buttonLink={ 'https://adisspr.mfcr.cz/pmd/home' }
                  modalContent={
                    <>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
                      <Paragraph className={'modal-text'}>{t('databoxNewModal.textTwoPartOne')}</Paragraph>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>
                        {t('databoxNewModal.textTwoPartTwo')}&nbsp;
                          <Link
                            title={t('databoxNewModal.textTwoPartThreeLinkTitle')}
                            href={ 'https://adisspr.mfcr.cz/pmd/home' }
                            target="_blank"
                            className={ 'stepper-wrapper__text--link' }
                          >{t('databoxNewModal.textTwoPartThreeLink')}
                            <img className={ 'plain-list__external-link' }
                                 src={ externalLink }
                                 alt={t("mainPage.imageLinkExternal")} />
                          </Link>&nbsp;
                        {t('databoxNewModal.textTwoPartFour')}
                      </Paragraph>
                    </>
                  }>
          <PreviewTile
            icon={<span className="gov-complex-icon gov-complex-icon--doc-taxes" />}
            headline={t('databoxNewModal.titleTwo')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t('databoxNewModal.titleThree')}
                  noButton={true}
                  modalContent={
                    <>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>{t('databoxNewModal.textThreePartOne')}</Paragraph>
                      <Paragraph className={ 'modal-text' }>{t('databoxNewModal.textThreePartTwo')}</Paragraph>
                      <Alert inContent={true}
                             globalClosable={false}
                             className={ 'databox-alert-wrapper' }
                      >
                        <Paragraph className={ 'gov-alert__content--bigger-text' }>
                          {t('databoxNewModal.textThreePartThreeAlert')}&nbsp;
                          <b>{t('databoxNewModal.textThreePartFourAlert')}</b>
                        </Paragraph>
                      </Alert>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>
                        {t('databoxNewModal.textThreePartFive')}
                      </Paragraph>
                    </>
                  }>
          <PreviewTile
            icon={<ReactSVG src={ coins }
                            alt={t('databoxNewsModal.altOne')} />}
            headline={t('databoxNewModal.titleThree')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t('databoxNewModal.titleFour')}
                  modalButtonName={t('databoxNewModal.buttonFour')}
                  buttonLink={ 'https://nahlizenidokn.cuzk.cz/' }
                  modalContent={
                    <>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>{t('databoxNewModal.textFourPartOne')}</Paragraph>
                      <Paragraph className={ 'modal-text' }>{t('databoxNewModal.textFourPartTwo')}</Paragraph>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>
                        {t('databoxNewModal.textFourPartThree')}&nbsp;
                          <Link
                            title={t('databoxNewModal.textFourPartFourLinkTitle')}
                            href={ 'https://ozs.cuzk.cz/OzsWAEWEB/uvod/uvod.do' }
                            target="_blank"
                            className={ 'stepper-wrapper__text--link' }
                          >{t('databoxNewModal.textFourPartFourLink')}
                            <img className={ 'plain-list__external-link' }
                                 src={ externalLink }
                                 alt={t("mainPage.imageLinkExternal")} />
                          </Link>&nbsp;
                        {t('databoxNewModal.textFourPartFive')}&nbsp;
                          <Link
                            title={t('databoxNewModal.textFourPartSixLinkTitle')}
                            href={ 'https://www.cuzk.cz/Katastr-nemovitosti/Poskytovani-udaju-z-KN/Sledovani-zmen/Sluzba-sledovani-zmen-v-katastru-nemovitosti.aspx' }
                            target="_blank"
                            className={ 'stepper-wrapper__text--link' }
                          >{t('databoxNewModal.textFourPartSixLink')}
                            <img className={ 'plain-list__external-link' }
                                 src={ externalLink }
                                 alt={t("mainPage.imageLinkExternal")} />
                          </Link>
                      </Paragraph>
                    </>
                  }>
          <PreviewTile
            icon={<span className="gov-complex-icon gov-complex-icon--map" />}
            headline={t('databoxNewModal.titleFour')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t("databoxNewModal.titleFive")}
                  noButton={true}
                  modalContent={
                    <>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>{t('databoxNewModal.textFivePartOne')}</Paragraph>
                      <Paragraph className={ 'modal-text' }>{t('databoxNewModal.textFivePartTwo')}</Paragraph>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>{t('databoxNewModal.textFivePartThree')}</Paragraph>
                    </>
                  }>
          <PreviewTile
            icon={<ReactSVG src={ scale }
                            alt={t("databoxNewModal.altTwo")} />}
            headline={t("databoxNewModal.titleFive")}
          >
          </PreviewTile>
        </GridTile>
      </Grid>
    </>
  )
}

export const DataboxBusinessModalsNew = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid gridSize={3} className={ 'grid-databox' }>
        <GridTile modalTitle={t('databoxNew.modal.titleTwo')}
                  modalButtonName={t('databoxNew.modal.buttonTwo')}
                  buttonLink={ 'https://adisspr.mfcr.cz/pmd/home' }
                  modalContent={
                    <>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
                      <Paragraph className={'modal-text'}>{t('databox.modal.second.text.first')}</Paragraph>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>{t('databox.modal.second.text.second')}&nbsp;
                        <Link
                          title={t('databox.modal.titleThree')}
                          href={ 'https://adisspr.mfcr.cz/pmd/home' }
                          target="_blank"
                          className={ 'stepper-wrapper__text--link' }
                        >{t('databox.modal.second.text.third')}
                          <img className={ 'plain-list__external-link' }
                               src={ externalLink }
                               alt={t("mainPage.imageLinkExternal")} />
                        </Link>&nbsp; {t('databox.modal.second.text.fourth')}</Paragraph>
                    </>
                  }>
          <PreviewTile
            icon={<span className="gov-complex-icon gov-complex-icon--doc-taxes" />}
            headline={t('databoxNew.modal.titleTwo')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t('databoxNew.modal.titleSix')}
                  modalButtonName={t('databoxNew.modal.buttonOnePartOne')}
                  buttonLink={ 'https://portal.gov.cz/kam-dal/rejstriky-seznamy-formulare/formulare-k-vypisum' }
                  modalContent={
                    <>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
                      <Paragraph className={'modal-text'}>{t('databox.modal.third.text.first')}</Paragraph>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>
                        {t('databoxNew.modal.textTen')}
                      </Paragraph>
                    </>
                  }>
          <PreviewTile
            icon={<ReactSVG src={ personalDocument }
                            alt={t('databox.modal.altFive')} />}
            headline={t('databoxNew.modal.titleSix')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t('databoxNew.modal.titleSeven')}
                  noButton={true}
                  modalContent={
                    <>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>{t('databoxNew.modal.textFifthFirst')}</Paragraph>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>{t('databoxNew.modal.textFifthSecond')}</Paragraph>
                    </>
                  }>
          <PreviewTile
            icon={<ReactSVG src={ chatBubble }
                            alt={t('databoxNew.modal.altSix')} />}
            headline={t('databoxNew.modal.titleSeven')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t('databoxNew.modal.titleEight')}
                  modalButtonName={t('databoxNew.modal.buttonSeven')}
                  buttonLink={ 'https://eportal.cssz.cz/web/portal/sluzby-pro-pojistence' }
                  modalContent={
                    <>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>{t('databoxNew.modal.firstTextFirst')}</Paragraph>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>{t('databoxNew.modal.firstTextSecond')}</Paragraph>
                    </>
                  }>
          <PreviewTile
            icon={<ReactSVG src={ cssz }
                            alt={t('databoxNew.modal.altSeven')} />}
            headline={t('databoxNew.modal.titleEight')}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t("databoxNew.modal.titleNine")}
                  noButton={true}
                  modalContent={
                    <>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.first.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>{t('databoxNew.modal.secondTextFirst')}</Paragraph>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>{t('databoxNew.modal.secondTextSecond')}</Paragraph>
                    </>
                  }>
          <PreviewTile
            icon={<ReactSVG src={ portalInsuranceCompanies }
                            alt={t("mainPage.sectionOne.modalSeven.altText")} />}
            headline={t("databoxNew.modal.titleNine")}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t('databoxNew.modal.titleTen')}
                  modalButtonName={t('databoxNew.modal.buttonEight')}
                  buttonLink={ 'https://www.rzp.cz/registracni-formular/cs/' }
                  modalContent={
                    <>
                      <Headline type={3} className={'modal-heading'}>{t('databox.modal.first.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>{t('databoxNew.modal.sixthTextFirst')}</Paragraph>
                      <Headline type={3} className={ 'modal-heading' }>{t('databox.modal.second.heading')}</Headline>
                      <Paragraph className={ 'modal-text' }>{t('databoxNew.modal.sixthTextSecond')}</Paragraph>
                    </>
                  }>
          <PreviewTile
            icon={<span className="gov-complex-icon gov-complex-icon--doc-stamp" />}
            headline={t('databoxNew.modal.titleTen')}
          >
          </PreviewTile>
        </GridTile>
      </Grid>
    </>
  )
}

