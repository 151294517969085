import * as React from 'react';

interface ContainerProps {
  className?: string;
  children?: any;
  tag?: 'header' | 'footer' | 'div';
}

export const Container = (props: ContainerProps) => {
  const { className, children, tag } = props;
  let finalClass: string = 'gov-container';

  finalClass = className?
    `${finalClass} ${className}` :
    finalClass;

  switch (tag) {
    case 'footer' : {
      return (<footer className={finalClass}>
        <div className="gov-container__content">{children}</div>
      </footer>)
    }
    case 'header' : {
      return (<header className={finalClass}>
        <div className="gov-container__content">{children}</div>
      </header>)
    }
    default : {
      return (<div className={finalClass}>
        <div className="gov-container__content">{children}</div>
      </div>)
    }
  }
}
