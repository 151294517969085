import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Headline, Paragraph } from '../../gov-components';
import telephone from '../../resources/icons/telephone.svg';

export const ContactSection = () => {
  const { t } = useTranslation();

  return (
    <section id={ 'kontakt-id' }>
      <Container className={ 'gov-container--900px container__seventh-section' }>
        <Headline type={2} className={ 'heading2' }>{t('questions')}</Headline>
        <div className={ 'container__seventh-section--telephone' }>
          <img src={ telephone }
               alt={t(`contact.altOne`)} /><Paragraph>+420 954 200 200</Paragraph>
        </div>
      </Container>
    </section>
  )
}
