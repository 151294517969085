import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Link } from './Link';
import { Container } from './Container';
import { List, ListItem } from './List';
import { Button } from './Button';
import { scrollToElement } from './Utils';
import { Headline } from './Headline';
import { Paragraph } from './Paragraph';

import externalLink from '../resources/icons/external-white.svg';
import { withLocale } from '../util/routing';
import * as Constants from '../util/constants';

export const Footer = () => {
  const { t } = useTranslation();
  const { location: { pathname } }: { location: { pathname: string } } = useHistory();
  const hasDifferentContent: boolean = pathname.includes('ua') || pathname.includes('ru');
  const isDataboxPage: boolean = pathname.includes(withLocale(Constants.ROUTE_PATHS.databox));

  const getMeta = (metaName: string) => {
    const metas = document.getElementsByTagName('meta');
    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute('name') === metaName) {
        return metas[i].getAttribute('content');
      }
    }
    return 'undefined';
  }

  return (<Container tag="footer" className="u-bg-color--grey-dark gov-footer">
    <section className="gov-footer__upper u-screen--only">
      <div className="gov-footer__col">
        <Headline type={3} className="gov-footer__headline">
          {t('footer.firstColumn.heading')}
        </Headline>
          {hasDifferentContent ?
            <List plain={true}>
              <ListItem>
                <Link
                  standalone={true}
                  inversed={true}
                  href={'https://portal.gov.cz/tiraz/osobni-udaje-a-cookies'}
                >{t('footer.firstColumn.text.second')}
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  standalone={true}
                  inversed={true}
                  href={'https://portal.gov.cz/tiraz/pro-media'}
                >{t("footer.firstColumn.text.fifth")}
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  standalone={true}
                  inversed={true}
                  href={'https://portal.gov.cz/tiraz/prohlaseni-o-pristupnosti'}
                >{t('footer.firstColumn.text.first')}
                </Link>
              </ListItem>
            </List>
            :
            <List plain={true}>
              <ListItem>
                <Link
                  standalone={true}
                  inversed={true}
                  target="_blank"
                  href={'https://info.mojedatovaschranka.cz/'}
                >{t("footer.firstColumn.text.sixth")}
                  <img className="plain-list__external-link"
                       src={externalLink}
                       alt={t("mainPage.imageLinkExternal")} />
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  standalone={true}
                  inversed={true}
                  target="_blank"
                  href={'https://www.identitaobcana.cz'}
                >{t("footer.firstColumn.text.seventh")}
                  <img className="plain-list__external-link"
                       src={externalLink}
                       alt={t("mainPage.imageLinkExternal")} />
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  standalone={true}
                  inversed={true}
                  target="_blank"
                  href={'https://info.identitaobcana.cz'}
                >{t("footer.firstColumn.text.eighth")}
                  <img className="plain-list__external-link"
                       src={externalLink}
                       alt={t("mainPage.imageLinkExternal")} />
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  standalone={true}
                  inversed={true}
                  href={'https://portal.gov.cz/'}
                >{t("footer.firstColumn.text.fourth")}
                </Link>
              </ListItem>
            </List>
          }
      </div>
      {hasDifferentContent ?
        <div className="gov-footer__col">
          <Headline type={3} className="gov-footer__headline">
            {t('footer.secondColumn.heading')}
          </Headline>
          <List plain={true}>
            <ListItem>
              <a className="gov-footer--email-link"
                 href={'mailto:portalobcana@dia.gov.cz'}>portalobcana@dia.gov.cz</a>
              <a title={t("footer.partOne.textLinkFiveTitle")}
                 className="clipboard"
                 onClick={() => navigator.clipboard.writeText('portalobcana@dia.gov.cz')}>&nbsp;⧉</a>
            </ListItem>
          </List>
        </div>
        :
        <div className="gov-footer__col">
          <Headline type={3} className="gov-footer__headline">
            {t('footer.secondColumn.headingNew')}
          </Headline>
          <List plain={true}>
            <ListItem>
              <Link
                standalone={true}
                inversed={true}
                href={'https://portal.gov.cz/tiraz/prohlaseni-o-pristupnosti'}
              >{t('footer.firstColumn.text.first')}
              </Link>
            </ListItem>
            <ListItem>
              <Link
                standalone={true}
                inversed={true}
                target="_blank"
                href={'https://portal.gov.cz/tiraz/osobni-udaje-a-cookies'}
              >{t('footer.firstColumn.text.second')}
              </Link>
            </ListItem>
            <ListItem>
              <Link
                standalone={true}
                inversed={true}
                target="_blank"
                href={'https://portal.gov.cz/tiraz/pro-media'}
              >{t("footer.firstColumn.text.fifth")}
              </Link>
            </ListItem>
          </List>
        </div>
      }
      <div className="gov-footer__col">
        {hasDifferentContent ?
          <></>
          :
          <>
            <Headline type={3} className="gov-footer__headline">
              {t('footer.secondColumn.heading')}
            </Headline>
            {isDataboxPage ?
              <List plain={true}>
                <ListItem>
                  <Link
                    standalone={true}
                    inversed={true}
                    target="_blank"
                    href={'https://info.mojedatovaschranka.cz/info/cs/eporadna.html'}
                    className="link-databox"
                  >
                    {t('footer.thirdColumn.textFirst')}
                    <img className="plain-list__external-link"
                         src={externalLink}
                         alt={t("mainPage.imageLinkExternal")} />
                  </Link>
                </ListItem>
                <ListItem>
                  <div className="gov-footer--email-phone-link">
                    <span className="gov-icon gov-icon--phone gov-icon--16x16" />
                    <a className="gov-footer--link"
                       href={'tel:954200200'}>954 200 200</a>
                    <span>&nbsp;{t('footer.thirdColumn.textSecond')}</span>
                  </div>
                </ListItem>
              </List>
              :
              <List plain={true}>
                <ListItem>
                  <a className="gov-footer--email-link"
                     href={'mailto:portalobcana@dia.gov.cz'}>portalobcana@dia.gov.cz</a>
                  <a title={t("footer.partOne.textLinkFiveTitle")}
                     className="clipboard"
                     onClick={() => navigator.clipboard.writeText('portalobcana@dia.gov.cz')}>&nbsp;⧉</a>
                </ListItem>
              </List>
            }
          </>
        }
        <Headline type={3} className="gov-footer__headline">
          {t('footer.thirdColumn.heading')}
        </Headline>
        <List plain={true} className="gov-list--inline">
          <ListItem>
            <Link
              inversed={true}
              target="_blank"
              href={'https://www.facebook.com/cz.eGovernment/'}
            ><span className="gov-icon gov-icon--facebook" />
            </Link>
          </ListItem>
          <ListItem>
            <Link
              inversed={true}
              target="_blank"
              href={'https://twitter.com/gov_cz'}
            ><img src="/static/images/x.svg" alt="Logo sociální sítě X" className="x-position" width={22} height={22} />
            </Link>
          </ListItem>
          <ListItem>
            <Link
              inversed={true}
              target="_blank"
              href={'https://www.youtube.com/channel/UCCEISjcwdeqghfa4Z2mWMOg'}
            ><span className="gov-icon gov-icon--youtube" />
            </Link>
          </ListItem>
        </List>
      </div>
    </section>
    <hr />
    <section className="gov-footer__lower">
      <Paragraph className="gov-footnote">
        <span>{(new Date().getFullYear())}</span> &copy; {t('footer.footnote')}
      </Paragraph>
      <Paragraph className="gov-footnote">{t('global.version')} {getMeta('version')}</Paragraph>
    </section>
    <Button
      color={'primary-outlined'}
      inversed={true}
      className="gov-footer__scroll-up gov-js--scroll-up u-color--blue"
      aria-labelledby="fo-scrollBtn"
      onClick={() => scrollToElement({ elem: 0 })}
    >
      <span id="fo-scrollBtn" className="u-sr-only gov-button__label">{t('footer.backHome')}</span>
    </Button>
  </Container>);
}
