import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  HomePageHeader,
} from '../gov-components';
import {
  CardsSection,
  InfotileTabs
} from '../pages/index';

import * as Constants from '../util/constants';
import { withLocale } from '../util/routing';

export const HomePageNew = () => {
  const { location: { } }: { location: { pathname: string } } = useHistory();
  const { t } = useTranslation();
  const lang = localStorage.getItem('lang');

  const history = useHistory();
  const navigateTo = (route: string) => {
    history.push(route);
  }

  const pathDataboxSetUpWithLocale = withLocale(Constants.ROUTE_PATHS.databoxSetUp);
  const pathIdentitySetUpWithLocale = withLocale(Constants.ROUTE_PATHS.identitySetUp);

  return (
    <>
      <Helmet>
        <html lang={lang ? lang : 'cs'} />
        <title lang={lang ? lang : 'cs'}>{t('homePage.seo.title')}</title>
        <link rel="canonical" hrefLang={`${lang}`} href={`https://chcidatovku.gov.cz/${lang}/${Constants.ROUTE_PATHS.intro}`} />
        <meta
          lang={lang ? lang : 'cs'}
          name="description"
          content={t('homePage.seo.description')} />
      </Helmet>
      <HomePageHeader
        version={ 'home' }
        title={t('homePage.header.titleOne')}
        subtitle={t('homePage.header.subtitleOne')}
        buttonLinkDatabox={ withLocale(Constants.ROUTE_PATHS.databoxSetUp) }
        buttonTextDatabox={t('homePage.header.buttonOne')}
        onButtonLinkDataBoxClick={ () => navigateTo(pathDataboxSetUpWithLocale)}
        buttonLinkIdentity={ withLocale(Constants.ROUTE_PATHS.identitySetUp) }
        buttonTextIdentity={t('homePage.header.buttonTwo')}
        onButtonLinkIdentityClick={ () => navigateTo(pathIdentitySetUpWithLocale) }
      />
      <InfotileTabs />
      <CardsSection />
  </>)
}
