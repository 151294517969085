import * as React from 'react';

export interface PromoBannerProps {
  children?: any;
  className?: string;
  image?: any;
}

export const PromoBanner = (props: PromoBannerProps) => {
  const { className, children, image } = props;
  let finalClass: string = 'gov-promo-banner';

  finalClass = className ?
    `${finalClass} ${className}` :
    finalClass;

  return <div className={finalClass}>
    <div className="gov-promo-banner__content">
      {children}
    </div>
    {image && <div className="gov-promo-banner__image">{image}</div>}
  </div>
}
