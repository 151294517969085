import * as React from 'react'
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  Link,
  Paragraph,
  scrollToElement
} from '../../gov-components';

import externalLink from '../../resources/icons/external.svg';

import { withLocale } from '../../util/routing';
import * as Constants from '../../util/constants';

interface DataboxAccordionProps {
  expandedNumber: number | undefined;
  switchDataboxTab?: (tab: number) => void;
}

export const DataboxAccordionsQANewGeneral = ({ expandedNumber }: DataboxAccordionProps) => {
  const { t } = useTranslation();
  const [expandedNumberItem, setExpandedNumber] = useState<number | undefined>(expandedNumber);
  const pathIdentityWithLocale = withLocale(Constants.ROUTE_PATHS.identity);
  const pathDataboxSetUpWithLocale = withLocale(Constants.ROUTE_PATHS.databoxSetUp);

  let accordionEl: any = 'gov-accordion-header-0';

  return (
    <Accordion className={ 'gov-accordion--bordered' } expandedItem={ expandedNumberItem } accordionRef={(el: any) => accordionEl = el}>
      <AccordionItem title={t("databoxAccordionsNew.titleOne")}
                     keyNumber={0}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }
                     hasBadge={true}
                     badgeText={t("databoxAccordionsNew.badgeText")}>
        <Paragraph>
          {t("databoxAccordionsNew.textOnePartOne")}&nbsp;
            <Link
              title={t("databoxAccordionsNew.titleLinkOne")}
              href={'https://info.mojedatovaschranka.cz/'}
              target="_blank"
              className={ 'stepper-wrapper__text--link' }
            >{t("databoxAccordionsNew.textLinkOne")}
              <img className={ 'plain-list__external-link' }
                   src={ externalLink }
                   alt={t("mainPage.imageLinkExternal")} />
            </Link>&nbsp; {t("databoxAccordionsNew.textOnePartTwo")}&nbsp;
          <b>{t("databoxAccordionsNew.textOnePartThree")}</b> {t("databoxAccordionsNew.textOnePartFour")}&nbsp;
          <b>{t("databoxAccordionsNew.textOnePartFive")}</b> {t("databoxAccordionsNew.textOnePartSix")}&nbsp;
          <b>{t("databoxAccordionsNew.textOnePartSeven")}</b> {t("databoxAccordionsNew.textOnePartEight")}&nbsp;
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t('databoxAccordionsNew.titleThree')}
                     keyNumber={1}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }
                     hasBadge={true}
                     badgeText={t("databoxAccordionsNew.badgeText")}>
        <Paragraph className={ 'u-mb--24' }>
          {t("databoxAccordionsNew.textFourPartOne")}&nbsp;
          <b>{t("databoxAccordionsNew.textFourPartTwo")}</b> {t("databoxAccordionsNew.textFourPartThree")}&nbsp;
          <b>{t("databoxAccordionsNew.textFourPartFour")}</b>
        </Paragraph>
        <Paragraph>
          {t("databoxAccordionsNew.textFivePartOne")}&nbsp;
            <Link
              title={t("databoxAccordionsNew.titleLinkThree")}
              onClick={() => { scrollToElement({ elem: accordionEl }); setExpandedNumber(0) }}
              standalone={true}
              size={ 'large' }
              inversed={true}
              className={ 'stepper-wrapper__text--link button-link' }
            >{t("databoxAccordionsNew.textSixLink")}</Link>
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t('databoxAccordionsNew.titleThreeNew')}
                     keyNumber={2}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }
                     hasBadge={true}
                     badgeText={t("databoxAccordionsNew.badgeText")}>
        <Paragraph className={ 'u-mb--24' }>
          {t("databoxAccordionsNew.textThreeNewPartOne")}&nbsp;
            <Link
              title={t("databoxAccordionsNew.textThreeNewPartTwoLinkTitle")}
              href={ pathIdentityWithLocale }
              className={ 'stepper-wrapper__text--link' }
            >{t("databoxAccordionsNew.textThreeNewPartTwoLink")}
            </Link>&nbsp;
          {t("databoxAccordionsNew.textThreeNewPartThree")}
        </Paragraph>
        <Paragraph>{t("databoxAccordionsNew.textThreeNewPartFour")}</Paragraph>
      </AccordionItem>
      <AccordionItem title={t("databoxAccordionsNew.titleTwo")}
                     keyNumber={3}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }>
        <Paragraph className={ 'u-mb--24' }>
          {t("databoxAccordionsNew.textTwoPartOne")}&nbsp;
          <b>{t("databoxAccordionsNew.textTwoPartTwo")}</b> {t("databoxAccordionsNew.textTwoPartThree")}
        </Paragraph>
        <Paragraph className={ 'u-mb--24' }>
          <b>{t("databoxAccordionsNew.textThreePartOne")}</b> {t("databoxAccordionsNew.textThreePartTwo")}&nbsp;
          <b>{t("databoxAccordionsNew.textThreePartThree")}</b> {t("databoxAccordionsNew.textThreePartFour")}&nbsp;
          <b>{t("databoxAccordionsNew.textThreePartFive")}</b> {t("databoxAccordionsNew.textThreePartSix")}
        </Paragraph>
        <Paragraph>
          <Link
            title={t("databoxAccordionsNew.titleLinkTwo")}
            href={'https://info.mojedatovaschranka.cz/info/cs/82.html'}
            target="_blank"
            className={ 'stepper-wrapper__text--link' }
          >{t("databoxAccordionsNew.textFourLink")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t('databoxAccordionsNew.titleFour')}
                     keyNumber={4}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }>
        <Paragraph className={ 'u-mb--24' }>
          {t("databoxAccordionsNew.textSevenPartOne")}&nbsp;
          <b>{t("databoxAccordionsNew.textSevenPartTwo")}</b> {t("databoxAccordionsNew.textSevenPartThree")}
        </Paragraph>
        <Paragraph className={ 'u-mb--24' }>{t('databoxAccordionsNew.textEightPartOne')}</Paragraph>
        <ul className={ 'unordered-list no-margin-bottom' }>
          <li>{t('databoxAccordionsNew.textNinePartOne')}</li>
          <li>{t('databoxAccordionsNew.textNinePartTwo')}</li>
          <li>{t('databoxAccordionsNew.textNinePartThree')}</li>
        </ul>
      </AccordionItem>
      <AccordionItem title={t("databoxAccordionsNew.titleSix")}
                     keyNumber={5}
                     expandedId={ expandedNumberItem }
                     className={'container__fourth-section--items'}>
        <Paragraph className={ 'u-mb--24' }>{t("databoxAccordionsNew.textTwelvePartOne")}</Paragraph>
        <Paragraph className={ 'u-mb--24' }>{t("databoxAccordionsNew.textThirteenPartOne")}&nbsp;
          <b>{t("databoxAccordionsNew.textThirteenPartTwo")}</b> {t("databoxAccordionsNew.textThirteenPartThree")}</Paragraph>
        <Paragraph className={ 'u-mb--24' }>{t("databoxAccordionsNew.textFourteenPartOne")}&nbsp;
          <b>{t("databoxAccordionsNew.textFourteenPartTwo")}</b> {t("databoxAccordionsNew.textFourteenPartThree")}</Paragraph>
        <Paragraph className={ 'u-mb--24' }>{t("databoxAccordionsNew.textFifteenPartOne")}</Paragraph>
        <Paragraph>
          <Link
            title={t("databoxAccordionsNew.textSixteenPartOneLinkTitle")}
            href={'https://info.mojedatovaschranka.cz/info/cs/65.html'}
            target="_blank"
            className={ 'stepper-wrapper__text--link' }
          >{t("databoxAccordionsNew.textSixteenPartOneLink")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t("databoxAccordionsNew.titleSeven")}
                     keyNumber={6}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }>
        <Paragraph className={ 'u-mb--24' }>{t("databoxAccordionsNew.textSeventeenPartOne")}&nbsp;
          <b>{t("databoxAccordionsNew.textSeventeenPartTwo")}</b> {t("databoxAccordionsNew.textSeventeenPartThree")}&nbsp;
          <b>{t("databoxAccordionsNew.textSeventeenPartFour")}</b> {t("databoxAccordionsNew.textSeventeenPartFive")}
        </Paragraph>
        <Paragraph>{t("databoxAccordionsNew.textEighteenPartOne")}&nbsp;
          <Link
            title={t("databoxAccordionsNew.textEighteenPartTwoLinkTitle")}
            href={'https://info.mojedatovaschranka.cz/info/cs/1025.html'}
            target="_blank"
            className={ 'stepper-wrapper__text--link' }
          >{t("databoxAccordionsNew.textEighteenPartTwoLink")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t("databoxAccordionsNew.titleEightNew")}
                     keyNumber={7}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }>
        <Paragraph className={ 'u-mb--24' }>
          {t("databoxAccordionsNew.textEightNewPartOne")} <b>{t("databoxAccordionsNew.textEightNewPartTwo")}</b>&nbsp;
          {t("databoxAccordionsNew.textEightNewPartThree")}&nbsp;
        </Paragraph>
        <Paragraph>
          {t("databoxAccordionsNew.textEightNewPartFour")}
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t("databoxAccordionsNew.titleFive")}
                     keyNumber={8}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }>
        <Paragraph className={ 'u-mb--24' }>
          {t("databoxAccordionsNew.textTenPartOne")}&nbsp;
            <Link
              title={t("databoxAccordionsNew.titleLinkFour")}
              href={'https://obcan.portal.gov.cz/prihlaseni'}
              target="_blank"
              className={ 'stepper-wrapper__text--link' }
            >{t("databoxAccordionsNew.textTenPartTwoLink")}
              <img className={ 'plain-list__external-link' }
                   src={ externalLink }
                   alt={t("mainPage.imageLinkExternal")} />
            </Link>&nbsp;
          {t("databoxAccordionsNew.textTenPartThree")}&nbsp;
          <b>{t("databoxAccordionsNew.textTenPartFour")}</b> {t("databoxAccordionsNew.textTenPartFive")}&nbsp;
            <Link
              title={t("databoxAccordionsNew.titleLinkFive")}
              href={'https://info.mojedatovaschranka.cz/'}
              target="_blank"
              className={ 'stepper-wrapper__text--link' }
            >{t("databoxAccordionsNew.textTenPartSixLink")}
              <img className={ 'plain-list__external-link' }
                   src={ externalLink }
                   alt={t("mainPage.imageLinkExternal")} />
            </Link>&nbsp;.
        </Paragraph>
        <Paragraph>
          {t("databoxAccordionsNew.textElevenPartOne")}&nbsp;
            <Link
              title={t("databoxAccordionsNew.titleLinkFive")}
              href={'https://info.mojedatovaschranka.cz/'}
              target="_blank"
              className={ 'stepper-wrapper__text--link' }
            >{t("databoxAccordionsNew.textElevenPartTwoLink")}
              <img className={ 'plain-list__external-link' }
                   src={ externalLink }
                   alt={t("mainPage.imageLinkExternal")} />
            </Link>&nbsp;
          {t("databoxAccordionsNew.textElevenPartThree")}
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t("databoxAccordionsNew.titleNineNew")}
                     keyNumber={9}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }>
        <Paragraph>
          {t("databoxAccordionsNew.textNineNewPartOne")}
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t("databoxAccordionsNew.titleTenNew")}
                     keyNumber={10}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }>
        <Paragraph>
          <strong>{t("databoxAccordionsNew.textTenNewPartOne")}</strong>{' '}
          {t("databoxAccordionsNew.textTenNewPartTwo")}{' '}
          <Link
              title={t("databoxAccordionsNew.textTenNewPartThreeLinkTitle")}
              href={ pathDataboxSetUpWithLocale }
              className={ 'stepper-wrapper__text--link' }
            >{t("databoxAccordionsNew.textTenNewPartThreeLink")}
            </Link>
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t("databoxAccordionsNew.titleElevenNew")}
                     keyNumber={11}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }>
        <Paragraph className={ 'u-mb--24' }>
        {t("databoxAccordionsNew.textElevenNewPartOne")}{' '}
        <strong>{t("databoxAccordionsNew.textElevenNewPartTwo")}</strong>{' '}
        {t("databoxAccordionsNew.textElevenNewPartThree")}{' '}
        <strong>{t("databoxAccordionsNew.textElevenNewPartFour")}</strong>{' '}
        {t("databoxAccordionsNew.textElevenNewPartFive")}
        </Paragraph>
        <Paragraph>
        {t("databoxAccordionsNew.textElevenNewPartSix")}
        </Paragraph>
      </AccordionItem>
    </Accordion>
  )
}

export const DataboxAccordionsQANewAutomaticSetup = ({ expandedNumber, switchDataboxTab }: DataboxAccordionProps) => {
  const { t } = useTranslation();
  const [expandedNumberItem, setExpandedNumber] = useState<number | undefined>(expandedNumber);
  const pathIdentityWithLocale = withLocale(Constants.ROUTE_PATHS.identity);

  let accordionEl: any = 'gov-accordion-header-0';

  return (
    <Accordion className={ 'gov-accordion--bordered' } expandedItem={ expandedNumberItem } accordionRef={(el: any) => accordionEl = el}>
      <AccordionItem title={t("databoxAccordionsNewSetup.titleOne")}
                     keyNumber={0}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }
                     hasBadge={true}
                     badgeText={t("databoxAccordionsNew.badgeText")}>
        <Paragraph className={ 'u-mb--24' }>
          {t("databoxAccordionsNewSetup.textOnePartOne")}
        </Paragraph>
        <Paragraph className={ 'u-mb--24' }>
          {t("databoxAccordionsNewSetup.textOnePartTwo")}
        </Paragraph>
        <Paragraph>
          {t("databoxAccordionsNewSetup.textOnePartThree")}
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t("databoxAccordionsNewSetup.titleTwo")}
                     keyNumber={1}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }
                     hasBadge={true}
                     badgeText={t("databoxAccordionsNew.badgeText")}>
        <Paragraph className={ 'u-mb--24' }>
          {t("databoxAccordionsNewSetup.textTwoPartOne")}
        </Paragraph>
        <Paragraph className={ 'u-mb--24' }>
          {t("databoxAccordionsNewSetup.textTwoPartTwo")}&nbsp;
            <Link
              title={t("databoxAccordionsNewSetup.textTwoPartThreeLinkTitle")}
              onClick={() => {
                switchDataboxTab && switchDataboxTab(1);
                scrollToElement({ elem: 'badge-prepinace' });
            }}
              standalone={true}
              size={ 'large' }
              inversed={true}
              className={ 'stepper-wrapper__text--link button-link' }
            >{t("databoxAccordionsNewSetup.textTwoPartThreeLink")}</Link>.&nbsp;
        </Paragraph>
        <Paragraph>
          {t("databoxAccordionsNewSetup.textTwoPartFour")}
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t('databoxAccordionsNewSetup.titleFour')}
                     keyNumber={2}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }
                     hasBadge={true}
                     badgeText={t("databoxAccordionsNew.badgeText")}>
        <Paragraph className={ 'u-mb--24' }>
          {t("databoxAccordionsNewSetup.textFourPartOne")}&nbsp;
            <Link
              title={t("databoxAccordionsNewSetup.textFourPartTwoLinkTitle")}
              href={ pathIdentityWithLocale }
              className={ 'stepper-wrapper__text--link' }
            >{t("databoxAccordionsNewSetup.textFourPartTwoLink")}
            </Link>&nbsp;
          {t("databoxAccordionsNewSetup.textFourPartThree")}
        </Paragraph>
        <Paragraph>{t("databoxAccordionsNewSetup.textFourPartFour")}</Paragraph>
      </AccordionItem>
      <AccordionItem title={t('databoxAccordionsNew.titleNine')}
                     keyNumber={3}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }
                     hasBadge={true}
                     badgeText={t("databoxAccordionsNew.badgeText")}>
        <Paragraph>
          {t("databoxAccordionsNew.textTwentyTwoPartOne")}&nbsp;
          <b>{t("databoxAccordionsNew.textTwentyTwoPartTwo")}</b>&nbsp;
          {t("databoxAccordionsNew.textTwentyTwoPartThree")}&nbsp;
            <Link
              title={t("databoxAccordionsNew.textTwentyTwoPartThreeLinkTitle")}
              href={'https://info.mojedatovaschranka.cz/'}
              target="_blank"
              className={'stepper-wrapper__text--link'}
            >{t("databoxAccordionsNew.textTwentyTwoPartThreeLink")}
            </Link>&nbsp;
          {t("databoxAccordionsNew.textTwentyTwoPartThreeNew")}&nbsp;
          <b>{t("databoxAccordionsNew.textTwentyTwoPartFour")}</b>&nbsp;
          {t("databoxAccordionsNew.textTwentyTwoPartFive")}
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t("databoxAccordionsNew.titleEight")}
                     keyNumber={4}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }
                     hasBadge={true}
                     badgeText={t("databoxAccordionsNew.badgeText")}>
        <Paragraph>
          {t("databoxAccordionsNew.textEightPartOneNew")}
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t("databoxAccordionsNewSetup.titleThree")}
                     keyNumber={5}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }>
        <Paragraph>
          {t("databoxAccordionsNewSetup.textThree")}
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t("databoxAccordionsNewSetup.titleSix")}
                     keyNumber={6}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }>
        <Paragraph>
          {t("databoxAccordionsNewSetup.textSixPartTwo")}
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t("databoxAccordionsNewSetup.titleSeven")}
                     keyNumber={7}
                     expandedId={ expandedNumberItem }
                     className={'container__fourth-section--items'}>
        <Paragraph>
          {t("databoxAccordionsNewSetup.textSevenPartTwo")}
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t("databoxAccordionsNewSetup.titleNine")}
                     keyNumber={8}
                     expandedId={ expandedNumberItem }
                     className={'container__fourth-section--items'}>
        <Paragraph>
          <b>{t("databoxAccordionsNewSetup.textNinePartOne")}</b>&nbsp;
          {t("databoxAccordionsNewSetup.textNinePartTwo")}
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t("databoxAccordionsNewSetup.titleTen")}
                     keyNumber={9}
                     expandedId={ expandedNumberItem }
                     className={'container__fourth-section--items'}>
        <Paragraph>
          <b>{t("databoxAccordionsNewSetup.textTenPartOne")}</b>&nbsp;
          {t("databoxAccordionsNewSetup.textTenPartTwo")}
        </Paragraph>
      </AccordionItem>
    </Accordion>
  )
}

export const DataboxAccordionsQANewCommunication = ({ expandedNumber }: DataboxAccordionProps) => {
  const { t } = useTranslation();
  const [expandedNumberItem, setExpandedNumber] = useState<number | undefined>(expandedNumber);

  let accordionEl: any = 'gov-accordion-header-0';

  return (
    <Accordion className={ 'gov-accordion--bordered' } expandedItem={ expandedNumberItem } accordionRef={(el: any) => accordionEl = el}>
      <AccordionItem title={t("databoxAccordionsNewCommunication.titleOne")}
                     keyNumber={0}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }
                     hasBadge={true}
                     badgeText={t("databoxAccordionsNew.badgeText")}>
        <Paragraph className={ 'u-mb--24' }>
          {t("databoxAccordionsNewCommunication.textOnePartOne")}
        </Paragraph>
        <Paragraph>
          {t("databoxAccordionsNewCommunication.textOnePartTwo")}
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t("databoxAccordionsNewCommunication.titleTwo")}
                     keyNumber={1}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }
                     hasBadge={true}
                     badgeText={t("databoxAccordionsNew.badgeText")}>
        <Paragraph>
          <b>{t("databoxAccordionsNewCommunication.textTwoPartOne")}</b>&nbsp;
          {t("databoxAccordionsNewCommunication.textTwoPartTwo")}
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t("databoxAccordionsNewCommunication.titleThree")}
                     keyNumber={2}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }>
        <Paragraph>
          <b>{t("databoxAccordionsNewCommunication.textThreePartOne")}</b>&nbsp;
          {t("databoxAccordionsNewCommunication.textThreePartTwo")}
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t('databoxAccordionsNewCommunication.titleFour')}
                     keyNumber={3}
                     expandedId={ expandedNumberItem }
                     className={ 'container__fourth-section--items' }>
        <Paragraph className={ 'u-mb--24' }>
          <b>{t("databoxAccordionsNewCommunication.textFourPartOne")}</b>&nbsp;
          {t("databoxAccordionsNewCommunication.textFourPartTwo")}&nbsp;
          <b>{t("databoxAccordionsNewCommunication.textFourPartThree")}</b>&nbsp;
          {t("databoxAccordionsNewCommunication.textFourPartFour")}
        </Paragraph>
        <Paragraph>
          {t("databoxAccordionsNewCommunication.textFourPartFive")}
        </Paragraph>
      </AccordionItem>
    </Accordion>
  )
}
