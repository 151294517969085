import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionItem,
  Link,
  Paragraph,
} from '../../gov-components';

import externalLink from '../../resources/icons/external.svg';

export const IdentityAccordionsQAndANew = () => {
  const { t } = useTranslation();

  return (
    <Accordion className={ 'gov-accordion--bordered' }  >
      <AccordionItem title={t("identityAccordionsNew.titleOne")}
                     keyNumber={0}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>
          <b>{t("identityAccordionsNew.textOnePartOne")}</b> {t("identityAccordionsNew.textOnePartTwo")}&nbsp;
          <Link
            title={t("identityAccordionsNew.titleLinkOne")}
            href={'https://obcan.portal.gov.cz/prihlaseni'}
            target="_blank"
            className={ 'stepper-wrapper__text--link' }
          >{t("identityAccordionsNew.textLinkOne")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>&nbsp; {t("identityAccordionsNew.textOnePartThree")}
        </Paragraph>
        <Paragraph>
          {t("identityAccordionsNew.textOnePartFour")}&nbsp;
          <Link
            title={t("identityAccordionsNew.titleLinkOne")}
            href={'https://obcan.portal.gov.cz/prihlaseni'}
            target="_blank"
            className={ 'stepper-wrapper__text--link' }
          >{t("identityAccordionsNew.textOnePartFiveLink")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>&nbsp; {t("identityAccordionsNew.textOnePartSix")}
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t("identityAccordionsNew.titleTwo")}
                     keyNumber={1}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>
          <b>{t("identityAccordionsNew.textTwoPartOne")}</b> {t("identityAccordionsNew.textTwoPartTwo")}&nbsp;
          <b>{t("identityAccordionsNew.textTwoPartThree")}</b> {t("identityAccordionsNew.textTwoPartFour")}
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t('identityAccordionsNew.titleThree')}
                     keyNumber={2}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>
          <Link
            title={t("identityAccordionsNew.titleLinkTwo")}
            href={'https://www.identitaobcana.cz/Home'}
            target="_blank"
            className={ 'stepper-wrapper__text--link' }
          >{t("identityAccordionsNew.textThreePartOneLink")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>
        </Paragraph>
        <Paragraph>
          {t("identityAccordionsNew.textFourPartOne")}&nbsp;
          <b>{t("identityAccordionsNew.textFourPartTwo")}</b>
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t('identityAccordionsNew.titleFour')}
                     keyNumber={3}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>
          {t("identityAccordionsNew.textFivePartOne")}&nbsp;
          <Link
            title={t("identityAccordionsNew.titleLinkTwo")}
            href={'https://info.identitaobcana.cz/Podpora.aspx'}
            target="_blank"
            className={ 'stepper-wrapper__text--link' }
          >{t("identityAccordionsNew.textFivePartTwoLink")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>&nbsp; {t("identityAccordionsNew.textFivePartThree")}
        </Paragraph>
        <Paragraph>
          {t('identityAccordionsNew.textSixPartOne')}&nbsp;
          <Link
            title={t("identityAccordionsNew.titleLinkTwo")}
            href={'https://info.identitaobcana.cz/Dostupnost/'}
            target="_blank"
            className={ 'stepper-wrapper__text--link' }
          >{t("identityAccordionsNew.textSixPartTwoLink")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t("identityAccordionsNew.titleFive")}
                     keyNumber={4}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>
         <b>{t("identityAccordionsNew.textSevenPartOne")}</b>&nbsp;
          {t("identityAccordionsNew.textSevenPartTwo")}
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t("identityAccordionsNew.titleSix")}
                     keyNumber={5}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t("identityAccordionsNew.textEightPartOne")}&nbsp;
          <b>{t("identityAccordionsNew.textEightPartTwo")}</b> {t("identityAccordionsNew.textEightPartThree")}</Paragraph>
        <Paragraph>
          <Link
            title={t("identityAccordionsNew.titleLinkTwo")}
            href={'https://info.identitaobcana.cz/foreigners/'}
            target="_blank"
            className={ 'stepper-wrapper__text--link' }
          >{t("identityAccordionsNew.textNinePartOneLink")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>&nbsp;
          {t("identityAccordionsNew.textNinePartTwo")}&nbsp;
          <Link
            title={t("identityAccordionsNew.titleLinkTwo")}
            href={'https://info.identitaobcana.cz/idp/'}
            target="_blank"
            className={ 'stepper-wrapper__text--link' }
          >{t("identityAccordionsNew.textNinePartThreeLink")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t("identityAccordionsNew.titleSeven")}
                     keyNumber={6}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t("identityAccordionsNew.textTenPartOne")}&nbsp;
          <b>{t("identityAccordionsNew.textTenPartTwo")}</b> {t("identityAccordionsNew.textTenPartThree")}
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t("identityAccordionsNew.titleEight")}
                     keyNumber={7}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t("identityAccordionsNew.textElevenPartOne")}&nbsp;
          <b>{t("identityAccordionsNew.textElevenPartTwo")}</b> {t("identityAccordionsNew.textElevenPartThree")}&nbsp;
          <Link
            title={t("identityAccordionsNew.titleLinkTwo")}
            href={'https://info.identitaobcana.cz/mep/'}
            target="_blank"
            className={ 'stepper-wrapper__text--link' }
          >{t("identityAccordionsNew.textElevenPartThreeLink")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>&nbsp; {t("identityAccordionsNew.textElevenPartFour")}&nbsp;
          <Link
            title={t("identityAccordionsNew.titleLinkTwo")}
            href={'https://www.identitaobcana.cz/ProfileRegistration'}
            target="_blank"
            className={ 'stepper-wrapper__text--link' }
          >{t("identityAccordionsNew.textElevenPartFiveLink")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>
        </Paragraph>
      </AccordionItem>
    </Accordion>
  )
}
