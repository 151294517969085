import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, BrowserRouterProps } from 'react-router-dom';
import { Layout } from './Layout';

import ScrollToElement from './pages/components/ScrollToElement';
import ScrollToTop from './pages/components/ScrollToTop';

import './i18n.ts';
import './create-nonce';
import './designsystem/assets/css/styles.scss';
import './designsystem/assets/css/print.scss';
import './scss/front.scss';

const routerProps: BrowserRouterProps = {
  basename: '/',
  forceRefresh: true
};

const renderApp = () => {
  ReactDOM.render(
    <React.Suspense fallback='Loading...'>
      <BrowserRouter {...routerProps}>
          <ScrollToElement />
          <ScrollToTop />
          <Layout />
      </BrowserRouter>
    </React.Suspense>,
    document.getElementById('app')
  );
};

renderApp();
