import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { Button } from './Button';
import { Headline } from './Headline';
import { Paragraph } from './Paragraph';
import { ReactSVG } from 'react-svg';

/*export interface GroupType {
  id: number;
  title?: string;
  collapseVisible?: boolean;
}

const Groups: GroupType[] = [
  {
    id: 1,
    title: 'Státní prostředky',
  },
  {
    id: 2,
    title: 'Bankovní identita',
  },
  {
    id: 3,
    title: 'Ostatní prostředky',
  },
  {
    id: 4,
    title: 'Portály VS',
  }
]

export interface IconType {
  id: number;
  bigImage: string;
  icon: string;
  title: string;
  group: number;
}*/

export interface IconModalItemProps {
  iconSource?: string;
  svgSource?: string;
  href?: string;
  onClick?: any;
  noButton?: boolean;
  modalTitle?: string;
  modalButtonName?: string;
  modalContent?: JSX.Element;
  buttonLink?: string;
  className?: string;
  classNameString?: string;
  classNameItem?: string;
  iconText?: string;
  alt?: string;
  children?: any;
}

export const IconModalItem = (props: IconModalItemProps) => {
  const {
    children,
    iconText,
    className,
    classNameString,
    classNameItem,
    alt,
    iconSource,
    svgSource,
    href,
    modalContent,
    modalTitle,
    modalButtonName,
    buttonLink,
    noButton
  } = props;
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (<>
    {href ?
      <a href={ href }>
        <div className={ classNameItem } >
          {iconSource &&
            <img className={ className }
                 src={ iconSource }
                 alt={ alt }/>
          }
          {svgSource &&
            <ReactSVG src={ svgSource }
                      alt={ alt }
                      className={ className } />
          }
          {iconText &&
            <Paragraph className={ classNameString }>{ iconText }</Paragraph>
          }
        </div>
        { children }
      </a>
      :
      <a onClick={ handleShow }>
        <div className={ classNameItem } >
          {iconSource &&
            <img className={ className }
                 src={ iconSource }
                 alt={ alt }/>
          }
          {svgSource &&
            <ReactSVG src={ svgSource }
                      alt={ alt }
                      className={ className } />
          }
          {iconText &&
            <Paragraph className={ classNameString }>{ iconText }</Paragraph>
          }
        </div>
        { children }
      </a>
    }
    <Modal show={ show } onHide={ handleClose } animation={false}>
      <Modal.Header>
        {modalTitle && <Modal.Title><Headline type={2} className={ 'heading5' }>{modalTitle}</Headline></Modal.Title>}
        <button
          type="button"
          className="close close-btn"
          data-dismiss="modal"
          aria-label={t("grid.buttonLabel")}
          onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        { modalContent }
      </Modal.Body>
      <Modal.Footer hidden={ noButton }>
        {buttonLink ?
          <Button
            href={ buttonLink }
            color={ 'primary' }
            size={ 'large' }
            target="_blank"
          >{ modalButtonName }</Button> :
          <Button
            color={ 'primary' }
            size={ 'large' }
            onClick={ handleClose }
          >{t('grid.buttonLabel')}</Button>
        }
      </Modal.Footer>
    </Modal>
  </>)
}
