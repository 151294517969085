import * as React from 'react'
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionItem,
  Link,
  Paragraph
} from '../../gov-components';

import settings from '../../resources/icons/settings.svg';
import externalLink from '../../resources/icons/external.svg';

export const CzechAndEnglishDataboxAccordions = ({ expandedNumber }: any) => {
  const { t } = useTranslation();

  return (
    <Accordion className={ 'gov-accordion--bordered' } expandedItem={ expandedNumber }>
      <AccordionItem title={t('curiosity.questions.accordion.first.heading')}
                     keyNumber={0}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t('curiosity.questions.accordion.first.text.first')}</Paragraph>
      </AccordionItem>
      <AccordionItem title={t('curiosity.questions.accordion.second.heading')}
                     keyNumber={1}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t('curiosity.questions.accordion.second.text.first')}</Paragraph>
      </AccordionItem>
      <AccordionItem title={t('curiosity.questions.accordion.third.heading')}
                     keyNumber={2}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t('curiosity.questions.accordion.third.text.first')}</Paragraph>
        <Paragraph>{t('curiosity.questions.accordion.third.text.second')}&nbsp;
          <Link
            title={t(`curiosity.questions.titleOne`)}
            href={ 'https://www.ceskaposta.cz/sluzby/egovernment/doplnkove-sluzby-k-datovym-schrankam/postovni-datova-zprava' }
            target="_blank"
            className={ 'plain-list__link' }
          >{t('curiosity.questions.accordion.third.text.third')}
            <img className={ 'plain-list__external-link' }
                 src={externalLink}
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>.</Paragraph>
      </AccordionItem>
      <AccordionItem title={t('curiosity.questions.accordion.fourth.heading')}
                     keyNumber={3}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t('curiosity.questions.accordion.fourth.text.first')}</Paragraph>
        <Paragraph>{t('curiosity.questions.accordion.fourth.text.second')}</Paragraph>
      </AccordionItem>
      <AccordionItem title={t('curiosity.questions.accordion.fifth.heading')}
                     keyNumber={4}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t('curiosity.questions.accordion.fifth.text.first')}</Paragraph>
        <Paragraph className={ 'no-margin-bottom' }>{t('curiosity.questions.accordion.fifth.text.second')}</Paragraph>
        <ul className={ 'unordered-list no-margin-bottom' }>
          <li>{t('curiosity.questions.accordion.fifth.text.third')}</li>
          <li>{t('curiosity.questions.accordion.fifth.text.fourth')}</li>
          <li>{t('curiosity.questions.accordion.fifth.text.fifth')}</li>
        </ul>
      </AccordionItem>
      <AccordionItem title={t('curiosity.questions.accordion.sixth.heading')}
                     keyNumber={5}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t('curiosity.questions.accordion.sixth.text.first')}</Paragraph>
        <Paragraph>{t('curiosity.questions.accordion.sixth.text.second')}&nbsp;
          <b>{t('curiosity.questions.accordion.sixth.text.third')}</b>&nbsp;
          {t('curiosity.questions.accordion.sixth.text.fourth')}
        </Paragraph>
        <Paragraph>{t('curiosity.questions.accordion.sixth.text.fifth')}&nbsp;
          <b>{t('curiosity.questions.accordion.sixth.text.sixth')}</b>&nbsp;
          {t('curiosity.questions.accordion.sixth.text.seventh')}
        </Paragraph>
        <Paragraph>{t('curiosity.questions.accordion.sixth.text.eighth')}</Paragraph>
      </AccordionItem>
      <AccordionItem title={t('curiosity.questions.accordion.seventh.heading')}
                     keyNumber={6}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t('curiosity.questions.accordion.seventh.text.first')}&nbsp;
          <img src={settings}
               alt={t('curiosity.questions.accordion.seventh.alt')} />{t('curiosity.questions.accordion.seventh.text.second')}</Paragraph>
      </AccordionItem>
      <AccordionItem title={t('curiosity.questions.accordion.eighth.heading')}
                     keyNumber={7}
                     className={'container__fourth-section--items'}>
        <Paragraph>{t('curiosity.questions.accordion.eighth.text.first')}&nbsp;
          <Link
            title={t(`curiosity.questions.titleTwo`)}
            href={ 'https://obcan.portal.gov.cz/' }
            target="_blank"
            className={ 'plain-list__link' }
          >{t('curiosity.questions.accordion.eighth.text.second')}
            <img className={ 'plain-list__external-link' }
                 src={externalLink}
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>&nbsp; {t('curiosity.questions.accordion.eighth.text.third')}&nbsp;
          <Link
            title={t(`curiosity.questions.titleThree`)}
            href={ 'https://www.mojedatovaschranka.cz' }
            target="_blank"
            className={ 'plain-list__link' }
          >mojedatovaschranka.cz
            <img className={ 'plain-list__external-link' }
                 src={externalLink}
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>. {t('curiosity.questions.accordion.eighth.text.fourth')}</Paragraph>
        <Paragraph>{t('curiosity.questions.accordion.eighth.text.fifth')}&nbsp;
          <Link
            title={t(`curiosity.questions.titleThree`)}
            href={ 'https://www.mojedatovaschranka.cz' }
            target="_blank"
            className={ 'plain-list__link' }
          >mojedatovaschranka.cz
            <img className={ 'plain-list__external-link' }
                 src={externalLink}
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>. {t('curiosity.questions.accordion.eighth.text.sixth')}</Paragraph>
      </AccordionItem>
    </Accordion>
  )
}

export const UkraineAndRussianDataboxAccordions = ({ expandedNumber }: any) => {
  const { t } = useTranslation();

  return (
    <Accordion className={ 'gov-accordion--bordered' } expandedItem={ expandedNumber }>
      <AccordionItem title={t('curiosity.questions.accordion.seventh.heading')}
                     keyNumber={0}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t('curiosity.questions.accordion.seventh.text.first')}&nbsp;
          <img src={ settings }
               alt={t(`curiosity.questions.altOne`)} />{t('curiosity.questions.accordion.seventh.text.second')}
        </Paragraph>
      </AccordionItem>
      <AccordionItem title={t('curiosity.questions.accordion.first.heading')}
                     keyNumber={1}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t('curiosity.questions.accordion.first.text.first')}</Paragraph>
      </AccordionItem>
      <AccordionItem title={t('curiosity.questions.accordion.second.heading')}
                     keyNumber={2}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t('curiosity.questions.accordion.second.text.first')}</Paragraph>
      </AccordionItem>
      <AccordionItem title={t('curiosity.questions.accordion.third.heading')}
                     keyNumber={3}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t('curiosity.questions.accordion.third.text.first')}</Paragraph>
        <Paragraph>{t('curiosity.questions.accordion.third.text.second')}&nbsp;
          <Link
            title={t(`curiosity.questions.titleOne`)}
            href={ 'https://www.ceskaposta.cz/sluzby/egovernment/doplnkove-sluzby-k-datovym-schrankam/postovni-datova-zprava' }
            target="_blank"
            className={ 'plain-list__link' }
          >{t('curiosity.questions.accordion.third.text.third')}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>.</Paragraph>
      </AccordionItem>
      <AccordionItem title={t('curiosity.questions.accordion.sixthAsThird.heading')}
                     keyNumber={4}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t('curiosity.questions.accordion.sixthAsThird.textFirst')}&nbsp;
          <Link
            title={t(`curiosity.questions.titleFour`)}
            href={ 'https://play.google.com/store/apps/details?id=cz.nic.mobiledatovka' }
            target="_blank"
            className={'plain-list__link'}
          >{t('curiosity.questions.accordion.sixthAsThird.textSecond')}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>&nbsp; {t('curiosity.questions.accordion.sixthAsThird.textThird')}&nbsp;
          <Link
            title={t(`curiosity.questions.titleFive`)}
            href={ 'https://apps.apple.com/cz/app/datovka/id1185445299?l=cs' }
            target="_blank"
            className={ 'plain-list__link' }
          >{t('curiosity.questions.accordion.sixthAsThird.textFourth')}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>.</Paragraph>
      </AccordionItem>
      <AccordionItem title={t('curiosity.questions.accordion.fourth.heading')}
                     keyNumber={5}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t('curiosity.questions.accordion.fourth.text.first')}</Paragraph>
        <Paragraph>{t('curiosity.questions.accordion.fourth.text.second')}</Paragraph>
      </AccordionItem>
      <AccordionItem title={t('curiosity.questions.accordion.fifth.heading')}
                     keyNumber={6}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t('curiosity.questions.accordion.fifth.text.first')}</Paragraph>
        <Paragraph className={ 'no-margin-bottom' }>{t('curiosity.questions.accordion.fifth.text.second')}</Paragraph>
        <ul className={ 'unordered-list' }>
          <li>{t('curiosity.questions.accordion.fifth.text.third')}</li>
          <li>{t('curiosity.questions.accordion.fifth.text.fourth')}</li>
          <li>{t('curiosity.questions.accordion.fifth.text.fifth')}</li>
        </ul>
      </AccordionItem>
    </Accordion>
  )
}
