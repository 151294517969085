import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionItem,
  Link,
  Paragraph
} from '../../gov-components';

import externalLink from '../../resources/icons/external.svg';

export const CzechAndEnglishIdentityAccordionsQAndA = ({ expandedNumber }: any) => {
  const { t } = useTranslation();

  return (
    <Accordion className={ 'gov-accordion--bordered' } expandedItem={ expandedNumber }>
      <AccordionItem title={t("mainPage.sectionFour.itemOne.title")}
                     keyNumber={0}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t("mainPage.sectionFour.itemOne.textOne")}</Paragraph>
        <Paragraph>
          <Link
            title={t("mainPage.sectionFour.itemOne.textTwoLinkTitle")}
            href={t("mainPage.sectionFour.itemOne.textTwoLinkHref")}
            target="_blank"
            className={ 'plain-list__link' }
          >{t("mainPage.sectionFour.itemOne.textTwoLink")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>.</Paragraph>
      </AccordionItem>
      <AccordionItem title={t("mainPage.sectionFour.itemTwo.title")}
                     keyNumber={1}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t("mainPage.sectionFour.itemTwo.textOne")}</Paragraph>
      </AccordionItem>
      <AccordionItem title={t("mainPage.sectionFour.itemThree.title")}
                     keyNumber={2}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t("mainPage.sectionFour.itemThree.textOne")}</Paragraph>
      </AccordionItem>
      <AccordionItem title={t("mainPage.sectionFour.itemFour.title")}
                     keyNumber={3}
                     className={'container__fourth-section--items'}>
        <Paragraph>{t("mainPage.sectionFour.itemFour.textOnePartOne")}&nbsp;
          <Link
            title={t("mainPage.sectionFour.itemFour.textOnePartTwoLinkTitle")}
            href={t("mainPage.sectionFour.itemFour.textOnePartTwoLinkHref")}
            target="_blank"
            className={ 'plain-list__link' }
          >{t("mainPage.sectionFour.itemFour.textOnePartTwoLink")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>&nbsp; {t("mainPage.sectionFour.itemFour.textOnePartThree")}</Paragraph>
        <Paragraph>{t("mainPage.sectionFour.itemFour.textTwoPartOne")}&nbsp;
          <Link
            title={t("mainPage.sectionFour.itemFour.textTwoPartTwoLinkTitle")}
            href={t("mainPage.sectionFour.itemFour.textTwoPartTwoLinkHref")}
            target="_blank"
            className={ 'plain-list__link' }
          >{t("mainPage.sectionFour.itemFour.textTwoPartTwoLink")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>&nbsp; {t("mainPage.sectionFour.itemFour.textTwoPartThree")}</Paragraph>
      </AccordionItem>
      <AccordionItem title={t("mainPage.sectionFour.itemFive.title")}
                     keyNumber={4}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t("mainPage.sectionFour.itemFive.textOnePartOne")} <b>{t("mainPage.sectionFour.itemFive.textOnePartTwo")}</b>.</Paragraph>
        <Paragraph>{t("mainPage.sectionFour.itemFive.textTwoPartOne")}&nbsp;
          <Link
            title={t("mainPage.sectionFour.itemFive.textTwoPartTwoLinkTitle")}
            href={t("mainPage.sectionFour.itemFive.textTwoPartTwoLinkHref")}
            target="_blank"
            className={ 'plain-list__link' }
          >{t("mainPage.sectionFour.itemFive.textTwoPartTwoLink")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>&nbsp; {t("mainPage.sectionFour.itemFive.textTwoPartThree")}&nbsp;
          <Link
            title={t("mainPage.sectionFour.itemFive.textTwoPartFourLinkTitle")}
            href={t("mainPage.sectionFour.itemFive.textTwoPartFourLinkHref")}
            target="_blank"
            className={ 'plain-list__link' }
          >{t("mainPage.sectionFour.itemFive.textTwoPartFourLink")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>.</Paragraph>
      </AccordionItem>
      <AccordionItem title={t("mainPage.sectionFour.itemSix.title")}
                     keyNumber={5}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t("mainPage.sectionFour.itemSix.textOne")}</Paragraph>
        <Paragraph>
          <Link
            title={t("mainPage.sectionFour.itemSix.textTwoPartOneLinkTitle")}
            href={t("mainPage.sectionFour.itemSix.textTwoPartOneLinkHref")}
            target="_blank"
            className={ 'plain-list__link' }
          >{t("mainPage.sectionFour.itemSix.textTwoPartOneLink")}
            <img className={'plain-list__external-link'}
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>.</Paragraph>
      </AccordionItem>
      <AccordionItem title={t("mainPage.sectionFour.itemSeven.title")}
                     keyNumber={6}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t("mainPage.sectionFour.itemSeven.textOnePartOne")}&nbsp;
          <Link
            title={t("mainPage.sectionFour.itemSeven.textOnePartTwoLinkTitle")}
            href={t("mainPage.sectionFour.itemSeven.textOnePartTwoLinkHref")}
            target="_blank"
            className={ 'plain-list__link' }
          >{t("mainPage.sectionFour.itemSeven.textOnePartTwoLink")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>.</Paragraph>
        <Paragraph>{t("mainPage.sectionFour.itemSeven.textTwo")}</Paragraph>
      </AccordionItem>
      <AccordionItem title={t("mainPage.sectionFour.itemEight.title")}
                     keyNumber={7}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t("mainPage.sectionFour.itemEight.textOne")}</Paragraph>
        <Paragraph>{t("mainPage.sectionFour.itemEight.textTwoPartOne")}&nbsp;
          <Link
            title={t("mainPage.sectionFour.itemEight.textTwoPartTwoLinkTitle")}
            href={t("mainPage.sectionFour.itemEight.textTwoPartTwoLinkHref")}
            target="_blank"
            className={ 'plain-list__link' }
          >{t("mainPage.sectionFour.itemEight.textTwoPartTwoLink")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>.</Paragraph>
      </AccordionItem>
    </Accordion>
  )
}

export const UkraineAndRussianIdentityAccordionsQAndA = ({ expandedNumber }: any) => {
  const { t } = useTranslation();

  return (
    <Accordion className={ 'gov-accordion--bordered' } expandedItem={ expandedNumber }>
      <AccordionItem title={t("mainPage.sectionFour.itemOne.title")}
                     keyNumber={0}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t("mainPage.sectionFour.itemOne.textOne")}</Paragraph>
        <Paragraph><Link
          title={t("mainPage.sectionFour.itemOne.textTwoLinkTitle")}
          href={t("mainPage.sectionFour.itemOne.textTwoLinkHref")}
          target="_blank"
          className={ 'plain-list__link' }
        >{t("mainPage.sectionFour.itemOne.textTwoLink")}
          <img className={ 'plain-list__external-link' }
               src={ externalLink }
               alt={t("mainPage.imageLinkExternal")} />
        </Link>.</Paragraph>
      </AccordionItem>
      <AccordionItem title={t("mainPage.sectionFour.itemTwo.title")}
                     keyNumber={1}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t("mainPage.sectionFour.itemTwo.textOne")}</Paragraph>
      </AccordionItem>
      <AccordionItem title={t("mainPage.sectionFour.itemThree.title")}
                     keyNumber={2}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t("mainPage.sectionFour.itemThree.textOne")}</Paragraph>
      </AccordionItem>
      <AccordionItem title={t("mainPage.sectionFour.itemFour.title")}
                     keyNumber={3}
                     className={ 'container__fourth-section--items' }>
        <Paragraph>{t("mainPage.sectionFour.itemFour.textOnePartOne")}&nbsp;
          <Link
            title={t("mainPage.sectionFour.itemFour.textOnePartTwoLinkTitle")}
            href={t("mainPage.sectionFour.itemFour.textOnePartTwoLinkHref")}
            target="_blank"
            className={ 'plain-list__link' }
          >{t("mainPage.sectionFour.itemFour.textOnePartTwoLink")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>&nbsp; {t("mainPage.sectionFour.itemFour.textOnePartThree")}</Paragraph>
        <Paragraph>{t("mainPage.sectionFour.itemFour.textTwoPartOne")}&nbsp;
          <Link
            title={t("mainPage.sectionFour.itemFour.textTwoPartTwoLinkTitle")}
            href={t("mainPage.sectionFour.itemFour.textTwoPartTwoLinkHref")}
            target="_blank"
            className={ 'plain-list__link' }
          >{t("mainPage.sectionFour.itemFour.textTwoPartTwoLink")}
            <img className={ 'plain-list__external-link' }
                 src={ externalLink }
                 alt={t("mainPage.imageLinkExternal")} />
          </Link>&nbsp; {t("mainPage.sectionFour.itemFour.textTwoPartThree")}</Paragraph>
      </AccordionItem>
    </Accordion>
  )
}
