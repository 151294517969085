import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionItem,
  Badge,
  Headline,
  Link,
  List,
  ListItem,
  Paragraph,
} from '../../gov-components';

import externalLink from '../../resources/icons/external.svg';
import bankId from '../../resources/icons/bank-id.svg';
import airbank from '../../resources/icons/airbank.png';
import ceskaSporitelna from '../../resources/icons/ceskasporitelna.png';
import csobId from '../../resources/icons/csob-id.png';
import komercniBanka from '../../resources/icons/komercnibanka.png';
import fioBanka from '../../resources/icons/fio-banka.png';
import moneta from '../../resources/icons/moneta.png';
import raiffeisenBank from '../../resources/icons/raiffeisenbank.png';
import unicreditBank from '../../resources/icons/unicredit.png';
import creditas from '../../resources/icons/creditas.png';
import mbank from '../../resources/icons/mbank.png';
import datovka from '../../resources/icons/datovka.svg';
import mojeID from '../../resources/icons/mojeid.svg';
import nothing from '../../resources/icons/have-nothing.svg';
import lionLogo from '../../resources/icons/erb-logo.svg';
import googleplay from '../../resources/icons/googleplay.svg';
import appstore from '../../resources/icons/appstore.svg';

export const CzechAndEnglishIdentityAccordions = () => {
  const { t } = useTranslation();

  return (
    <Accordion version={ 'new' } className={ 'gov-accordion--new--english-czech-version' }>
      <AccordionItem
        title={t("mainPage.sectionThree.itemOne.title")}
        keyNumber={0}
        icon={<img src={ bankId }
                   alt={t("mainPage.sectionThree.itemOne.titleAltText")} />}
      >
        <Headline type={3} className={ 'p-bigger u-mb--35' }>{t("mainPage.sectionThree.itemOne.headingOne")}
          <Badge className={ 'badge' } variant={ 'inversed' }>{t("mainPage.sectionThree.itemOne.headingOneBadge")}</Badge>
        </Headline>
        <List plain={true}>
          <ListItem>
            <Headline type={4} className={ 'plain-list__smaller-title' }>{t("mainPage.sectionThree.itemOne.textOne")}</Headline>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemOne.textTwo")}</Paragraph>
          </ListItem>
          <ListItem>
            <Headline type={4} className={ 'plain-list__smaller-title' }>{t("mainPage.sectionThree.itemOne.textThree")}</Headline>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemOne.textFour")}</Paragraph>
          </ListItem>
          <ListItem>
            <Headline type={4} className={ 'plain-list__smaller-title' }>{t("mainPage.sectionThree.itemOne.textFive")}</Headline>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemOne.textSix")}</Paragraph>
          </ListItem>
        </List>
        <Paragraph>{t("mainPage.sectionThree.itemOne.textSeven")}</Paragraph>
        <List plain={true} inline={true} className={ 'list-plain' } >
          <ListItem>
            <img src={ airbank } alt={t("mainPage.sectionThree.itemOne.imageTextAltOne")}/>
          </ListItem>
          <ListItem>
            <img src={ ceskaSporitelna } alt={t("mainPage.sectionThree.itemOne.imageTextAltTwo")}/>
          </ListItem>
          <ListItem>
            <img src={ csobId } alt={t("mainPage.sectionThree.itemOne.imageTextAltThree")}/>
          </ListItem>
          <ListItem>
            <img src={ fioBanka } alt={t("mainPage.sectionThree.itemOne.imageTextAltSeven")}/>
          </ListItem>
          <ListItem>
            <img src={ komercniBanka } alt={t("mainPage.sectionThree.itemOne.imageTextAltFour")}/>
          </ListItem>
          <ListItem>
            <img src={ moneta } alt={t("mainPage.sectionThree.itemOne.imageTextAltFive")}/>
          </ListItem>
          <ListItem>
            <img src={ raiffeisenBank } alt={t("mainPage.sectionThree.itemOne.imageTextAltSix")}/>
          </ListItem>
          <ListItem>
            <img src={ unicreditBank } alt={t("mainPage.sectionThree.itemOne.imageTextAltEight")}/>
          </ListItem>
          <ListItem>
              <img src={ creditas }alt={t("mainPage.sectionThree.itemOne.imageTextAltNine")}/>
          </ListItem>
          <ListItem>
            <img src={ mbank }alt={t("mainPage.sectionThree.itemOne.imageTextAltTen")}/>
          </ListItem>
        </List>
      </AccordionItem>
      <AccordionItem
        title={t("mainPage.sectionThree.itemTwo.title")}
        keyNumber={1}
        icon={<img className={ 'plain-list__datovka' }
                   src={ datovka }
                   alt={t("mainPage.sectionThree.itemTwo.titleAltText")} />}
      >
        <Headline type={3} className={ 'p-bigger u-mb--35' }>{t("mainPage.sectionThree.itemTwo.headingOne")}</Headline>
        <List plain={true}>
          <ListItem>
            <Headline type={4} className={ 'plain-list__smaller-title' }>{t("mainPage.sectionThree.itemTwo.textOne")}</Headline>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemTwo.textTwo")}</Paragraph>
          </ListItem>
          <ListItem>
            <Headline type={4} className={ 'plain-list__smaller-title' }>{t("mainPage.sectionThree.itemTwo.textThree")}</Headline>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemTwo.textFour")}</Paragraph>
          </ListItem>
          <ListItem>
            <Headline type={4} className={ 'plain-list__smaller-title' }>{t("mainPage.sectionThree.itemTwo.textFive")}</Headline>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemTwo.textSixPartOne")}&nbsp;
              <Link
                title={t("mainPage.sectionThree.itemTwo.textSixPartTwoLinkTitle")}
                href={t("mainPage.sectionThree.itemTwo.textSixPartTwoLinkHref")}
                target="_blank"
                className={ 'plain-list__link' }
              >{t("mainPage.sectionThree.itemTwo.textSixPartTwoLink")}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>.</Paragraph>
          </ListItem>
        </List>
      </AccordionItem>
      <AccordionItem
        title={t("mainPage.sectionThree.itemThree.title")}
        keyNumber={2}
        icon={<img className={ 'plain-list__moje-id' }
                   src={ mojeID }
                   alt={t("mainPage.sectionThree.itemThree.titleAltText")} />}
      >
        <Headline type={3} className={ 'p-bigger u-mb--35' }>{t("mainPage.sectionThree.itemThree.headingOne")}</Headline>
        <List plain={true}>
          <ListItem>
            <Headline type={4} className={ 'plain-list__smaller-title' }>{t("mainPage.sectionThree.itemThree.textOne")}</Headline>
            <Paragraph className={ 'u-ml--20' }>
              <Link
                title={t("mainPage.sectionThree.itemThree.textTwoPartOneLinkTitle")}
                href={t("mainPage.sectionThree.itemThree.textTwoPartOneLinkHref")}
                target="_blank"
                external={true}
                className={ 'plain-list__link' }
              >{t("mainPage.sectionThree.itemThree.textTwoPartOneLink")}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>, {t("mainPage.sectionThree.itemThree.textTwoPartTwo")}</Paragraph>
          </ListItem>
          <ListItem>
            <Headline type={4} className={ 'plain-list__smaller-title' }>{t("mainPage.sectionThree.itemThree.textThree")}</Headline>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemThree.textFour")}</Paragraph>
          </ListItem>
          <ListItem>
            <Headline type={4} className={ 'plain-list__smaller-title' }>{t("mainPage.sectionThree.itemThree.textFive")}</Headline>
            <Paragraph className={'u-ml--20'}>{t("mainPage.sectionThree.itemThree.textSix")}</Paragraph>
          </ListItem>
          <ListItem>
            <Headline type={4} className={ 'plain-list__smaller-title' }>{t("mainPage.sectionThree.itemThree.textSeven")}</Headline>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemThree.textEight")}</Paragraph>
          </ListItem>
          <ListItem>
            <Paragraph>{t("mainPage.sectionThree.itemThree.textNinePartOne")}&nbsp;
              <Link
                title={t("mainPage.sectionThree.itemThree.textNinePartTwoLinkTitle")}
                href={t("mainPage.sectionThree.itemThree.textNinePartTwoLinkHref")}
                target="_blank"
                external={true}
                className={ 'plain-list__link' }
              >{t("mainPage.sectionThree.itemThree.textNinePartTwoLink")}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>.
            </Paragraph>
          </ListItem>
        </List>
      </AccordionItem>
      <AccordionItem
        title={t("mainPage.sectionThree.itemFour.title")}
        icon={<img src={ nothing }
                   alt={t("mainPage.sectionThree.itemFour.titleAltText")}/>}
        keyNumber={3}
      >
        <Headline type={3} className={ 'p-bigger u-mb--35' }>{t("mainPage.sectionThree.itemFour.headingOne")}</Headline>
        <List plain={true} className={ 'plain-list__first-part' }>
          <ListItem>
            <Headline type={4} className={ 'plain-list__smaller-title' }>{t("mainPage.sectionThree.itemFour.textOne")}
              <span className={ 'plain-list__first-part--icons' }>
                <Link
                  title={t("mainPage.sectionThree.itemFour.imageOneLinkTitle")}
                  href={t("mainPage.sectionThree.itemFour.imageOneLinkHref")}
                  target="_blank"
                  className={ 'plain-list__first-part--icon' }
                >
                  <img src={ googleplay }
                       alt={t("mainPage.sectionThree.itemFour.imageOneAltText")} />
                </Link>
                <Link
                  title={t("mainPage.sectionThree.itemFour.imageTwoLinkTitle")}
                  href={t("mainPage.sectionThree.itemFour.imageTwoLinkHref")}
                  target="_blank"
                  className={ 'plain-list__first-part--icon' }
                >
                  <img src={ appstore }
                       alt={t("mainPage.sectionThree.itemFour.imageTwoAltText")}/>
                </Link>
              </span>
            </Headline>
          </ListItem>
          <ListItem>
            <Headline type={4} className={ 'plain-list__smaller-title' }>{t("mainPage.sectionThree.itemFour.textTwo")}</Headline>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemFour.textThreePartOne")}&nbsp;
              <Link
                title={t("mainPage.sectionThree.itemFour.textThreePartTwoLinkTitle")}
                href={t("mainPage.sectionThree.itemFour.textThreePartTwoLinkHref")}
                target="_blank"
                className={ 'plain-list__link' }
              >{t("mainPage.sectionThree.itemFour.textThreePartTwoLink")}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>.
            </Paragraph>
          </ListItem>
        </List>
        <Headline type={3} className={ 'p-bigger u-mb--35' }>{t("mainPage.sectionThree.itemFour.headingTwo")}</Headline>
        <List plain={true}>
          <ListItem>
            <Headline type={4} className={ 'plain-list__smaller-title' }>{t("mainPage.sectionThree.itemFour.textFour")}</Headline>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemFour.textFivePartOne")}&nbsp;
              <Link
                title={t("mainPage.sectionThree.itemFour.textFivePartTwoLinkTitle")}
                href={t("mainPage.sectionThree.itemFour.textFivePartTwoLinkHref")}
                target="_blank"
                className={ 'plain-list__link' }
              >{t("mainPage.sectionThree.itemFour.textFivePartTwoLink")}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp; {t("mainPage.sectionThree.itemFour.textFivePartThree")}</Paragraph>
          </ListItem>
          <ListItem>
            <Headline type={4} className={ 'plain-list__smaller-title' }>{t("mainPage.sectionThree.itemFour.textSix")}</Headline>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemFour.textSeven")}</Paragraph>
          </ListItem>
          <ListItem>
            <Headline type={4} className={ 'plain-list__smaller-title' }>{t("mainPage.sectionThree.itemFour.textEight")}</Headline>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemFour.textNine")}</Paragraph>
          </ListItem>
        </List>
      </AccordionItem>
    </Accordion>
  )
}

export const UkraineAndRussianIdentityAccordions = () => {
  const { t } = useTranslation();

  return (
    <Accordion version={ 'new' } className={ 'gov-accordion--new--ukrainian-russian-version' }>
      <AccordionItem
        title={t("mainPage.sectionThree.itemOne.title")}
        keyNumber={0}
        icon={<img src={ bankId }
                   alt={t("mainPage.sectionThree.itemOne.titleAltText")} />}
      >
        <Headline type={3} className={ 'p-bigger u-mb--35' }>{t("mainPage.sectionThree.itemOne.headingOne")}
          <Badge className={ 'badge-ua-ru' } variant={ 'inversed' }>{t("mainPage.sectionThree.itemOne.headingOneBadge")}</Badge>
        </Headline>
        <List plain={true}>
          <ListItem>
            <Headline type={4} className={ 'plain-list__smaller-title' }>{t("mainPage.sectionThree.itemOne.textOne")}</Headline>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemOne.textTwo")}</Paragraph>
          </ListItem>
          <ListItem>
            <Headline type={4} className={ 'plain-list__smaller-title' }>{t("mainPage.sectionThree.itemOne.textThree")}</Headline>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemOne.textFour")}</Paragraph>
          </ListItem>
          <ListItem>
            <Headline type={4} className={ 'plain-list__smaller-title' }>{t("mainPage.sectionThree.itemOne.textFive")}</Headline>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemOne.textSix")}</Paragraph>
          </ListItem>
        </List>
        <Paragraph>{t("mainPage.sectionThree.itemOne.textSeven")}</Paragraph>
        <List plain={true} inline={true} className={ 'list-plain' } >
          <ListItem>
            <img src={ airbank } alt={t("mainPage.sectionThree.itemOne.imageTextAltOne")}/>
          </ListItem>
          <ListItem>
            <img src={ ceskaSporitelna } alt={t("mainPage.sectionThree.itemOne.imageTextAltTwo")}/>
          </ListItem>
          <ListItem>
            <img src={ csobId } alt={t("mainPage.sectionThree.itemOne.imageTextAltThree")}/>
          </ListItem>
          <ListItem>
            <img src={ fioBanka } alt={t("mainPage.sectionThree.itemOne.imageTextAltSeven")}/>
          </ListItem>
          <ListItem>
            <img src={ komercniBanka } alt={t("mainPage.sectionThree.itemOne.imageTextAltFour")}/>
          </ListItem>
          <ListItem>
            <img src={ moneta } alt={t("mainPage.sectionThree.itemOne.imageTextAltFive")}/>
          </ListItem>
          <ListItem>
            <img src={ raiffeisenBank } alt={t("mainPage.sectionThree.itemOne.imageTextAltSix")}/>
          </ListItem>
          <ListItem>
            <img src={ unicreditBank } alt={t("mainPage.sectionThree.itemOne.imageTextAltEight")}/>
          </ListItem>
          <ListItem>
              <img src={ creditas }alt={t("mainPage.sectionThree.itemOne.imageTextAltNine")}/>
          </ListItem>
          <ListItem>
            <img src={ mbank }alt={t("mainPage.sectionThree.itemOne.imageTextAltTen")}/>
          </ListItem>
        </List>
      </AccordionItem>
      <AccordionItem
        title={t("mainPage.sectionThree.itemFive.title")}
        icon={<img src={ lionLogo }
                   alt={t("mainPage.sectionThree.itemFive.titleAltText")}/>}
        keyNumber={1}
      >
        <List plain={true}>
          <ListItem>
            <Paragraph className={ 'u-ml--20' }>
              {t("mainPage.sectionThree.itemFive.textOnePartOne")}&nbsp;
              <b>{t("mainPage.sectionThree.itemFive.textOnePartTwo")}</b>&nbsp;
              {t("mainPage.sectionThree.itemFive.textOnePartThree")}
            </Paragraph>
          </ListItem>
          <ListItem>
            <Paragraph className={ 'u-ml--20' }>
              {t("mainPage.sectionThree.itemFive.textTwo")}
            </Paragraph>
          </ListItem>
          <ListItem>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemFive.textThreePartOne")}&nbsp;
              <Link
                title={t("mainPage.sectionThree.itemFive.textThreePartTwoLinkTitle")}
                href={t("mainPage.sectionThree.itemFive.textThreePartTwoLinkHref")}
                target="_blank"
                className={ 'plain-list__link' }
              >{t("mainPage.sectionThree.itemFive.textThreePartTwoLink")}
                <img className={'plain-list__external-link'}
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>. {t("mainPage.sectionThree.itemFive.textThreePartThree")}&nbsp;
              <Link
                title={t("mainPage.sectionThree.itemFive.textThreePartFourLinkTitle")}
                href={t("mainPage.sectionThree.itemFive.textThreePartFourLinkHref")}
                target="_blank"
                className={ 'plain-list__link' }
              >{t("mainPage.sectionThree.itemFive.textThreePartFourLink")}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>.
            </Paragraph>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemFive.textFourPartOne")}&nbsp;
              <b>{t("mainPage.sectionThree.itemFive.textFourPartTwo")}</b>,&nbsp;
              {t("mainPage.sectionThree.itemFive.textFourPartThree")}&nbsp;
              <b>{t("mainPage.sectionThree.itemFive.textFourPartFour")}</b>&nbsp;
              {t("mainPage.sectionThree.itemFive.textFourPartFive")}
            </Paragraph>
          </ListItem>
          <ListItem>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemFive.textFivePartOne")}&nbsp;
              <Link
                title={t("mainPage.sectionThree.itemFive.textFivePartTwoLinkTitle")}
                href={t("mainPage.sectionThree.itemFive.textFivePartTwoLinkHref")}
                target="_blank"
                className={ 'plain-list__link' }
              >{t("mainPage.sectionThree.itemFive.textFivePartTwoLink")}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp;{t("mainPage.sectionThree.itemFive.textFivePartThree")}&nbsp;
              <b>{t("mainPage.sectionThree.itemFive.textFivePartFour")}</b>.&nbsp;
              {t("mainPage.sectionThree.itemFive.textFivePartFive")}
            </Paragraph>
          </ListItem>
          <ListItem>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemFive.textSix")}</Paragraph>
            <ul className={'unordered-list'}>
              <li>{t("mainPage.sectionThree.itemFive.textSevenPartOne")}&nbsp;
                <b>{t("mainPage.sectionThree.itemFive.textSevenPartTwo")}</b>&nbsp;
                {t("mainPage.sectionThree.itemFive.textSevenPartThree")}
              </li>
              <li>{t("mainPage.sectionThree.itemFive.textEightPartOne")}&nbsp;
                <b>{t("mainPage.sectionThree.itemFive.textEightPartTwo")}</b>.
              </li>
              <li>{t("mainPage.sectionThree.itemFive.textNinePartOne")}&nbsp;
                <b>{t("mainPage.sectionThree.itemFive.textNinePartTwo")}</b>.
              </li>
              <li>{t("mainPage.sectionThree.itemFive.textTenPartOne")}&nbsp;
                <b>{t("mainPage.sectionThree.itemFive.textTenPartTwo")}</b>&nbsp;
                {t("mainPage.sectionThree.itemFive.textTenPartThree")}
              </li>
            </ul>
          </ListItem>
          <ListItem>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemFive.textEleven")}</Paragraph>
          </ListItem>
          <ListItem>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemFive.textTwelvePartOne")}&nbsp;
              <b>{t("mainPage.sectionThree.itemFive.textTwelvePartTwo")}</b>&nbsp;
              {t("mainPage.sectionThree.itemFive.textTwelvePartThree")}
            </Paragraph>
          </ListItem>
        </List>
      </AccordionItem>
      <AccordionItem
        title={t("mainPage.sectionThree.itemSix.title")}
        icon={<img src={ lionLogo }
                   alt={t("mainPage.sectionThree.itemSix.titleAltText")} />}
        keyNumber={2}
      >
        <List plain={true}>
          <ListItem>
            <Paragraph className={ 'u-ml--20' }>
              {t("mainPage.sectionThree.itemSix.textOnePartOne")}&nbsp;
              <b>{t("mainPage.sectionThree.itemSix.textOnePartTwo")}</b>&nbsp;
              {t("mainPage.sectionThree.itemSix.textOnePartThree")}
            </Paragraph>
          </ListItem>
          <ListItem>
            <Paragraph className={ 'u-ml--20' }>
              {t("mainPage.sectionThree.itemSix.textTwo")}
            </Paragraph>
          </ListItem>
          <ListItem>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemSix.textThreePartOne")}&nbsp;
              <b>{t("mainPage.sectionThree.itemSix.textThreePartTwo")}</b>&nbsp;
              {t("mainPage.sectionThree.itemSix.textThreePartThree")}
            </Paragraph>
            <Paragraph className={ 'u-ml--20 no-margin-bottom' }>
              <Link
                title={t("mainPage.sectionThree.itemSix.textFourPartOneLinkTitle")}
                href={t("mainPage.sectionThree.itemSix.textFourPartOneLinkHref")}
                target="_blank"
                className={ 'plain-list__link' }
              >{t("mainPage.sectionThree.itemSix.textFourPartOneLink")}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp; {t("mainPage.sectionThree.itemSix.textFourPartTwo")}
            </Paragraph>
            <Paragraph className={ 'u-ml--20' }>
              <Link
                title={t("mainPage.sectionThree.itemSix.textFourPartThreeLinkTitle")}
                href={t("mainPage.sectionThree.itemSix.textFourPartThreeLinkHref")}
                target="_blank"
                className={ 'plain-list__link' }
              >{t("mainPage.sectionThree.itemSix.textFourPartThreeLink")}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp; {t("mainPage.sectionThree.itemSix.textFourPartFour")}
            </Paragraph>
          </ListItem>
          <ListItem>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemSix.textFivePartOne")}&nbsp;
              <b>{t("mainPage.sectionThree.itemSix.textFivePartTwo")}</b>&nbsp;
              {t("mainPage.sectionThree.itemSix.textFivePartThree")}&nbsp;
              <b>{t("mainPage.sectionThree.itemSix.textFivePartFour")}</b>.
            </Paragraph>
          </ListItem>
          <ListItem>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemSix.textSixPartOne")}&nbsp;
              <b>{t("mainPage.sectionThree.itemSix.textSixPartTwo")}</b>&nbsp;
              {t("mainPage.sectionThree.itemSix.textSixPartThree")}
            </Paragraph>
          </ListItem>
          <ListItem>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemSix.textSevenPartOne")}&nbsp;
              <b>{t("mainPage.sectionThree.itemSix.textSevenPartTwo")}</b>&nbsp;
              {t("mainPage.sectionThree.itemSix.textSevenPartThree")}
            </Paragraph>
          </ListItem>
          <ListItem>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemSix.textEightPartOne")}&nbsp;
              <Link
                title={t("mainPage.sectionThree.itemSix.textEightPartTwoLinkTitle")}
                href={t("mainPage.sectionThree.itemSix.textEightPartTwoLinkHref")}
                target="_blank"
                className={ 'plain-list__link' }
              >{t("mainPage.sectionThree.itemSix.textEightPartTwoLink")}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp;{t("mainPage.sectionThree.itemSix.textEightPartThree")}&nbsp;
              <b>{t("mainPage.sectionThree.itemSix.textEightPartFour")}</b>.&nbsp;
              {t("mainPage.sectionThree.itemSix.textEightPartFive")}
            </Paragraph>
          </ListItem>
          <ListItem>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemSix.textNine")}</Paragraph>
            <ul className={ 'unordered-list' }>
              <li>{t("mainPage.sectionThree.itemSix.textTenPartOne")}&nbsp;
                <b>{t("mainPage.sectionThree.itemSix.textTenPartTwo")}</b>&nbsp;
                {t("mainPage.sectionThree.itemSix.textTenPartThree")}
              </li>
              <li>{t("mainPage.sectionThree.itemSix.textElevenPartOne")}&nbsp;
                <b>{t("mainPage.sectionThree.itemSix.textElevenPartTwo")}</b>.
              </li>
              <li>{t("mainPage.sectionThree.itemSix.textTwelvePartOne")}&nbsp;
                <b>{t("mainPage.sectionThree.itemSix.textTwelvePartTwo")}</b>.
              </li>
              <li>{t("mainPage.sectionThree.itemSix.textThirteenPartOne")}&nbsp;
                <b>{t("mainPage.sectionThree.itemSix.textThirteenPartTwo")}</b>&nbsp;
                {t("mainPage.sectionThree.itemSix.textThirteenPartThree")}&nbsp;
                <b>{t("mainPage.sectionThree.itemSix.textThirteenPartFour")}</b>.
              </li>
            </ul>
          </ListItem>
          <ListItem>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemSix.textFourteen")}</Paragraph>
          </ListItem>
          <ListItem>
            <Paragraph className={ 'u-ml--20' }>{t("mainPage.sectionThree.itemSix.textFifteenPartOne")}&nbsp;
              <b>{t("mainPage.sectionThree.itemSix.textFifteenPartTwo")}</b>.
            </Paragraph>
          </ListItem>
        </List>
      </AccordionItem>
    </Accordion>
  )
}
