import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { Button } from './Button';
import { Headline } from './Headline';

export interface GridProps {
  children?: any;
  className?: string;
  gridSize: number | string;
}

export const Grid = (props: GridProps) => {
  const { children, className, gridSize } = props;
  let finalClass: string = 'gov-grid';

  finalClass = className ?
    `${finalClass} ${className}` :
    finalClass;
  finalClass = gridSize ?
    `${finalClass} gov-grid--x${gridSize}` :
    finalClass;

  return (<div className={finalClass}>
    {children?.props ?
      <GridTile {...children.props} /> :
      children.map((item: { props: GridTileProps }, index: number) => {
        return <GridTile key={index} {...item?.props} />
      })
    }
  </div>);
}

export interface GridTileProps {
  children?: any;
  title?: string;
  href?: string;
  onClick?: any;
  noButton?: boolean;
  modalTitle?: string;
  modalButtonName?: string;
  modalContent?: JSX.Element;
  buttonLink?: string;
}

export const GridTile = (props: GridTileProps) => {
  const { children, title, href, modalContent, modalTitle, modalButtonName, buttonLink, noButton } = props;
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (<>
    {href ?
      <a href={href} className="gov-grid-tile">
        {title && <Headline type={3}>{title}</Headline>}
        {children}
      </a> :
      <a onClick={ handleShow } className="gov-grid-tile">
        {title && <Headline type={3}>{title}</Headline>}
        {children}
      </a>
    }
    <Modal show={ show } onHide={ handleClose } animation={false}>
      <Modal.Header>
        {modalTitle && <Modal.Title><Headline type={2} className={ 'heading5' }>{modalTitle}</Headline></Modal.Title>}
        <button
          type="button"
          className="close close-btn"
          data-dismiss="modal"
          aria-label={t("grid.buttonLabel")}
          onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        { modalContent }
      </Modal.Body>
      <Modal.Footer hidden={ noButton }>
        {buttonLink ?
          <Button
            href={ buttonLink }
            color={ 'primary' }
            size={ 'large' }
            target="_blank"
          >{ modalButtonName }</Button> :
          <Button
            color={ 'primary' }
            size={ 'large' }
            onClick={ handleClose }
          >{t('grid.buttonLabel')}</Button>
        }
      </Modal.Footer>
    </Modal>
  </>)
}
