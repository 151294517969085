/**
 * Stepper Component
 */
import * as React from 'react';
import { useMemo, useState } from 'react';
import clsx from 'clsx';

import { sortByProp } from '../util';
import { Headline } from './Headline';
import { Paragraph } from './Paragraph';
import { Perex } from './Perex';
import { TextInline } from './TextInline';
import { Button } from './Button';
import { useTranslation } from 'react-i18next';

import imageIcon from '../resources/icons/image-icon.svg';
import imageIconFill from '../resources/icons/image-icon-fill.svg';

interface StepItemObject {
  description?: string;
  order: number;
  title: string;
  images?: string[];
  imagesSmall?: string[];
  children?: any;
}

export interface StepperProps {
  /** Custom className applied to root element */
  className?: string;
  /** If true, steps are inline */
  inline?: boolean;
  /** Component steps */
  steps: StepItemObject[];
  /** Passing all other props to component */
  props?: any;
  /** Children */
  children?: any[];
}

export const Stepper = ({ inline = false, className, steps = [], children, ...props }: StepperProps) => {
  const sortedSteps = useMemo(() => sortByProp({ array: steps, propName: 'order' }), [steps]);

  return (
    <div
      className={clsx('gov-stepper', className, {
        'gov-grid gov-grid--x4': inline,
        'gov-stepper--as-block': !inline,
      })}
      {...props}
    >
      {sortedSteps.map(({ order, title, description, images, imagesSmall }) => {
        let childObject = children?.find(item => item.key == order);

        return (
          <div className="gov-stepper__step" key={ order }>
            <TextInline className="gov-stepper__step-number">
              <Perex className="gov-perex">{ order }</Perex>
            </TextInline>
            <Headline type={3} className="gov-stepper__heading">{ title }</Headline>
            <div className="gov-stepper__border">
              {description &&
                <TextInline className="gov-stepper__step-border">
                  <Paragraph className="gov-stepper__step-border--text">{ description }</Paragraph>
                </TextInline>
              }
              {childObject &&
                <TextInline className="gov-stepper__step-border">
                  <Paragraph className="gov-stepper__step-border--text">
                    <StepDescription>{ childObject }</StepDescription>
                  </Paragraph>
                </TextInline>
              }
              {images && <StepperImage images={ images } className="gov-stepper__image" />}
              {imagesSmall && <StepperImage images={ imagesSmall } className="gov-stepper__image-small" />}
            </div>
        </div>)
      })}
    </div>
  )
}

interface StepImageProps {
  images?: string[];
  imagesSmall?: string[];
  className?: string;
}

export const StepperImage = ({ images = [], imagesSmall = [], className }: StepImageProps) => {
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation();
  const imagesLength = images?.length;
  const imagesSmallLength = imagesSmall?.length;
  let isLongerThanOne = imagesLength > 1 || imagesSmallLength > 1;

  return (<>
    <Button color={ 'tertiary' }
            size={ 'normal'}
            onClick={() => setShowMore(!showMore)}
            className={ 'no-padding u-mt--12' }>
      {showMore ?
        <img src={ imageIconFill }
             alt={t('stepper.iconAlt')}
             className={ 'u-mr--8' }/>
             :
        <img src={ imageIcon }
             alt={t('stepper.iconAlt')}
             className={ 'u-mr--8' }/>
      }
      {images && imagesSmall && isLongerThanOne ?
        <>{showMore ? <Headline type={4}>{ t('stepper.buttonTwoMore') }</Headline> : <Headline type={4}>{ t('stepper.buttonOneMore') }</Headline>}</>
        :
        <>{showMore ? <Headline type={4}>{ t('stepper.buttonTwo') }</Headline> : <Headline type={4}>{ t('stepper.buttonOne') }</Headline>}</>
      }
    </Button>
      <div>
        {showMore && images && images?.map((image, index) => (
          <img src={ image } alt={t('stepper.imageAlt')} key={ index } className={ className } />))
        }
        {showMore && imagesSmall && imagesSmall?.map((imageSmall, index) => (
          <img src={ imageSmall } alt={t('stepper.imageAlt')} key={ index } className={ className } />))
        }
      </div>
  </>)
};

interface StepDescriptionProps {
  children?: any;
}

export const StepDescription = ({ children }: StepDescriptionProps) => {
  return (
    <>
      {children && <span>{ children }</span>}
    </>
  )
};
