/**
 * Text Component
 */

import clsx from 'clsx';
import * as React from 'react';
import { ReactNode } from 'react';

export interface TextInlineProps {
  /** If true, text is bold */
  bold?: boolean;
  /** Custom class applied to root element */
  className?: string;
  /** Represents text or another component */
  children: ReactNode;
  /** Passing all other props to component */
  props?: any;
}

export const TextInline = ({ bold = false, className, children, ...props }: TextInlineProps) => (
  <span className={clsx(className, { 'u-font-weight--bold': bold })} {...props}>
    {children}
  </span>
);
