import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useWindowSize, WindowSize } from '../../hooks';
import { ReactSVG } from 'react-svg';
import { Container } from '../Container';
import { Link } from '../Link';
import { scrollToElement } from '../Utils';


import logo from '../../resources/icons/logo.svg';
import { HeaderProps } from './HeaderChciIdentitu';

export const HeaderChciDatovku = ({ version }: HeaderProps) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { location: { pathname } }: { location: { pathname: string } } = useHistory();
  const hasDifferentContent: boolean = pathname.includes('ua') || pathname.includes('ru');
  const [headerMenu, setHeaderMenu] = useState(false);
  const size: WindowSize = useWindowSize();
  const langs = ['cs', 'ua', 'en', 'ru'];

  const routePaths = location.pathname.split('/');
  const pathName = routePaths.filter(path => path.length <= 2)[0];
  const currentLang = pathName === '' ? 'cs' : pathName

  const isMainPage =
    pathname === '/' ||
    pathname === '/cs' ||
    pathname === '/cs/' ||
    pathname === '/cs/uvod' ||
    pathname === '/en' ||
    pathname === '/en/' ||
    pathname === '/uvod' ||
    pathname === '/en/uvod';

  useEffect(() => {
    setHeaderMenu(false);
  }, [size])

  useEffect(() => {
    headerMenu ?
      document.getElementsByTagName("body")[0].style.overflow = 'hidden' :
      document.getElementsByTagName("body")[0].style.overflow = '';
  }, [headerMenu])

  const translatePage = (lang: string): void => {
    localStorage.setItem('lang', lang);

    if (lang === 'cs') {
      history.replace(`/${pathName}`, true);
      i18n.changeLanguage('cs');
    } else {
      history.replace(`/${lang}/${pathName}`);
      i18n.changeLanguage(lang);
    }
  }

  return (<Container tag={'header'} className={`gov-container--no-y-offset gov-header${headerMenu ? ' is-fixed' : ''}`}>
    <div className="gov-header__holder">
      <div className="gov-header__left">
        <Link
          inversed={true}
          className={'gov-header__logo'}
          href={'https://chcidatovku.gov.cz/'}
        >
          <div className="identity-logo">
            <div className="gov-pictogram gov-pictogram--inversed gov-pictogram--xs">
              <ReactSVG src={ logo } alt={t('header.altOne')} />
            </div>
          </div>
        </Link>
      </div>
      <div className="gov-header__right">
        <div className="gov-header__fixed-items">
          <div className="gov-header__item gov-header__item--mobile">
            <button
              onClick={() => setHeaderMenu(!headerMenu)}
              className={`gov-header__hamburger${headerMenu ? ' active' : ''}`}
            >
              <span />
              <span />
              <div>{t('header.menu')}</div>
            </button>
          </div>
          <div className="gov-header__item gov-header__lang">
            <div className="gov-lang-switch">
              <select
                defaultValue={currentLang}
                id="83e30b94-77c5-7998-e9b9-a54aab60a061"
                className="gov-lang-switch__select"
                onChange={(e) => translatePage(e.target.value)}
              >
                <option value={currentLang}>{currentLang.toUpperCase()}</option>
                {langs.map(lang => { return lang !== currentLang && <option key={lang} value={lang}>{lang.toUpperCase()}</option> })}
              </select>
              <label className="u-sr-only" htmlFor="83e30b94-77c5-7998-e9b9-a54aab60a061">
                {t('header.languageChange')}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr className="gov-header__separator" />
    <div className={`gov-header__overlay${headerMenu ? ' active' : ''}`} />
    <nav className={`gov-header__nav${headerMenu ? ' active' : ''}`}>
      <div className={`gov-header__nav-logo${headerMenu ? ' active' : ''}`}>
        <div className="identity-logo">
          <div className="gov-pictogram gov-pictogram--inversed gov-pictogram--xs">
            <ReactSVG src={ logo } alt={t('header.altOne')} />
          </div>
        </div>
      </div>
      <div className="gov-header__nav-holder">
        <div className="gov-header__link">
          <Link
            onClick={() => { scrollToElement({ elem: '#' }); if (headerMenu) { setHeaderMenu(!headerMenu) } }}
            standalone={true}
            size={'normal'}
            inversed={true}
            className={'header-link'}
          >{t('header.intro')}
          </Link>
        </div>
        <div className="gov-header__link">
          <Link
            onClick={() => { scrollToElement({ elem: 'chci-datovku-id' }); if (headerMenu) { setHeaderMenu(!headerMenu) } }}
            standalone={true}
            size={'normal'}
            inversed={true}
            className={'header-link'}
          >{t('header.services')}
          </Link>
        </div>
        <div className="gov-header__link">
          <Link
            onClick={() => { scrollToElement({ elem: 'jak-zalozit-datovku-id' }); if (headerMenu) { setHeaderMenu(!headerMenu) } }}
            standalone={true}
            size={'normal'}
            inversed={true}
            className={'header-link'}
          >{t('header.establishment')}
          </Link>
        </div>
        <div className="gov-header__link">
          <Link
            onClick={() => { scrollToElement({ elem: 'caste-dotazy-id' }); if (headerMenu) { setHeaderMenu(!headerMenu) } }}
            standalone={true}
            size={'normal'}
            inversed={true}
            className={'header-link'}
          >{t('header.questions')}
          </Link>
        </div>
        {hasDifferentContent ?
          <></> :
          <div className="gov-header__link">
            <Link
              onClick={ () => {
                scrollToElement({ elem: 'kontakt-id' });
                if (headerMenu) {
                  setHeaderMenu(!headerMenu)
                }
              } }
              standalone={true}
              size={'normal'}
              inversed={true}
              className={'header-link'}
            >{t('header.contact')}
            </Link>
          </div>
        }
      </div>
    </nav>
  </Container>);
};
