/**
 * Badge component
 */

import * as React from 'react';
import { ReactNode } from 'react';
import clsx from 'clsx';

import { TextInline } from './TextInline';

export const BadgeVariant = ['inversed', 'warning', 'error'] as const;
export type BadgeVariantType = typeof BadgeVariant[number];

export interface BadgeProps {
  /** Custom class applied to root element */
  className?: string;
  /** Represents text or components */
  children: ReactNode;
  /** Visual variants of badge */
  variant?: BadgeVariantType;
  /** Passing all other props to component */
  props?: any;
}

export const Badge = ({ className, children, variant, ...props }: BadgeProps) => (
  <TextInline
    className={clsx('gov-badge', className, { [`gov-badge--${variant}`]: variant })}
    {...props}
  >
    {children}
  </TextInline>
);
